import { AssetServiceQuery } from "@phc-health/connect-query"
import {
  createConnectQueryKey,
  useMutation,
  useQuery,
} from "@connectrpc/connect-query"
import { useTransport } from "../../../hooks/useTransport"
import { ASSET_SERVICE_API } from "../../../utils/constants"
import { useSnackbar } from "notistack"
import { useQueryClient } from "@tanstack/react-query"

export const useListAllTrials = () => {
  const transport = useTransport(ASSET_SERVICE_API)

  return useQuery(
    AssetServiceQuery.listOrganizationsUsersTrials,
    {},
    { transport }
  )
}

export const useUpdateUserStatus = () => {
  const transport = useTransport(ASSET_SERVICE_API)
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(AssetServiceQuery.updatePharosUserStatus, {
    transport,
    retry: 5,
    onError: err => {
      enqueueSnackbar("Failed to update user status.", {
        variant: "error",
      })
      console.error(err)
    },
    onSuccess: () => {
      enqueueSnackbar("Updated user status", {
        variant: "success",
      })

      void queryClient.invalidateQueries({
        queryKey: createConnectQueryKey(
          AssetServiceQuery.listOrganizationsUsersTrials
        ),
      })
    },
  })
}

export const useUpdateUserTrial = () => {
  const transport = useTransport(ASSET_SERVICE_API)
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(AssetServiceQuery.updatePharosUserTrial, {
    transport,
    retry: 5,
    onError: err => {
      enqueueSnackbar("Failed to update user status.", {
        variant: "error",
      })
      console.error(err)
    },
    onSuccess: () => {
      enqueueSnackbar("Updated user status", {
        variant: "success",
      })

      void queryClient.invalidateQueries({
        queryKey: createConnectQueryKey(
          AssetServiceQuery.listOrganizationsUsersTrials
        ),
      })

      void queryClient.invalidateQueries({
        queryKey: createConnectQueryKey(AssetServiceQuery.getPharosUserTOS),
      })
    },
  })
}
