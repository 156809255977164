import axios from "axios"
import { MAPBOX_USERNAME, mapboxConfig } from "./mapboxConfig"
import type { PhcFeature } from "./shared-map"
import type { GeocoderResult } from "./mapboxTilesetQuery"
import PQueue from "p-queue"

export type TilesetFeatureCollection = GeoJSON.FeatureCollection<
  GeoJSON.Point,
  PhcFeature["properties"] & { tilequery: { layer: string } }
>

// the geocoder has a rate limit of 600 per minute
// but this seems to work without throwing a 429
const tilesetQueue = new PQueue({
  intervalCap: 600,
  interval: 1000 * 60,
  concurrency: 10,
})

export const getTileSetResponse = async (
  coords: { lng: number; lat: number },
  accessToken: string,
  countryShortCode: string
) => {
  const tilesetId = `${MAPBOX_USERNAME}.${countryShortCode.toLowerCase()}`

  const fetch = () =>
    axios.get<TilesetFeatureCollection | null>(
      `https://api.mapbox.com/v4/${tilesetId}/tilequery/${coords.lng},${coords.lat}.json?access_token=${accessToken}`
    )
  const response = await tilesetQueue.add(fetch, {
    throwOnTimeout: true,
  })
  if (!response.data) return response.data
  // filter by available source layers
  response.data.features = response.data.features.filter(f =>
    mapboxConfig.sourceLayer.find(s => s.id === f.properties.tilequery.layer)
  )
  return response.data
}

interface MapboxSearchResponse {
  features: GeocoderResult["result"][]
}

// the geocoder has a rate limit of 600 per minute
// but this seems to work without throwing a 429
const queue = new PQueue({
  intervalCap: 600,
  interval: 1000 * 60,
  concurrency: 10,
})

/**
 * Retrieves geocode results from Mapbox API based on the provided query.
 * @param query - The query string or coordinates (lng, lat) to search for.
 * @param types - The types of features to include in the search results. Defaults to "country,region,district".
 * @param accessToken - The access token for authenticating the API request.
 * @returns A Promise that resolves to the geocode result data.
 */
export const getMapboxGeocodeResult = async ({
  query,
  types = "country,region,district",
  accessToken,
}: {
  query:
    | string
    | {
        lng: number
        lat: number
      }
  /** "country,region,district" */
  types?: string
  accessToken: string | undefined
}) => {
  const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
    typeof query === "string" ? query : `${query.lng},${query.lat}`
  )}.json?types=${encodeURIComponent(types)}&access_token=${accessToken ?? ""}`
  const fetch = () => axios.get<MapboxSearchResponse | undefined>(url)
  const response = await queue.add(fetch, {
    throwOnTimeout: true,
  })
  return response.data
}

/**
 * Centroids are formed: "POINT (lng lat)"
 */
export function getCentroidCoordinates(wktCentroids?: string) {
  if (!wktCentroids) return undefined
  const coords = wktCentroids
    .split("(")[1]
    ?.split(")")[0]
    ?.split(" ")
    // remove digits, dots, and dashes
    .map(c => c.replace(/[^\d.-]/g, ""))
  if (!coords?.length || coords.some(c => !c)) return undefined
  return {
    lng: Number(coords[0]),
    lat: Number(coords[1]),
  }
}
