import { useOrganization } from "./useOrganization"
import { useOrganizationId } from "./useOrganizationId"
import phcLogoFull from "../assets/phc_global_logo_gradient.png"
import phcLogoIcon from "../assets/phc_logo_icon.png"

export const useAppLogo = () => {
  const orgId = useOrganizationId()
  const organization = useOrganization(orgId.data ?? "")

  const orgName = organization.data?.name

  const logoList: Record<string, { full: string; short: string }> = {
    phc: {
      full: phcLogoFull,
      short: phcLogoIcon,
    },
    parsons: {
      full: "https://assets.thepublichealthco.com/78d9d6c/sha-57f06f6/logos/org_l8Exf3cN2tyGKkfO-full.png",
      short:
        "https://assets.thepublichealthco.com/78d9d6c/sha-57f06f6/logos/org_l8Exf3cN2tyGKkfO-short.png",
    },
    // Add more orgs here
  }

  return orgName ? logoList[orgName] : undefined
}
