import {
  mapboxConfig,
  type GeocoderResult,
  type PhcFeature,
  getCentroidCoordinates,
} from "@phc/common"
import type { FitBoundsOptions } from "mapbox-gl"
type Lng = number
type Lat = number
const COORDINATES_BY_LOCATION = new Map<string, [Lng, Lat]>([
  ["84006075", [-122.4426899697519, 37.759823231029316]], // San Francisca, CA, USA
  ["84006083", [-120.01770913778685, 34.7480516895225]], // Santa Barbara County, CA, USA
  ["84006111", [-119.05526097792773, 34.435333785464024]], // Ventura County, CA, USA
  ["84006037", [-118.20814020558294, 34.32822840966874]], // Los Angeles County, CA, USA
  ["84015003", [-157.95216871258216, 21.48672196509864]], // Honolulu, HI, USA
  ["84015007", [-159.52764141935478, 22.064609840512745]], // Kauai, Hawaii, USA

  ["84012", [-82.01289838748089, 29.244911460286474]], // Florida, USA
  ["84002", [-153.60856276418852, 64.35373252800102]], // Alaska, USA
  ["84015", [-156.2618580934565, 20.69824926008718]], // Hawaii, USA

  ["840", [-97.96395635899651, 39.415316908047174]], // USA

  ["12411", [-63.338347989371016, 46.289594172187634]], // Prince Edward Island, CA

  ["710", [24.029278780089328, -29.132057400168378]], // South Africa

  ["784", [54.04240672800236, 23.561112519274047]], // UAE

  ["39213", [139.45452557523527, 35.70484729257666]], // Tokyo, Japan
  ["392", [138.6548925271398, 36.41864123070016]], // Japan

  ["158", [121.05742255266051, 23.462629944865228]], // Taiwan

  ["608", [121.16595650633474, 14.54281529229904]], // Philippines

  ["458", [102.19525315242004, 4.1815846144776145]], // Malaysia

  ["376", [34.79663190291154, 31.784296927489848]], // Israel

  ["250", [2.5471244162694973, 47.21354808402651]], // France
])

export function getLocationCenter(
  locationId?: string,
  wktCentroids?: string
): { lat: number; lng: number } | undefined {
  return (
    getCenterFromLocationId(locationId) || getCentroidCoordinates(wktCentroids)
  )
}

function getCenterFromLocationId(
  locationId?: string
): { lng: number; lat: number } | undefined {
  if (!locationId) return undefined
  const loc = COORDINATES_BY_LOCATION.get(locationId)
  return loc
    ? {
        lng: loc[0],
        lat: loc[1],
      }
    : undefined
}

export function getCentroidFromGeocoder(
  geocoder?: GeocoderResult
): string | undefined {
  if (!geocoder?.result?.center) return undefined
  const center = geocoder.result.center
  return `POINT (${center[0].toString()} ${center[1].toString()})`
}

export const configureFitBoundsOptions = (location: PhcFeature) => {
  const options: FitBoundsOptions = {
    padding: 50,
    animate: false,
  }

  const maxZoom = getMaxZoom(location)
  if (maxZoom) {
    options.maxZoom = maxZoom
  }

  return options
}

const getMaxZoom = (location: PhcFeature) => {
  const locId = location.properties.location_code
  if (!locId) return undefined
  const layerDetails = mapboxConfig.sourceLayer.find(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    layer => layer.id === location.properties.tilequery?.layer
  )

  if (
    location.sourceLayer === "us_states" ||
    (layerDetails?.context === "subnational" &&
      layerDetails.sourceInfo.source === "us")
  ) {
    return 5.9
  }

  return layerDetails?.defaultZoomLevels.maxzoom
}
