import type React from "react"
import { Typography, styled } from "@mui/material"
import { dayjsUTC } from "../../../utils/helpers"
import { extraColors } from "../../../utils/theme"
import type { ReactNode } from "react"

export const MAX_IMAGE_WIDTH = 160
export const DEFAULT_TRANSFORM_SCALE =
  "scale(1.00001) translate3d(0, 0, 0) perspective(1px)"

const CardHeader = styled("div")({
  display: "grid",
  gridAutoFlow: "column",
  justifyContent: "space-between",
  alignItems: "baseline",
})

export const TagRow = styled("div")({
  display: "flex",
  flexFlow: "wrap",
  gap: 6,
  alignItems: "center",
  margin: "6px 0 12px 0",
})

const SideBarContainer = styled("div")<{ backgroundcolor: string }>(
  ({ backgroundcolor }) => ({
    backgroundColor: backgroundcolor,
    display: "flex",
    position: "relative",
    borderTopLeftRadius: 6,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 0,
    width: 24,
    height: "100%",
  })
)

const AlertBannerText = styled(Typography)<{ color: string }>(({ color }) => ({
  position: "absolute",
  top: 32,
  textTransform: "uppercase",
  color,
  transformOrigin: "top left",
  marginLeft: 18,
  WebkitTransform: "rotate(90deg)",
}))

export const ContentHeader: React.FC<{
  date?: string
  title: string
  id: string
}> = ({ date, title, id }) => {
  const cardTitle = id.startsWith("drafts.") ? `${title} (Draft)` : title
  const formattedDate = dayjsUTC(date).local().format("MM/D/YY")

  return (
    <CardHeader>
      <Typography variant="body1Bold">{cardTitle}</Typography>
      <Typography
        variant="small1"
        sx={{ color: extraColors.hint, marginLeft: "12px" }}
      >
        {formattedDate}
      </Typography>
    </CardHeader>
  )
}

export const ContentSideBar: React.FC<{
  icon: ReactNode
  text: string
  textColor?: string
  backgroundColor?: string
}> = ({ textColor, backgroundColor, icon, text }) => {
  return (
    <SideBarContainer backgroundcolor={backgroundColor || extraColors.light}>
      {icon}
      <AlertBannerText variant={"tiny"} color={textColor || extraColors.medium}>
        {text}
      </AlertBannerText>
    </SideBarContainer>
  )
}
