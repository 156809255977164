import { Switch, Tooltip, Typography, styled } from "@mui/material"

import { extraColors } from "../../utils/theme"
import { useCapServiceContext } from "../CriticalThreats/capServiceContext"
import { useFlags } from "launchdarkly-react-client-sdk"

const ToggleRow = styled("div")({
  display: "flex",
  alignItems: "center",
})

const SwitchStyled = styled(Switch)({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: extraColors.status.teal,
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: extraColors.status.teal,
  },
  "& .MuiSwitch-switchBase": {
    color: extraColors.status.green,
  },
  "& .MuiSwitch-switchBase + .MuiSwitch-track": {
    color: extraColors.status.green,
    backgroundColor: extraColors.status.green,
  },
})

export const SanityDatasetToggle: React.FC = () => {
  const { showCmsDatasetToggle } = useFlags()
  const { sanityDataset, setSanityDataset } = useCapServiceContext()
  const prodIsSelected = sanityDataset === "production"

  if (!showCmsDatasetToggle) return null

  return (
    <Tooltip title="Toggles CMS dataset between PROD and DEV">
      <ToggleRow>
        <Typography
          variant="small1Bold"
          sx={{
            color: extraColors.status.green,
            opacity: prodIsSelected ? 1 : 0.4,
            fontSize: 10,
          }}
        >
          Prod CMS
        </Typography>
        <SwitchStyled
          checked={!prodIsSelected}
          onChange={() =>
            setSanityDataset(prev =>
              prev === "production" ? "development" : "production"
            )
          }
          color={"secondary"}
          size="small"
        />
        <Typography
          variant="small1Bold"
          sx={{
            color: extraColors.status.teal,
            opacity: prodIsSelected ? 0.4 : 1,
            fontSize: 10,
          }}
        >
          Dev CMS
        </Typography>
      </ToggleRow>
    </Tooltip>
  )
}
