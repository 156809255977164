import { AppBar, Toolbar as MuiToolbar, styled } from "@mui/material"

import MenuIcon from "@mui/icons-material/Menu"
import { useRef } from "react"
import { extraColors } from "../../utils/theme"
import DrawerNav from "../DrawerNav"
import { UserNavigation } from "./UserNavigation"
import { useAppLogo } from "../../hooks/useAppLogo"

const StyledAppBar = styled(AppBar)(() => ({
  background: extraColors.subtle,
  padding: "0px 12px",
  // be above map drawers
  zIndex: 1201,
}))

const Toolbar = styled(MuiToolbar)(() => ({
  display: "grid",
  justifyContent: "space-between",
  textAlign: "center",
  gridTemplateColumns: "1fr 1fr 1fr",
  "&": { padding: 0 },
}))

const ToolbarContainer = styled("div")(() => ({
  marginLeft: "auto",
}))

const LogoContainer = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
}))

const Logo = styled("img")(() => ({
  height: 40,
  width: "auto",
}))

export const MobileHeader: React.FC = () => {
  const ref = useRef<{ toggle(): void }>(null)
  const appLogo = useAppLogo()

  return (
    <>
      <StyledAppBar position="static" elevation={0} data-testid="mobile-header">
        <Toolbar color="white">
          <MenuIcon
            height="40"
            width="35"
            onClick={() => ref.current?.toggle()}
            cursor="pointer"
            style={{ color: extraColors.purpleMiddle }}
          />
          <LogoContainer>
            <Logo src={appLogo?.full} alt="phc logo" />
          </LogoContainer>
          <ToolbarContainer>
            <UserNavigation />
          </ToolbarContainer>
        </Toolbar>
        <DrawerNav ref={ref} />
      </StyledAppBar>
    </>
  )
}
