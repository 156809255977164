import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material"
import type React from "react"
import { extraColors } from "../../../../utils/theme"
import { useNotificationTypeContext } from "../contexts/NotificationTypeContext"
import { useNotificationContext } from "../contexts/NotificationContext"

export const ForecastRangeRadioGroup: React.FC = () => {
  const { notificationType } = useNotificationTypeContext()
  const { forecastRange, setForecastRange } =
    useNotificationContext(notificationType)

  return (
    <FormControl
      sx={{
        padding: "10px 20px 20px",
        border: `1px solid ${extraColors.light}`,
        marginTop: "12px",
      }}
    >
      <Typography variant="body1">
        How far in advance would you like to be notified?
      </Typography>
      <Typography variant="small1" sx={{ color: extraColors.medium }}>
        Forecast confidence is likely to be lower for forecasts that are farther
        out in time. If data is unavailable for the selected range, forecasts
        for the next available range will be provided.
      </Typography>
      <RadioGroup
        value={forecastRange}
        name="forecast range"
        onChange={event =>
          setForecastRange?.(parseInt(event.currentTarget.value))
        }
        sx={{ margin: "20px 0 0 12px", gap: "6px" }}
      >
        <FormControlLabel
          value={5}
          control={<Radio />}
          label={
            <>
              <Typography variant="body2">5 weeks' notice</Typography>
              <Typography variant="small1" sx={{ color: extraColors.medium }}>
                Potentially lower confidence
              </Typography>
            </>
          }
        />
        <FormControlLabel
          value={3}
          control={<Radio />}
          label={`3 weeks' notice`}
        />
        <FormControlLabel
          value={1}
          control={<Radio />}
          label={
            <>
              <Typography variant="body2">1 week notice</Typography>
              <Typography variant="small1" sx={{ color: extraColors.medium }}>
                Highest possible confidence
              </Typography>
            </>
          }
        />
      </RadioGroup>
    </FormControl>
  )
}
