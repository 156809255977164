import { Dialog, Typography, styled } from "@mui/material"
import Button from "../../Shared/Button"
import type { ReactNode } from "react"

export const DialogStyled = styled(Dialog)(() => ({
  ".MuiPaper-root": {
    height: "fit-content",
    maxHeight: "100%",
    width: "fit-content",
    maxWidth: 600,
  },
})) as typeof Dialog

export const SelectContainer = styled("div")({
  ".MuiSelect-select": {
    padding: 10,
  },
  ".MuiFormLabel-root": {
    top: -5,
  },
  ".MuiInputLabel-shrink": {
    top: 0,
  },
})

const ButtonContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  paddingTop: 10,
})

export const DialogHeader: React.FC<{ title: string; desc?: string }> = ({
  title,
  desc,
}) => {
  return (
    <>
      <Typography variant="h3Bold" id="asset-modal-title">
        {title}
      </Typography>
      {desc && (
        <Typography
          variant="body1"
          id="asset-modal-description"
          style={{ marginTop: -12 }}
        >
          {desc}
        </Typography>
      )}
    </>
  )
}

export const AssetDialogControls: React.FC<{
  handleModalClose: () => void
  isDisabled: boolean
  action: () => Promise<unknown>
  text: ReactNode
}> = ({ handleModalClose, isDisabled, action: action, text }) => {
  return (
    <ButtonContainer>
      <Button variant="outlined" color="secondary" onClick={handleModalClose}>
        Cancel
      </Button>
      <Button
        variant="contained"
        disabled={isDisabled}
        onClick={() => {
          action().catch(console.error)
          handleModalClose()
        }}
      >
        {text}
      </Button>
    </ButtonContainer>
  )
}
