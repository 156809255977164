import { useState, useEffect } from "react"
import type { MapRef } from "react-map-gl"
import { useMap } from "react-map-gl"
import { ALERT_SOURCE_ID } from "../AlertMarkers"

import { useMapContext } from "../../../../contexts/MapContext"
import type { AlertClusterMarkerProps, AlertMarkerProps } from "../AlertMarker"

/**
 * Creates a cluster marker for the Mapbox map.
 *
 * The callback function needs to do several things:
 * 1. Filter out alerts that are not relevant to the current zoom level.
 * 2. Add a single marker if the cluster only has 1 alert.
 * 3. Add a cluster marker if the cluster has more than 1 alert.
 */
const makeClusterMarker = (
  map: MapRef,
  cluster_id: number,
  clusteredFeatures: GeoJSON.Feature[] | undefined
): AlertClusterMarkerProps | AlertMarkerProps | null => {
  const currentZoom = map.getZoom()
  const visibleFeatures = clusteredFeatures?.filter(
    f => f.properties?.maxZoom >= currentZoom
  )
  const alertCount = visibleFeatures?.length
  if (alertCount === 0) return null

  const firstFeature = visibleFeatures?.[0]

  // check if feature is valid (and to make ts happy)
  if (
    !firstFeature ||
    firstFeature.geometry.type !== "Point" ||
    !firstFeature.properties ||
    typeof firstFeature.properties._id !== "string"
  )
    return null
  const lng = firstFeature.geometry.coordinates[0]
  const lat = firstFeature.geometry.coordinates[1]
  if (!lng || !lat) return null

  // add single marker if cluster only ends up having 1 alert
  if (alertCount === 1) {
    return {
      alertId: firstFeature.properties._id,
      lng,
      lat,
    }
  }

  const alertIds = visibleFeatures
    .map(f => (typeof f.properties?._id === "string" ? f.properties._id : ""))
    .filter(Boolean)

  return {
    cluster_id: cluster_id,
    lng,
    lat,
    alertIds,
  }
}

const isSingleMarker = (
  marker: AlertMarkerProps | AlertClusterMarkerProps | null
): marker is AlertMarkerProps => {
  return !!marker && !("cluster_id" in marker)
}

const isClusterMarker = (
  marker: AlertMarkerProps | AlertClusterMarkerProps | null
): marker is AlertClusterMarkerProps => {
  return !!marker && "cluster_id" in marker
}

export const useAlertCluster = () => {
  const { alertFilters } = useMapContext()

  const mapRef = useMap()
  const [singleMarkerProps, setSingleMarkerProps] = useState<
    (AlertMarkerProps | null)[]
  >([])
  const [clusterMarkerProps, setClusterMarkerProps] = useState<
    (AlertClusterMarkerProps | null)[]
  >([])

  useEffect(() => {
    const map = mapRef.current
    if (!map) return

    const setAlerts = async () => {
      const features = map.querySourceFeatures(
        ALERT_SOURCE_ID
      ) as unknown as GeoJSON.Feature<
        GeoJSON.Point,
        {
          cluster_id?: number
          point_count?: number
          maxZoom?: number
          _id?: string
          locationId?: string
        }
      >[]

      // make clusters
      const clusterPromises = features.map(feature => {
        return new Promise<ReturnType<typeof makeClusterMarker>>(
          (resolve, reject) => {
            const source = map.getSource(ALERT_SOURCE_ID)
            const pointCount = feature.properties.point_count
            const cluster_id = feature.properties.cluster_id
            if (source.type == "geojson" && cluster_id && pointCount) {
              source.getClusterLeaves(
                cluster_id,
                pointCount,
                0,
                (error: { message?: string }, clusteredFeatures) => {
                  // will be refactored with maplibre
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  if (error) {
                    if (error.message === "No cluster with the specified id.") {
                      resolve(null)
                      return
                    }
                    console.error(error)
                    // will be refactored with maplibre
                    // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
                    reject(error)
                    return
                  }
                  const newClusters = makeClusterMarker(
                    map,
                    cluster_id,
                    clusteredFeatures
                  )
                  resolve(newClusters)
                }
              )
            } else {
              resolve(null)
            }
          }
        )
      })

      const clusterMarkers = await Promise.all(clusterPromises)

      const clusterSingleMarkers = clusterMarkers.flatMap(marker =>
        isSingleMarker(marker) ? [marker] : []
      )

      const clusterMarkersWithoutSingles = clusterMarkers.flatMap(marker =>
        isClusterMarker(marker) ? [marker] : []
      )

      const newSingleMarkerProps: (AlertMarkerProps | null)[] = features.map(
        feature => {
          if (feature.properties.cluster_id) return null
          const maxZoom = feature.properties.maxZoom
          const alertId = feature.properties._id

          // type is cast above, trust nothing
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          if (feature.geometry.type !== "Point" || !maxZoom || !alertId)
            return null
          const zoom = map.getZoom()
          // hide parent layer markers
          if (zoom > maxZoom) return null
          const location = feature.geometry.coordinates
          const lng = location[0]
          const lat = location[1]
          if (!lng || !lat) return null
          return {
            alertId: alertId.toString(),
            lng,
            lat,
          }
        }
      )
      const uniqueSingleMarkers = [
        ...newSingleMarkerProps,
        ...clusterSingleMarkers,
      ].filter(
        (m, i, self) => m && self.findIndex(s => s?.alertId === m.alertId) === i
      )
      setSingleMarkerProps(current => {
        // deep array comparison
        if (
          current.length === uniqueSingleMarkers.length &&
          current.every(
            (value, index) =>
              value?.alertId === uniqueSingleMarkers[index]?.alertId
          )
        )
          return current
        return uniqueSingleMarkers
      })

      const uniqueClusterMarkers = clusterMarkersWithoutSingles.filter(
        (m, i, self) =>
          // might be undefined hiding, will be refactored with maplibre
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          m && self.findIndex(s => s.cluster_id === m.cluster_id) === i
      )
      setClusterMarkerProps(current => {
        // deep array comparison
        if (
          current.length === uniqueClusterMarkers.length &&
          current.every(
            (value, index) =>
              value?.cluster_id === uniqueClusterMarkers[index]?.cluster_id
          )
        )
          return current
        return uniqueClusterMarkers
      })
    }

    const handleRender = () => {
      setAlerts().catch(console.error)
    }

    map.on("render", handleRender)

    return () => {
      map.off("render", handleRender)
    }
  }, [alertFilters, mapRef])
  return {
    singleMarkerProps,
    clusterMarkerProps,
  }
}
