import { Typography } from "@mui/material"
import { Disease_Type, type Asset } from "@phc-health/connect-query"
import { useMemo, useState } from "react"
import { extraColors } from "../../../../utils/theme"
import { SettingCheckbox } from "../../../Settings/notifications/SettingCheckbox"
import { useLocationNotificationSettings } from "./hooks/useLocationNotificationSettings"
import { OptionSection, SummarySelectionContainer } from "./Shared"
import { diseaseTypeMapping } from "../../../../utils/helpers/threatTrendHelper"

const ORDERED_DISEASE_TYPES = [
  Disease_Type.RESP,
  Disease_Type.COVID,
  Disease_Type.FLU,
  Disease_Type.RSV,
]

export const LocationRiskIncreaseSettings: React.FC<{
  asset: Asset
}> = ({ asset }) => {
  const {
    currentAssetRiskIncreaseEnabled,
    summarySelectedThreats,
    saveAssetNotifications,
    updateIsPending,
  } = useLocationNotificationSettings(asset)

  // The checkbox state is being managed explicitly here so it can be updated
  // immediately for a snappy experience. If we rely directly on currentAssetRiskIncreaseEnabled
  // for the checkbox state, then the backend update loop is a bit slow and makes
  // the check-action feel delayed when we toggle the value.
  const [riskIncreaseEnabled, setRiskIncreaseEnabled] = useState(
    currentAssetRiskIncreaseEnabled
  )

  const includedThreats = useMemo(() => {
    return ORDERED_DISEASE_TYPES.filter(type =>
      summarySelectedThreats.find(threat => threat === type)
    )
  }, [summarySelectedThreats])

  return (
    <OptionSection>
      <SettingCheckbox
        disabled={updateIsPending}
        settingEnabled={riskIncreaseEnabled}
        setSettingEnabled={setRiskIncreaseEnabled}
        title="Receive Risk Increase notifications for this location."
        customSettingFn={() => {
          setRiskIncreaseEnabled(prev => !prev)
          saveAssetNotifications({ toggleRiskIncrease: true })
        }}
      />
      {!!summarySelectedThreats.length && (
        <SummarySelectionContainer>
          <Typography variant="small1" sx={{ marginBottom: "6px" }}>
            Receiving risk increase notifications for the following threats:
          </Typography>
          {includedThreats.map(threat => {
            return (
              <Typography
                variant="small1"
                key={threat}
                sx={{ color: extraColors.medium }}
              >
                {diseaseTypeMapping(threat)}
              </Typography>
            )
          })}
        </SummarySelectionContainer>
      )}
    </OptionSection>
  )
}
