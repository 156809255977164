import { Typography, styled } from "@mui/material"
import {
  GridActionsCell,
  useGridApiContext,
  type GridTreeNodeWithRender,
  type GridSlotProps,
} from "@mui/x-data-grid-pro"
import type { AlertLevel, AlertWithReferences } from "@phc/common"
import { AlertPill } from "../Shared/AlertPill"
import { Pill } from "../Shared/Pill"
import { GridRowCard } from "../Shared/GridRowCard"
import { extraColors } from "../../utils/theme"
import { DateRenderCell } from "./AlertsShared"

const TitleBox = styled("div")(() => ({
  alignSelf: "start",
  display: "grid",
  gap: 4,
}))

export const MobileRowRender = (
  props: GridSlotProps["row"] & { row: AlertWithReferences }
) => {
  const dataGridRef = useGridApiContext()

  const actions = dataGridRef.current.getCellParams<
    any,
    unknown,
    unknown,
    GridTreeNodeWithRender
  >(props.rowId, "actions")
  // if the _id starts with "drafts." it's a draft
  const isDraft = props.row._id.startsWith("drafts.")
  //add draft to the title if it's a draft
  const title = isDraft ? `${props.row.title ?? ""} (Draft)` : props.row.title

  return (
    <GridRowCard
      {...props}
      style={{
        display: "grid",
        gap: 4,
        padding: "4px 9px 16px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <DateRenderCell
          dateString={props.row.publishedAt ?? props.row.updatedAt ?? ""}
        />
        <GridActionsCell {...actions} />
      </div>
      <AlertContent
        alertLevel={props.row.alertLevel}
        category={props.row.categories?.[0]?.title}
        title={title}
        recommendation={props.row.recommendation}
        justification={props.row.justification}
      />
    </GridRowCard>
  )
}

const AlertContent: React.FC<{
  alertLevel?: AlertLevel
  category?: string
  title?: string
  recommendation?: string
  justification?: string
}> = ({ alertLevel, category, title, recommendation, justification }) => {
  return (
    <TitleBox>
      <div style={{ display: "flex", gap: 16, paddingBottom: 8 }}>
        <AlertPill alertLevel={alertLevel} />
        <Pill
          value={category?.toUpperCase() ?? ""}
          colors={{
            background: extraColors.light,
            border: extraColors.disabled,
            text: extraColors.dark,
          }}
        />
      </div>
      <Typography variant="body2Bold">{title}</Typography>
      <Typography variant="body2" color={extraColors.medium}>
        <Typography variant="inherit" component="span" fontWeight={600}>
          {recommendation}{" "}
        </Typography>
        {justification}
      </Typography>
    </TitleBox>
  )
}
