import { useAuth0 } from "@auth0/auth0-react"
import { Button, styled, TextField, Typography } from "@mui/material"
import { useSnackbar } from "notistack"
import type React from "react"
import { useCallback, useState } from "react"
import { Link, Navigate } from "react-router-dom"
import { useGetAuth0OrgByEmail } from "../hooks/useLookupOrgByEmail"
import { ROUTES } from "../utils/constants"
import { extraColors } from "../utils/theme"
import phcGlobalLogo from "../assets/phc_global_logo_gradient.png"

const Container = styled("div")({
  flex: 1,
})

const LogoWrapper = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
}))

const Content = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  backgroundColor: theme.palette.background.default,
}))

const IconCard = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
})

const Card = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  width: 360,
  borderRadius: 20,
  boxShadow: "3px -5px 40px rgba(205, 205, 212, 0.1)",
  padding: "30px 20px 10px 20px",
  textAlign: "center",
  marginTop: 60,
  display: "grid",
  gap: theme.spacing(2),
}))

const ButtonWrapper = styled("div")({
  marginTop: 10,
  display: "flex",
  justifyContent: "space-between",
})

const SignIn: React.FC = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0()
  const { enqueueSnackbar } = useSnackbar()
  const [value, setValue] = useState<string>("")
  const { refetch: refetchOrgByEmail, isFetching } = useGetAuth0OrgByEmail(
    value.toLowerCase()
  )

  const handleChange = (val: string) => {
    setValue(val)
  }

  const handleSubmit = useCallback(async () => {
    const data = await refetchOrgByEmail()

    const initialUrl = localStorage.getItem("initialUrl") ?? "/"
    localStorage.removeItem("initialUrl")

    if (data.data?.name) {
      await loginWithRedirect({
        authorizationParams: {
          organization: data.data.name,
          login_hint: value,
        },
        appState: {
          returnTo: initialUrl,
        },
      }).catch(console.error)
    } else {
      enqueueSnackbar("Organization not found", {
        variant: "error",
      })
    }
  }, [enqueueSnackbar, loginWithRedirect, refetchOrgByEmail, value])

  if (isAuthenticated) {
    return <Navigate replace to={ROUTES.BASE} />
  }

  return (
    <Container>
      <Content>
        <IconCard data-testid="icon-card">
          <Card data-testid="card">
            <LogoWrapper>
              <img alt="phc global logo" src={phcGlobalLogo} height={90} />
            </LogoWrapper>
            <Typography
              component="h1"
              variant="h5"
              color="textPrimary"
              data-testid="title"
            >
              Enter your email to Sign In
            </Typography>
            <form
              onSubmit={e => {
                e.preventDefault()
                handleSubmit().catch(console.error)
              }}
            >
              <TextField
                type="email"
                name="email"
                label="Email"
                id="username-input"
                onChange={e => handleChange(e.target.value)}
                disabled={isFetching}
                value={value}
                fullWidth
              />
              <Button
                data-testid="sign-in-button"
                type="submit"
                variant="contained"
                color="primary"
                disabled={isFetching}
                fullWidth
                sx={{
                  marginTop: 2,
                }}
              >
                {isFetching ? "Loading..." : "Sign In"}
              </Button>
              <ButtonWrapper>
                <Button
                  variant="text"
                  component={Link}
                  to={ROUTES.PRIVACY_POLICY}
                  data-testid="privacy-button"
                  sx={{
                    textTransform: "uppercase",
                    fontSize: 8,
                    color: extraColors.dark,
                  }}
                >
                  Privacy and Accessibility Policy
                </Button>
                <Button
                  variant="text"
                  component={Link}
                  to={ROUTES.TERMS_AND_CONDITIONS}
                  data-testid="terms-button"
                  sx={{
                    textTransform: "uppercase",
                    fontSize: 8,
                    color: extraColors.dark,
                  }}
                >
                  Terms & Conditions
                </Button>
              </ButtonWrapper>
            </form>
          </Card>
        </IconCard>
      </Content>
    </Container>
  )
}

export default SignIn
