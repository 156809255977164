import { keyframes, styled } from "@mui/material"
import { Banner } from "./Banner"
import { extraColors } from "../../utils/theme"

const gradientAnimation = keyframes`
0% {
  background-position: -100% 0%
  }
100% {
  background-position: 400% 0%
  }
`

export const GradientBanner = styled(Banner)<{ display: string }>(
  ({ display }) => ({
    display,
    marginBottom: 0,
    padding: 0,
    position: "relative",
    zIndex: 1401,
    ".MuiPaper-root": {
      marginBottom: 0,
      padding: 10,
      width: "100%",
      height: "fit-content",
      alignItems: "center",
      background: `linear-gradient(
      to right,
      ${extraColors.white} 0,
      ${extraColors.purpleLight} 50%,
      ${extraColors.white} 100%
    )`,
      backgroundSize: "50% 100%",
      animation: `${gradientAnimation} 6s linear infinite`,
      borderRadius: 0,
      ".MuiAlert-icon": {
        display: "none",
        margin: 0,
      },
      ".MuiAlert-message": {
        padding: 0,
      },
    },
  })
)
