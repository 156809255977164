import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder"
import { Stack, styled, Typography } from "@mui/material"
import { getIdsFromGeocoderResult, type GeocoderResult } from "@phc/common"
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react"
import {
  isAdminBoundaryFromPlaceType,
  placeTypeStringToEnum,
} from "../../../utils/helpers/assetHelper"
import { BORDER_RADIUS, extraColors, theme } from "../../../utils/theme"
import { MAPBOX_ACCESS_TOKEN } from "../../../utils/env"

const StyledGeocoder = styled("div")(() => ({
  "& .mapboxgl-ctrl-geocoder": {
    zIndex: 2,
    borderRadius: BORDER_RADIUS,
    boxShadow: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    color: extraColors.dark,
    maxWidth: "unset",
    width: "100%",

    ".mapboxgl-ctrl-geocoder--icon": {
      top: 10,
      right: 14,
      left: "auto",
      color: extraColors.purpleMiddle,
      fill: extraColors.purpleMiddle,
    },

    ".mapboxgl-ctrl-geocoder--icon-close": {
      position: "unset",
      marginTop: 5,
      color: extraColors.dark,
    },

    "& .mapboxgl-ctrl-geocoder--input": {
      border: `1px solid ${extraColors.disabled}`,
      boxShadow: "none",
      height: 40,
      borderRadius: BORDER_RADIUS,
      color: extraColors.dark,
      paddingLeft: 12,

      "&:hover": {
        borderColor: extraColors.black,
        color: extraColors.dark,
      },
      "&:focus": {
        border: `2px solid ${extraColors.navy}`,
        outline: "none",
      },
    },
  },
}))

export interface GeoLocationSearchInputProps {
  onChangeGeo?: (value?: GeocoderResult, locationIds?: string[]) => void
  setLocationId?: (value?: string) => void
  placeholder?: string
  setIsAdminBoundary?: (value: boolean) => void
  setName?: React.Dispatch<React.SetStateAction<string>>
  helperText?: string
}

export const AssetGeoLocationSearchInput = forwardRef<
  MapboxGeocoder,
  GeoLocationSearchInputProps
>(
  (
    {
      onChangeGeo,
      setLocationId,
      placeholder,
      setIsAdminBoundary,
      setName,
      helperText,
    },
    ref
  ) => {
    const [error, setError] = useState<string>()
    const geocoderRef = useRef(
      new MapboxGeocoder({
        accessToken: MAPBOX_ACCESS_TOKEN ?? "",
        placeholder: placeholder ?? "Location",
      })
    )
    // make the geocoder ref available to the parent component
    useImperativeHandle(ref, () => {
      return geocoderRef.current
    }, [])

    const mountGeocoder = useCallback(
      (node: HTMLDivElement | null) => {
        if (node !== null) {
          const geocoder = geocoderRef.current
          if (!node.firstChild) geocoder.addTo(node)

          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          geocoder.on("result", async (result: GeocoderResult) => {
            const locationIds = (
              await getIdsFromGeocoderResult(result, MAPBOX_ACCESS_TOKEN)
            ).map(f => f.properties.location_code ?? "")
            const locationId = locationIds[0]?.toString()
            onChangeGeo?.(result, locationIds)
            setLocationId?.(locationId)

            setIsAdminBoundary?.(
              isAdminBoundaryFromPlaceType(
                placeTypeStringToEnum(result.result?.place_type[0]?.toString())
              )
            )
            // If there's already a nickname set for the location, don't overwrite it.
            setName?.(prev =>
              !prev && result.result?.text ? result.result.text : prev
            )
          })

          geocoder.on("clear", () => {
            setError(undefined)
            onChangeGeo?.()
            setLocationId?.()
          })
        }
      },
      [onChangeGeo, setIsAdminBoundary, setLocationId, setName]
    )

    return (
      <>
        <StyledGeocoder ref={mountGeocoder} />
        {helperText && (
          <Typography
            variant="small1"
            display="block"
            color={extraColors.medium}
          >
            {helperText}
          </Typography>
        )}
        {error && (
          <Stack direction="row" spacing={1} paddingTop={1}>
            <Typography color="error">{error}</Typography>
          </Stack>
        )}
      </>
    )
  }
)

AssetGeoLocationSearchInput.displayName = "AssetGeoLocationSearchInput"
