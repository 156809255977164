import { Typography, styled } from "@mui/material"
import {
  ForecastRangeKey,
  ForecastedAbsenteeismKey,
  HistoricKey,
} from "./workforcePlanningShared"
import { extraColors } from "../../utils/theme"

const LegendContainer = styled("div")({
  display: "grid",
  alignItems: "center",
  justifyContent: "center",
  gap: "16px",
  marginTop: "10px",
  background: extraColors.subtle,
  borderRadius: 6,
  padding: 6,
  ["@container (min-width: 580px)"]: {
    gridTemplateColumns: "repeat(3, auto)",
  },
})

const LegendItem = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "5px",
  color: extraColors.medium,
})

export const WorkforcePlanningLegend: React.FC = () => {
  return (
    <LegendContainer>
      <LegendItem>
        <HistoricKey />
        <Typography variant="small1" color="inherit">
          Historic Absenteeism
        </Typography>
      </LegendItem>
      <LegendItem>
        <ForecastedAbsenteeismKey />
        <Typography variant="small1" color="inherit">
          Forecasted Absenteeism
        </Typography>
      </LegendItem>
      <LegendItem>
        <ForecastRangeKey />
        <Typography variant="small1" color="inherit">
          Forecast Range (confidence)
        </Typography>
      </LegendItem>
    </LegendContainer>
  )
}
