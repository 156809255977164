import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material"
import type { Organization } from "@phc-health/connect-query"
import { OrganizationStatusEnum } from "@phc-health/connect-query"
import { useEffect, useState } from "react"
import { usePermissions } from "../hooks/usePermissions"
import { useUpdateOrganization } from "../hooks/useUpdateOrganization"
import { getOrganizationStatusNameFromStatus } from "../utils/helpers/identityHelpers"
import Button from "./Shared/Button"
import ContentCard from "./ContentCard"
import { Modal } from "./Shared/Modal"

const OrganizationAccountStatus: React.FC<{
  organization?: Organization
}> = ({ organization }) => {
  const { mutate } = useUpdateOrganization()
  const [openDeactivateModal, setOpenDeactivateModal] = useState(false)
  const [editAccountStatus, setEditAccountStatus] = useState(false)
  const [accountStatus, setAccountStatus] = useState(organization?.status)
  const permissions = usePermissions()

  useEffect(() => {
    setAccountStatus(organization?.status)
  }, [organization?.status])

  return (
    <ContentCard
      data-testid="organization-account-status"
      title="Account Status"
      sections={[
        {
          content: (
            <div>
              <Typography
                variant="body1"
                color="textPrimary"
                style={{ marginBottom: 32 }}
                component="div"
              >
                This organization is currently{" "}
                {getOrganizationStatusNameFromStatus(organization?.status)}.
              </Typography>
              {!editAccountStatus ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setEditAccountStatus(true)}
                  disabled={!permissions.organizations.write}
                >
                  CHANGE ACCOUNT STATUS
                </Button>
              ) : (
                <>
                  <FormControl style={{ width: "fit-content" }}>
                    <RadioGroup
                      value={accountStatus}
                      onChange={event =>
                        setAccountStatus(
                          // TODO: Remove this type assertion once we figure out how to use new buf lib
                          event.target
                            .value as unknown as OrganizationStatusEnum
                        )
                      }
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: 24,
                      }}
                    >
                      {Object.keys(OrganizationStatusEnum)
                        .filter(
                          status =>
                            status !==
                            OrganizationStatusEnum[
                              OrganizationStatusEnum
                                .ORGANIZATION_STATUS_UNSPECIFIED
                            ]
                        )
                        .map(status => (
                          <FormControlLabel
                            key={status}
                            value={status}
                            control={<Radio />}
                            label={getOrganizationStatusNameFromStatus(
                              // TODO: Remove this type assertion once we figure out how to use new buf lib
                              status as unknown as OrganizationStatusEnum
                            )}
                          />
                        ))}
                    </RadioGroup>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setEditAccountStatus(false)
                        }}
                        style={{ marginRight: 20, width: 101 }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          if (
                            !accountStatus ||
                            accountStatus === organization?.status
                          ) {
                            setEditAccountStatus(false)
                            return
                          }
                          if (
                            accountStatus ===
                            OrganizationStatusEnum.ORGANIZATION_DISABLED
                          ) {
                            setOpenDeactivateModal(true)
                            return
                          }
                          mutate({
                            organization: {
                              ...organization,
                              status: accountStatus,
                            },
                            updateMask: {
                              paths: ["status"],
                            },
                          })
                          setEditAccountStatus(false)
                        }}
                        style={{ width: 101 }}
                      >
                        Save
                      </Button>
                    </div>
                  </FormControl>
                </>
              )}
              <Modal
                title="Deactivate Organization"
                open={openDeactivateModal}
                onClose={() => {
                  setOpenDeactivateModal(false)
                }}
                onConfirm={() => {
                  mutate({
                    organization: {
                      ...organization,
                      status: OrganizationStatusEnum.ORGANIZATION_DISABLED,
                    },
                    updateMask: {
                      paths: ["status"],
                    },
                  })
                  setOpenDeactivateModal(false)
                  setEditAccountStatus(false)
                }}
                confirmLabel="Deactivate"
              >
                <>
                  By deactivating this organization, all users within the
                  organization will also be deactivated.
                </>
              </Modal>
            </div>
          ),
        },
      ]}
    />
  )
}

export default OrganizationAccountStatus
