import { Box } from "@mui/material"
import type React from "react"
import { MainContent } from "../MainContent.js"
import { useGetFirstRelatedCriticalThreat } from "../CriticalThreats/useGetFirstRelatedCriticalThreat.js"
import { LocationDetailsTabs } from "./LocationDetailsTabs.js"

export const LocationDetails: React.FC<{
  locationId: string
  title: string
  subtitle?: string
}> = ({ locationId, title, subtitle }) => {
  const formattedTitle = title && subtitle ? `${title} ` : title
  const { relatedCriticalThreat } = useGetFirstRelatedCriticalThreat(locationId)

  return (
    <Box paddingTop="14px">
      <MainContent
        headerProps={{
          title: formattedTitle || "",
          subtitle: subtitle,
          showBackButton: true,
          relatedCriticalThreat,
        }}
      >
        <LocationDetailsTabs />
      </MainContent>
    </Box>
  )
}
