import { styled } from "@mui/material"
import { extraColors } from "../../utils/theme"
import { dayjsUTC } from "../../utils/helpers"
import type { Percentile } from "@phc-health/connect-query"

export const WorkforcePlanningContainer = styled("div")({
  background: extraColors.white,
  borderColor: extraColors.disabled,
  borderRadius: 6,
  borderWidth: 1,
  borderStyle: "solid",
  padding: 24,
  containerType: "inline-size",
  "@media print": {
    border: "none",
  },
})

export const BAR_COLOR = extraColors.purpleDark

export const HistoricKey = styled("span")(() => ({
  width: "12px",
  height: "12px",
  backgroundColor: BAR_COLOR,
  borderRadius: 2,
}))

export const ForecastRangeKey = styled(HistoricKey)(() => ({
  backgroundColor: extraColors.dark,
  opacity: 0.3,
}))

export const ForecastedAbsenteeismKey = styled("span")(() => ({
  width: "12px",
  height: "2px",
  backgroundColor: extraColors.medium,
}))

export const CURRENT_WEEK_TEXT = "Current Week"
/** Print MMM D or "Current Week" */
export const makeGraphDateString = (seconds: bigint | number | undefined) => {
  const date = dayjsUTC(Number(seconds) * 1000)
  const currentWeek = dayjsUTC().isSame(date, "week")
  return currentWeek ? CURRENT_WEEK_TEXT : date.format("MMM D")
}

export const sortPercentile = (percentiles: Percentile[] = []) =>
  percentiles.map(p => p.value).sort()

/** Truncate to 2 decimal places
 *
 * @example
 * trunc(1.126) // 1.12
 */
export const trunc = (num: number) => Math.trunc(num * 100) / 100

/** Present 2 decimal places, including trailing zeros
 *
 * @example
 * present2Decimal(1.1) // "1.10"
 */
export const present2Decimal = (num: number | undefined) =>
  trunc(num ?? 0).toFixed(2)
