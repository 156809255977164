import { Box, styled, Tab, Tabs } from "@mui/material"
import type React from "react"
import { useState } from "react"
import { MainContent } from "../MainContent"
import TabPanel from "../TabsPanel"
import { AccountDetails } from "./AccountDetails"
import { Notifications } from "./notifications/Notifications"
import { Navigate } from "react-router-dom"
import { ROUTES } from "../../utils/constants"
import { usePermissions } from "../../hooks/usePermissions"

const StyledTabPanel = styled(TabPanel)(() => ({
  "& .MuiBox-root": {
    padding: "24px 0 0 0",
  },
  marginBottom: 70,
}))

export const Settings = () => {
  const permissions = usePermissions()
  const [activeTab, setActiveTab] = useState(1)

  if (!permissions.isLoading && !permissions.currentUser.preferences.read) {
    return <Navigate replace to={ROUTES.BASE} />
  }

  const handleChange = (event: React.SyntheticEvent, newTab: number) => {
    setActiveTab(newTab)
  }

  function a11yProps(index: number) {
    return {
      id: `settings-tab-${index}`,
      "aria-controls": `settings-tabpanel-${index}`,
    }
  }

  return (
    <MainContent
      headerProps={{
        title: "Settings",
      }}
    >
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{ borderBottom: 1, borderColor: "divider", width: "fit-content" }}
        >
          <Tabs
            value={activeTab}
            onChange={handleChange}
            aria-label="settings tabs"
          >
            <Tab label="Account Details" {...a11yProps(0)} />
            <Tab label="Notifications" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <StyledTabPanel
          value={activeTab}
          index={0}
          ariaLabel={activeTab.toString()}
        >
          <AccountDetails />
        </StyledTabPanel>
        <StyledTabPanel
          sx={{ display: "flex", justifyContent: "center" }}
          value={activeTab}
          index={1}
          ariaLabel={activeTab.toString()}
        >
          <Notifications />
        </StyledTabPanel>
      </Box>
    </MainContent>
  )
}
