import { AssetServiceQuery } from "@phc-health/connect-query"
import { createConnectQueryKey, useMutation } from "@connectrpc/connect-query"
import { useSnackbar } from "notistack"
import { useTransport } from "../../../../hooks/useTransport"
import { ASSET_SERVICE_API } from "../../../../utils/constants"
import { useQueryClient } from "@tanstack/react-query"

export const useCreateAsset = ({
  hideToast,
  skipQueryInvalidation,
}: { hideToast?: boolean; skipQueryInvalidation?: boolean } = {}) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()
  const successMessage = "Your new location has been saved."
  const transport = useTransport(ASSET_SERVICE_API)
  return useMutation(AssetServiceQuery.createAsset, {
    transport,
    onError: (err, req) => {
      console.error(err, req)
      enqueueSnackbar("Unable to create this new location.", {
        variant: "error",
      })
    },
    onSuccess: () => {
      if (!hideToast) {
        enqueueSnackbar(successMessage, {
          variant: "success",
        })
      }
    },
    onSettled: async () => {
      if (skipQueryInvalidation) return
      await queryClient.invalidateQueries({
        queryKey: createConnectQueryKey(AssetServiceQuery.listAssets),
      })

      await queryClient.invalidateQueries({
        queryKey: createConnectQueryKey(AssetServiceQuery.getAsset),
      })

      await queryClient.invalidateQueries({
        queryKey: createConnectQueryKey(AssetServiceQuery.listAssetGroups),
      })
    },
  })
}

export const useBulkCreateAssets = () => {
  const { enqueueSnackbar } = useSnackbar()
  const transport = useTransport(ASSET_SERVICE_API)
  return useMutation(AssetServiceQuery.createAssets, {
    transport,
    onError: err => {
      console.error(err)
      enqueueSnackbar("Unable to create these new locations.", {
        variant: "error",
      })
    },
  })
}
