import {
  determineSourceLayerContext,
  mapboxConfig,
  isAlertLevel,
} from "@phc/common"
import { useMemo } from "react"
import { useMapContext } from "../../../../contexts/MapContext"
import { getLocationCenter } from "../../../../utils/helpers/locationCenterHelper"
import { useCapServiceContext } from "../../../CriticalThreats/capServiceContext"

export const useAlertGeoJson = () => {
  const { useAlerts } = useCapServiceContext()
  const { data: alerts } = useAlerts()
  const { alertFilters } = useMapContext()

  const alertGeoJSON = useMemo(
    () =>
      ({
        type: "FeatureCollection",
        features: (alerts
          ?.filter(alert => {
            const isAlert =
              isAlertLevel(alert.alertLevel) &&
              alertFilters.includes(alert.alertLevel)

            const hasCenter = getLocationCenter(
              alert.geotag?.locationId,
              alert.geotag?.centroid
            )

            return isAlert && hasCenter
          })
          .map(alert => {
            const location = getLocationCenter(
              alert.geotag?.locationId,
              alert.geotag?.centroid
            )
            if (!location) {
              console.error(
                "Could not find location center for alert",
                alert._id,
                alert.geotag?.locationId
              )
              return
            }

            const layerConfig = mapboxConfig.sourceLayer.find(
              layer =>
                layer.sourceInfo.source === alert.geotag?.countryCode &&
                layer.context ===
                  determineSourceLayerContext(alert.geotag.locationId)
            )

            return {
              type: "Feature" as const,
              geometry: {
                type: "Point" as const,
                coordinates: [location.lng, location.lat],
              },
              properties: {
                _id: alert._id,
                locationId: alert.geotag?.locationId,
                maxZoom: layerConfig?.defaultZoomLevels.maxzoom,
              },
            }
          })
          .flatMap(a => (a ? a : [])) ?? []) satisfies GeoJSON.Feature[],
      }) satisfies GeoJSON.FeatureCollection,
    [alertFilters, alerts]
  )
  return alertGeoJSON
}
