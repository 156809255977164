import * as React from "react"
import { styled } from "@mui/material/styles"
import Button from "../Shared/Button"
import type { MenuProps } from "@mui/material/Menu"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import AddIcon from "@mui/icons-material/Add"
import { extraColors } from "../../utils/theme"

const StyledButton = styled(Button)({
  width: 198,
})

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 3,
    marginTop: theme.spacing(1),
    minWidth: 130,
    boxShadow: `0px 9px 12px 0px ${extraColors.shadow}`,
  },
}))

const StyledMenuItem = styled(MenuItem)({
  padding: "6px 12px",
})

export const LocationActionButton: React.FC<{
  openModal: (isBulk: boolean) => void
}> = ({ openModal }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleMenuItemClick = (isBulk: boolean) => {
    setAnchorEl(null)
    openModal(isBulk)
  }

  return (
    <div>
      <StyledButton
        id="add-location-button"
        aria-controls={open ? "add-location-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        <AddIcon fontSize="small" sx={{ paddingRight: 0.5 }} />
        Add Location
      </StyledButton>
      <StyledMenu
        id="add-location-menu"
        MenuListProps={{
          "aria-labelledby": "add-location-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <StyledMenuItem
          onClick={() => handleMenuItemClick(false)}
          disableRipple
        >
          Single Location
        </StyledMenuItem>
        <StyledMenuItem onClick={() => handleMenuItemClick(true)} disableRipple>
          Bulk Upload
        </StyledMenuItem>
      </StyledMenu>
    </div>
  )
}
