import type React from "react"
import { MainContent } from "../MainContent"
import { SectionContainer } from "../LocationDetails/LocationRisk/Shared"
import { CriticalThreatHeader } from "./CriticalThreatHeader"
import { useParams } from "react-router-dom"
import { useMemo, useState } from "react"
import { styled } from "@mui/material"
import type { CriticalThreatWithReferences } from "@phc/common"
import { RelatedAlert } from "./RelatedContent/RelatedAlert"
import { RelatedContent } from "./RelatedContent/RelatedContent"
import { ContentFilters } from "./ContentFilters/ContentFilters"
import { toPlainText } from "@portabletext/react"
import type { ContentTypeValue } from "./ContentFilters/ContentTypeFilter"
import { useCapServiceContext } from "./capServiceContext"

const HomeContentContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  placeContent: "center",
  padding: "0 10px",
  [theme.breakpoints.up("sm")]: {
    padding: 0,
  },
}))

const SectionContainerStyled = styled(SectionContainer)(({ theme }) => ({
  marginTop: 0,
  [theme.breakpoints.up("sm")]: {
    marginTop: 16,
  },
}))

const ContentContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  maxWidth: 760,
  width: "100%",
  alignSelf: "center",
  marginBottom: 30,
  [theme.breakpoints.up("sm")]: {
    minWidth: 300,
    width: "100%",
  },
}))

export const CriticalThreat: React.FC = () => {
  const { slug } = useParams()
  const { useCriticalThreats } = useCapServiceContext()
  const { data } = useCriticalThreats()

  const criticalThreat = useMemo(() => {
    return data?.find(threat => threat.slug?.current === slug)
  }, [data, slug])

  return (
    <MainContent
      headerProps={{
        title: "Critical Threat Monitoring",
        showBackButton: true,
      }}
      disableLeftGutter
    >
      <HomeContentContainer>
        <SectionContainerStyled>
          <CriticalThreatHeader criticalThreat={criticalThreat} />
        </SectionContainerStyled>
        <CMSContentItems content={criticalThreat?.content} isLoading={false} />
      </HomeContentContainer>
    </MainContent>
  )
}

const CMSContentItems: React.FC<{
  content?: CriticalThreatWithReferences["content"]
  isLoading: boolean
}> = ({ content }) => {
  const [searchText, setSearchText] = useState("")
  const [selectedGeotagIds, setSelectedGeotagIds] = useState<Set<string>>(
    new Set()
  )
  const [contentType, setContentType] = useState<ContentTypeValue>()

  const filteredContent = useMemo(() => {
    const contentTypeFiltered = filterContentOnType(contentType, content)

    const searchFiltered = filterContentOnSearchText(
      searchText,
      contentTypeFiltered
    )

    const geoTagFiltered = filterContentOnGeotags(
      selectedGeotagIds,
      searchFiltered
    )

    return geoTagFiltered?.sort((a, b) => {
      const aDate = a.publishedAt || a.updatedAt || ""
      const bDate = b.publishedAt || b.updatedAt || ""
      return (
        bDate.localeCompare(aDate) || a.title?.localeCompare(b.title ?? "") || 0
      )
    })
  }, [content, contentType, searchText, selectedGeotagIds])

  return (
    <ContentContainer>
      <ContentFilters
        content={content}
        selectedGeotagIds={selectedGeotagIds}
        setSelectedGeotagIds={setSelectedGeotagIds}
        searchText={searchText}
        setSearchText={setSearchText}
        contentType={contentType}
        setContentType={setContentType}
      />
      <>
        {filteredContent?.map(contentItem =>
          contentItem._type === "alert" ? (
            <RelatedAlert key={contentItem._id} relatedAlert={contentItem} />
          ) : (
            <RelatedContent
              key={contentItem._id}
              relatedContent={contentItem}
            />
          )
        )}
      </>
    </ContentContainer>
  )
}

function filterContentOnSearchText(
  searchText: string,
  content?: CriticalThreatWithReferences["content"]
) {
  if (!searchText) return content

  const search = searchText.toLowerCase()
  return content?.filter(contentItem => {
    const title = contentItem.title?.toLowerCase()

    if (contentItem._type === "alert") {
      return (
        contentItem.recommendation?.toLowerCase().includes(search) ||
        contentItem.justification?.toLowerCase().includes(search) ||
        title?.includes(search)
      )
    }

    // account for other _types that may be added in the future
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (contentItem._type === "headlines" || contentItem._type === "guidance") {
      const { body } = contentItem
      const bodyText = body ? toPlainText(body).toLowerCase() : ""
      return bodyText.includes(search.toLowerCase()) || title?.includes(search)
    }
  })
}

function filterContentOnGeotags(
  selectedGeotagIds: Set<string>,
  content?: CriticalThreatWithReferences["content"]
) {
  if (!selectedGeotagIds.size) return content

  return content?.filter(contentItem => {
    switch (contentItem._type) {
      case "alert":
        if (contentItem.geotag?.locationId) {
          return selectedGeotagIds.has(contentItem.geotag.locationId)
        }
        break
      case "headlines":
        return !!contentItem.geotags?.find(
          tag => tag.locationId && selectedGeotagIds.has(tag.locationId)
        )
      default:
        return false
    }
  })
}

function filterContentOnType(
  contentType: ContentTypeValue | undefined,
  content?: CriticalThreatWithReferences["content"]
) {
  if (!contentType) return content
  return content?.filter(contentItem => contentItem._type === contentType)
}
