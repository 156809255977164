import { Layer, Source, useMap } from "react-map-gl"
import { useAssetGeoJson } from "./hooks/useAssetGeoJson"
import marker from "../../../assets/pngs/location-dot.png"
import { useEffect } from "react"

export const ASSET_SOURCE_ID = "asset-source"
export const ASSET_LAYER_ID = "asset-layer"
const MARKER_IMAGE_ID = "marker-image"

export const AssetMarkers: React.FC = () => {
  const assetGeoJSON = useAssetGeoJson()
  const mapRef = useMap()
  useEffect(() => {
    if (mapRef.current && !mapRef.current.hasImage(MARKER_IMAGE_ID)) {
      mapRef.current.loadImage(marker, (error, image) => {
        if (error) throw error
        if (
          mapRef.current &&
          !mapRef.current.hasImage(MARKER_IMAGE_ID) &&
          image
        ) {
          mapRef.current.addImage(MARKER_IMAGE_ID, image, {})
        }
      })
    }
  }, [mapRef])
  return (
    <>
      <Source id={ASSET_SOURCE_ID} type="geojson" data={assetGeoJSON}>
        <Layer
          id={ASSET_LAYER_ID}
          type="symbol"
          layout={{
            "icon-image": MARKER_IMAGE_ID,
            "icon-allow-overlap": true,
            "icon-anchor": "bottom",
          }}
        />
      </Source>
    </>
  )
}
