import { TextField, Typography, styled } from "@mui/material"
import type { AssetGroup } from "@phc-health/connect-query"
import {
  Asset,
  AssetType,
  Geotag,
  IndustryType,
} from "@phc-health/connect-query"

import type { GeocoderResult } from "@phc/common"
import type React from "react"
import { useCallback, useState } from "react"

import {
  isAdminBoundaryFromPlaceType,
  convertGeocoderResultToLocation,
} from "../../../../utils/helpers/assetHelper"
import { getCentroidFromGeocoder } from "../../../../utils/helpers/locationCenterHelper"
import { AssetGroupAutocomplete } from "../../../WatchedLocations/AssetManagement/AssetGroupAutocomplete"
import { AssetTypeAutocomplete } from "../../../WatchedLocations/AssetManagement/AssetTypeAutocomplete"
import { AssetGeoLocationSearchInput } from "../../../WatchedLocations/AssetManagement/GeoLocationSearchInput"
import { IndustryAutocomplete } from "../../../WatchedLocations/AssetManagement/IndustryAutocomplete"
import { AssetDialogControls } from "../../../WatchedLocations/AssetManagement/Shared"
import { useUpdateAsset } from "../../../WatchedLocations/AssetManagement/hooks/useUpdateAsset"
import { extraColors } from "../../../../utils/theme"
import { getLocationId } from "../../../../utils/helpers"

const MAX_NOTES_CHARACTERS = 250

const AssetEditContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: 20,
  marginTop: 20,
})

const NotesContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
})

interface EditSiteDetailsgProps {
  asset: Asset
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>
}

export const EditSiteDetails: React.FC<EditSiteDetailsgProps> = ({
  asset,
  setIsEditing,
}) => {
  const [selectedGroups, setSelectedGroups] = useState<
    AssetGroup[] | undefined
  >(asset.assetGroups)
  const [selectedAssetType, setSelectedAssetType] = useState(
    asset.assetTypes[0] || AssetType.ASSET_TYPE_UNSPECIFIED
  )

  const { mutateAsync: update, isPending: updateIsLoading } = useUpdateAsset()
  const [locationId, setLocationId] = useState(getLocationId(asset))
  const [name, setName] = useState(asset.name || "")
  const [notes, setNotes] = useState(asset.notes || "")

  const [selectedIndustryType, setSelectedIndustryType] = useState(
    asset.industry || IndustryType.UNSPECIFIED
  )
  const [groups, setGroups] = useState<AssetGroup[] | undefined>(
    asset.assetGroups
  )
  const [location, setLocation] = useState<GeocoderResult | undefined>()
  const [isAdminBoundary, setIsAdminBoundary] = useState(
    isAdminBoundaryFromPlaceType(asset.baseEvent?.mapboxLocation?.placeType)
  )

  const saveAsset = useCallback(async () => {
    const existingGeotag = asset.baseEvent?.geotags[0]
    const newGeotag = new Geotag({
      ...existingGeotag,
      locationId,
      // if location hasn't changed, use the original location
      mapboxLocation: location
        ? convertGeocoderResultToLocation(location)
        : existingGeotag?.mapboxLocation,
      wktCentroid: getCentroidFromGeocoder(location),
    })
    const newAsset = new Asset({
      ...asset,
      name,
      notes,
      industry: selectedIndustryType,
      assetGroups: selectedGroups,
      assetTypes: [selectedAssetType],
      baseEvent: {
        ...asset.baseEvent,
        geotags: [newGeotag],
      },
    })

    await update({ asset: newAsset })
  }, [
    asset,
    location,
    locationId,
    name,
    notes,
    selectedAssetType,
    selectedGroups,
    selectedIndustryType,
    update,
  ])

  return (
    <AssetEditContainer>
      <TextField
        id="outlined"
        label="Nickname"
        value={name}
        onChange={event => {
          setName(event.target.value)
        }}
      />

      <AssetGeoLocationSearchInput
        onChangeGeo={setLocation}
        setLocationId={setLocationId}
        placeholder={asset.baseEvent?.mapboxLocation?.placeName}
        setIsAdminBoundary={setIsAdminBoundary}
        setName={setName}
      />

      <IndustryAutocomplete
        selectedIndustry={selectedIndustryType}
        setSelectedIndustry={setSelectedIndustryType}
        isDisabled={isAdminBoundary}
      />

      <AssetGroupAutocomplete
        groups={groups || []}
        setGroups={setGroups}
        selectedGroups={selectedGroups}
        setSelectedGroups={setSelectedGroups}
      />

      <AssetTypeAutocomplete
        selectedAssetType={selectedAssetType}
        setSelectedAssetType={setSelectedAssetType}
        isDisabled={isAdminBoundary}
      />

      <NotesContainer>
        <TextField
          id="outlined"
          label="Notes"
          multiline
          value={notes}
          onChange={event => {
            setNotes(event.target.value)
          }}
          inputProps={{ maxLength: MAX_NOTES_CHARACTERS }}
        />
        <Typography
          variant="small1"
          sx={{
            marginTop: "6px",
            alignSelf: "flex-end",
            color: extraColors.medium,
          }}
        >
          {notes.length}/{MAX_NOTES_CHARACTERS}
        </Typography>
      </NotesContainer>

      <AssetDialogControls
        handleModalClose={() => setIsEditing(false)}
        isDisabled={
          updateIsLoading ||
          (!location && !asset.baseEvent?.mapboxLocation?.placeType)
        }
        action={saveAsset}
        text={"Save Changes"}
      />
    </AssetEditContainer>
  )
}
