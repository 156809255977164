import { Skeleton, styled, Typography } from "@mui/material"
import type React from "react"
import { Link } from "react-router-dom"
import SvgAssets from "../../../assets/navigation/Assets"
import { ROUTES, stringifySearchParams } from "../../../utils/constants"
import { cleanSubtitle } from "../../../utils/helpers"
import { useGetFirstRelatedCriticalThreat } from "../../CriticalThreats/useGetFirstRelatedCriticalThreat"
import { CriticalThreatTag } from "../../CriticalThreats/CriticalThreatTag"

const TitleRow = styled("div")({
  display: "grid",
  gridTemplateColumns: "min-content 1fr",
  padding: "16px 0px 0px 12px",
  alignItems: "flex-start",
})

const Title = styled(Link)(() => ({
  display: "flex",
  textDecoration: "none",
  cursor: "pointer",
  marginLeft: 5,
}))

const TitleContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
})

const DetailsContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
})

const IconContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  marginLeft: 5,
  height: 16,
})

interface TitleProps {
  id: string
  title?: string
  subtitle?: string
  subtitleShort?: string
}

export const TitleHeader: React.FC<TitleProps> = ({
  id,
  title = "",
  subtitle = "",
}) => {
  const { relatedCriticalThreat } = useGetFirstRelatedCriticalThreat(id)

  // if the subtitle includes the title, remove that part from the string
  // and remove leading comma if it exists
  const formattedSubtitle = cleanSubtitle(title, subtitle)
  return (
    <TitleRow>
      <SvgAssets fill={""} />
      <Title
        to={{
          pathname: ROUTES.LOCATION_DETAILS,
          search: stringifySearchParams({
            id,
            title,
            subtitle,
          }),
        }}
      >
        {title || formattedSubtitle ? (
          <DetailsContainer>
            <TitleContainer>
              <Typography variant="body1Bold" component="div" lineHeight={1}>
                {title}
              </Typography>
              {relatedCriticalThreat && (
                <IconContainer>
                  <CriticalThreatTag
                    relatedCriticalThreat={relatedCriticalThreat}
                  />
                </IconContainer>
              )}
            </TitleContainer>
            <Typography variant="body2" pt={0.5}>
              {formattedSubtitle}
            </Typography>
          </DetailsContainer>
        ) : (
          <Skeleton />
        )}
      </Title>
    </TitleRow>
  )
}
