import type React from "react"
import { styled } from "@mui/material"
import { extraColors } from "../../../utils/theme"
import { Dropdown } from "../../Shared/Dropdown"
import type { Alert, Analysis, Guidance } from "@phc/common"
import { objectKeys } from "../../../utils/helpers"

export const ContentTypes: Record<ContentTypeValue, string> = {
  alert: "Alerts",
  headlines: "Analysis",
  guidance: "Guidance",
}

export type ContentTypeValue = (Analysis | Guidance | Alert)["_type"]

const isContentType = (value: string): value is ContentTypeValue => {
  return objectKeys(ContentTypes).includes(value as ContentTypeValue)
}

const DropdownStyled = styled(Dropdown)(({ theme }) => ({
  "& .MuiInputBase-root": {
    borderRadius: 6,
  },
  background: extraColors.white,
  width: "100%",
  marginTop: 12,
  [theme.breakpoints.up("sm")]: {
    marginLeft: 20,
    minWidth: 300,
    marginTop: 0,
  },
}))

export const ContentTypeDropdown: React.FC<{
  contentType: ContentTypeValue | undefined
  setContentType: React.Dispatch<
    React.SetStateAction<ContentTypeValue | undefined>
  >
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}> = ({ contentType, setContentType }) => {
  const contentTypeOptions = Object.entries(ContentTypes).map(ct => {
    return {
      name: ct[1],
      value: ct[0],
    }
  })

  return (
    <DropdownStyled
      options={contentTypeOptions}
      onChange={e =>
        isContentType(e.target.value)
          ? setContentType(e.target.value)
          : setContentType(undefined)
      }
      value={contentType}
      placeholder="All Intelligence"
    />
  )
}
