import { useFlags } from "launchdarkly-react-client-sdk"
import { useEffect, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import { useSearchParams } from "../../hooks/useSearchParams"
import { LOCATION_IDS_WITH_STALE_DATA, ROUTES } from "../../utils/constants"
import { Banner } from "./Banner"
import { useGetAsset } from "../WatchedLocations/hooks/useAssetService"
import { getLocationId } from "../../utils/helpers"

export const StaleDataBanner: React.FC = () => {
  const location = useLocation()
  const { showStaleDataBanner } = useFlags()
  const [showBanner, setShowBanner] = useState<boolean>(false)
  const { assetId } = useParams<{ assetId: string }>()
  const { id: locationId } = useSearchParams() // needed for location-details page
  const { data } = useGetAsset(assetId)
  const selectedLocationId = getLocationId(data?.asset) || locationId || ""
  const isLocationWithStaleData = LOCATION_IDS_WITH_STALE_DATA.some(id =>
    selectedLocationId.startsWith(id)
  )
  const isAssetOrLocationDetailsPage =
    location.pathname.includes(ROUTES.LOCATION_DETAILS) ||
    location.pathname.includes(ROUTES.ASSETS)

  useEffect(() => {
    setShowBanner(isLocationWithStaleData && isAssetOrLocationDetailsPage)
  }, [isAssetOrLocationDetailsPage, isLocationWithStaleData])

  if (
    !showStaleDataBanner ||
    !isAssetOrLocationDetailsPage ||
    !selectedLocationId.length
  ) {
    return null
  }

  return (
    <Banner
      type="warning"
      open={showBanner}
      dismissFunction={() => setShowBanner(false)}
    >
      This country has not reported any influenza testing data in the past 28
      days. Typically, this is an indication of low flu activity in the country,
      though it may be the result of unexpected non-reporting.
    </Banner>
  )
}
