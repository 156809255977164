import { Typography, styled } from "@mui/material"
import { PieChart, pieArcClasses } from "@mui/x-charts"
import type { AlertLevel } from "@phc/common"
import { isAlertLevel } from "@phc/common"
import type React from "react"
import { Marker } from "react-map-gl"
import { useMapContext } from "../../../contexts/MapContext"
import { convertStringToTitleCase } from "../../../utils/helpers"
import { extraColors } from "../../../utils/theme"
import { alertColors, getAlertColor } from "../../Shared/AlertPill"
import { useMarkerHighlightHover } from "./hooks/useAlertHighlightSelected"
import { useCapServiceContext } from "../../CriticalThreats/capServiceContext"
import { memo, useMemo } from "react"

const AlertBody = styled("div")(() => ({
  padding: "4px 12px",
  borderRadius: 3,
  textAlign: "center",
  position: "relative",
  boxShadow: `0px 9px 12px 0px ${extraColors.shadow}`,
}))

const Arrow = styled("div")(() => ({
  position: "absolute",
  left: -16,
  top: 0,
  width: 0,
  height: "100%",
  borderRight: "18px solid green",
  borderTop: "1em solid transparent",
  borderBottom: "1em solid transparent",
  borderRadius: 3,
}))

const AlertCount = styled(Typography)(() => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  // white circle background
  "&::before": {
    content: '""',
    width: 32,
    height: 32,
    borderRadius: "50%",
    background: "#fff",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: -1,
    boxShadow: `0px 9px 12px 0px ${extraColors.shadow}`,
  },
}))

interface AlertPieProps {
  warning?: number
  watch?: number
  advisory?: number
}

const AlertPie: React.FC<AlertPieProps> = ({
  warning = 0,
  watch = 0,
  advisory = 0,
}) => {
  return (
    <PieChart
      series={[
        {
          data: [
            {
              value: warning,
              color: alertColors.warning.background,
              label: "warning",
            },
            {
              value: watch,
              color: alertColors.watch.background,
              label: "watch",
            },
            {
              value: advisory,
              color: alertColors.advisory.background,
              label: "advisory",
            },
          ],
          innerRadius: 12,
          outerRadius: 22,
          // TODO: add a label on hover
          // arcLabel: "label",
        },
      ]}
      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
      width={60}
      height={60}
      sx={{
        [`& .${pieArcClasses.root}`]: {
          stroke: "none",
        },
      }}
      slotProps={{
        legend: {
          hidden: true,
        },
      }}
    />
  )
}

const MemoPie = memo(AlertPie)

export interface AlertMarkerProps {
  alertId: string
  lng: number
  lat: number
}

export const AlertMarker: React.FC<AlertMarkerProps> = ({
  alertId,
  lat,
  lng,
}) => {
  const { setClusterAlertsIds } = useMapContext()
  const { useAlerts } = useCapServiceContext()
  const { data: alerts } = useAlerts()
  const { setHoverFeatureState } = useMarkerHighlightHover(alertId)

  const alert = useMemo(
    () => alerts?.find(a => a._id === alertId),
    [alertId, alerts]
  )

  const colors = getAlertColor(alert?.alertLevel)

  if (!alert) return null

  return (
    <Marker
      longitude={lng}
      latitude={lat}
      anchor="left"
      offset={[16, 0]}
      style={{ cursor: "pointer" }}
      onClick={e => {
        e.originalEvent.stopPropagation()
        setClusterAlertsIds([alertId])
      }}
    >
      <AlertBody
        style={{ background: colors?.background }}
        onPointerEnter={() => {
          setHoverFeatureState(true)
        }}
        onPointerLeave={() => {
          setHoverFeatureState(false)
        }}
      >
        <Arrow style={{ borderRightColor: colors?.text }} />
        <Typography variant="body2Bold" color={colors?.text}>
          {convertStringToTitleCase(alert.alertLevel)}
        </Typography>
      </AlertBody>
    </Marker>
  )
}

export interface AlertClusterMarkerProps {
  cluster_id: number
  lng: number
  lat: number
  alertIds: string[]
}

export const AlertClusterMarker: React.FC<AlertClusterMarkerProps> = ({
  lng,
  lat,
  alertIds,
}) => {
  const { setClusterAlertsIds } = useMapContext()
  const { useAlerts } = useCapServiceContext()
  const { data: alerts } = useAlerts()

  const { setHoverFeatureState } = useMarkerHighlightHover(alertIds)
  const clusterAlerts = useMemo(() => {
    if (!alerts) return []
    return alertIds.map(a => alerts.find(alert => alert._id === a))
  }, [alertIds, alerts])

  const alertLevelMap = clusterAlerts.reduce<
    Partial<Record<AlertLevel, number>>
  >((acc, alert) => {
    const alertLevel = alert?.alertLevel
    if (isAlertLevel(alertLevel)) {
      acc[alertLevel] = (acc[alertLevel] || 0) + 1
    }
    return acc
  }, {})

  if (!alerts) return

  return (
    <Marker
      longitude={lng}
      latitude={lat}
      anchor="center"
      style={{ cursor: "pointer" }}
      onClick={e => {
        e.originalEvent.stopPropagation()
        setClusterAlertsIds(alertIds)
      }}
    >
      <div
        onPointerMove={() => {
          setHoverFeatureState(true)
        }}
        onPointerLeave={() => {
          setHoverFeatureState(false)
        }}
      >
        <MemoPie {...alertLevelMap} />
        <AlertCount variant="body2Bold">{alertIds.length}</AlertCount>
      </div>
    </Marker>
  )
}
