import { Typography } from "@mui/material"
import { dayjsUTC } from "../../utils/helpers"
import { extraColors } from "../../utils/theme"
import { isPrd } from "../../components/CriticalThreats/capServiceContext"

/**
 * INTERFACES
 */

interface PostDates {
  postPublishedAt?: string
  postUpdatedAt?: string
  postUpdatedAtAlternate?: string
}

/**
 * COMPONENTS
 */

export const PostDates: React.FC<PostDates> = ({
  postPublishedAt,
  postUpdatedAt,
  postUpdatedAtAlternate,
}) => {
  const publishedAt = getPublishedAtDate({
    postPublishedAt,
    postUpdatedAt,
    postUpdatedAtAlternate,
  })

  const updatedAt = isPrd
    ? dayjsUTC(postUpdatedAtAlternate).local()
    : dayjsUTC(postUpdatedAt).local()

  const showUpdatedAt = updatedAt.diff(publishedAt, "minute") > 0

  return (
    <>
      <Typography variant={"body2Bold"} sx={{ color: extraColors.disabled }}>
        {publishedAt.format("MMM D, YYYY")} -{" "}
        {dayjsUTC().local().to(publishedAt)}
      </Typography>
      {showUpdatedAt && (
        <Typography variant={"body2Bold"}>
          (Updated {dayjsUTC().local().to(updatedAt)})
        </Typography>
      )}
    </>
  )
}

// Since scheduled publishes don't set the `publishedAt` field,
// fallback to `_updatedAt` when `publishedAt` is not set
export const getPublishedAtDate = (dates: PostDates) => {
  const { postPublishedAt, postUpdatedAt } = dates
  return isPrd
    ? dayjsUTC(postPublishedAt ?? postUpdatedAt).local()
    : dayjsUTC(postUpdatedAt).local()
}
