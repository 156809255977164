import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined"
import { Box, Typography, styled } from "@mui/material"
import type { AlertLevel } from "@phc/common"
import { Link, generatePath } from "react-router-dom"
import { ROUTES } from "../../../utils/constants"
import { dayjsUTC } from "../../../utils/helpers"
import { extraColors } from "../../../utils/theme"
import { AlertPill, alertColors } from "../../Shared/AlertPill"
import { CardContainer, LeftBorder } from "../LocationCard/Shared"

const ContentContainer = styled("div")({
  display: "flex",
  gap: 12,
  flexDirection: "column",
  width: "100%",
  padding: "12px",
  height: 240,
})

const TitleRow = styled("div")({
  display: "flex",
  alignItems: "start",
  justifyContent: "space-between",
  gap: 7,
})

interface AnalysisCardProps {
  title: string
  content: string
  date: string
  alertLevel?: AlertLevel
  slug: string | undefined
  className?: string
}

export const AnalysisCard = ({
  alertLevel,
  title,
  content,
  date,
  slug,
  className,
}: AnalysisCardProps) => {
  const borderColor =
    (alertLevel && alertColors[alertLevel].background) || extraColors.light
  return (
    <CardContainer className={className}>
      <LeftBorder color={borderColor} />
      <ContentContainer>
        <TitleRow>
          <Box display="flex" alignItems="center" gap="7px">
            <ArticleOutlinedIcon
              sx={{
                color: extraColors.status.teal,
                fontSize: "18px",
              }}
            />
            <Typography
              variant="body1Bold"
              color={extraColors.status.teal}
              component={Link}
              to={generatePath(ROUTES.ANALYSIS_POST, {
                slug: slug ?? "",
              })}
              sx={{ textDecoration: "none" }}
            >
              {title}
            </Typography>
          </Box>
          <Typography variant="small1">
            {dayjsUTC(date).format("MM/DD/YY")}
          </Typography>
        </TitleRow>
        {alertLevel && (
          <Box display="flex">
            <AlertPill alertLevel={alertLevel} />
          </Box>
        )}
        <Typography
          border={`1px solid ${extraColors.light}`}
          borderRadius="4px"
          padding={1.5}
          variant="small1"
          flex={1}
          sx={{
            overflow: "hidden",
            position: "relative",
            "&:after": {
              content: '""',
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              background:
                "linear-gradient(transparent 0%, transparent 78%, white 98%)",
            },
          }}
        >
          {content}
        </Typography>
      </ContentContainer>
    </CardContainer>
  )
}
