import { Typography, styled } from "@mui/material"
import { Disease_Type } from "@phc-health/connect-query"
import { useMemo } from "react"
import { diseaseTypeMapping } from "../../../utils/helpers/threatTrendHelper"
import { RiskPill } from "../../RiskPill"
import { ColumnContainer } from "./Shared"
import { useLayersByLocationId } from "../../../hooks/useLayersApi"

const Row = styled("div")({
  display: "flex",
  justifyContent: "space-between",
})

interface ContributingFactorsProps {
  locationId: string
}

export const ContributingFactors = ({
  locationId,
}: ContributingFactorsProps) => {
  const { data } = useLayersByLocationId(locationId)

  const sortedBuckets = useMemo(() => {
    const details =
      data?.buckets.sort((bucket1, bucket2) => {
        if (bucket1.disease === Disease_Type.RESP) return 1
        return Disease_Type[bucket1.disease].localeCompare(
          Disease_Type[bucket2.disease]
        )
      }) ?? []

    return details
  }, [data?.buckets])

  return (
    <ColumnContainer>
      {sortedBuckets.map(bucket => {
        return (
          <Row key={bucket.disease}>
            <Typography variant="small1">
              {diseaseTypeMapping(bucket.disease)}
            </Typography>
            <RiskPill severity={bucket.severity} />
          </Row>
        )
      })}
    </ColumnContainer>
  )
}
