import type React from "react"
import { Tag } from "./CategoryTag"
import { Typography } from "@mui/material"
import { Link } from "react-router-dom"
import { ROUTES, stringifySearchParams } from "../../../utils/constants"
import { extraColors } from "../../../utils/theme"

export const LocationTag: React.FC<{
  id: string
  title: string
  subtitle: string
}> = ({ id, title, subtitle }) => {
  return (
    <Tag>
      <Typography
        component={Link}
        to={{
          pathname: ROUTES.LOCATION_DETAILS,
          search: stringifySearchParams({
            id,
            title,
            subtitle,
          }),
        }}
        variant="tiny"
        color={extraColors.dark}
        sx={{ textDecoration: "none" }}
      >
        {title}
      </Typography>
    </Tag>
  )
}
