import type { PHC_ENV } from "@phc/common"
import {
  getAccessDomain,
  getCapDomain,
  getLayersDomain,
  getNotificationsDomain,
  getPharosDomain,
  getAssetServiceDomain,
} from "@phc/common"
import { ENV } from "./env"

// TODO: refactor ROUTES config to handle child routes
// magic string routes to address --> tools / event-planning / dynamic-event-tool
export const ROUTES = {
  BASE: "/",
  HOME: "/home",
  SIGN_IN: "/sign-in",
  /** Route for invite validation */
  LOGIN: "/login",
  ERROR: "/error",
  PRIVACY_POLICY: "/privacy-policy",
  TERMS_AND_CONDITIONS: "/terms-and-conditions",
  MAP: "/map",
  GUIDANCE: "/guidance",
  GUIDANCE_POST: "/guidance/:slug",
  ANALYSIS_POST: "/analyses/:slug",
  ANALYSES: "/analyses",
  ALERTS: "/alerts",
  CRITICAL_THREAT: "/critical-threat/:slug",
  CRITICAL_THREATS: "/critical-threats",
  NOTIFICATIONS: "/notifications",
  PREFERENCES: "/preferences",
  DOCUMENTATION: "/documentation",
  ASSETS: "/assets",
  ASSET: "/assets/:assetId",
  LOCATION_DETAILS: "/location-details",
  WORKFORCE_PLANNING: "/workforce-planning",
  ORGANIZATIONS: "/organizations",
  USERS: "/users",
  MANAGE_ORGANIZATION: "/manage-organization",
  SOURCES: "/sources",
  SCENARIO_TOOLS: "/scenario-tools",
  TOOLS: "/tools",
  ADMIN_CRUD_TOOL: "/admin-crud-tool",
  FAQ: "/faq",
  FAQ_POST: "/faq/:slug",
  NOT_FOUND: "/not-found",
} as const

export const stringifySearchParams = (params: object) => {
  return Object.entries(params).reduce((acc, [key, value]) => {
    if (!value) return acc
    const encodedKey = encodeURIComponent(key)
    const encodedValue = encodeURIComponent(String(value))
    return `${acc}${acc ? "&" : "?"}${encodedKey}=${encodedValue}`
  }, "")
}

export const ENVIRONMENTS: Record<PHC_ENV, string> = {
  int: "integration",
  sbx: "sandbox",
  demo: "demo",
  prd: "production",
  stg: "staging",
}

export const PHAROS_API = getPharosDomain(ENV)
export const ASSET_SERVICE_API = getAssetServiceDomain(ENV)
export const NOTIFICATIONS_API = getNotificationsDomain(ENV)
export const ACCESS_API = getAccessDomain(ENV)
export const LAYERS_API = getLayersDomain(ENV)
export const CAP_URL = import.meta.env.VITE_CAP_URL_DEV ?? getCapDomain(ENV)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const apiKeys = [
  PHAROS_API,
  ACCESS_API,
  ASSET_SERVICE_API,
  NOTIFICATIONS_API,
  LAYERS_API,
]
export type ApiKey = (typeof apiKeys)[number]

export const organizationIdClaim =
  "https://thepublichealthco.com/v1/organization"

export const queries = {
  mapboxId: "mapboxId",
  timezoneLocation: "timezoneLocation",
  organizationId: "organizationId",
  permissions: "permissions",
} as const

export const userGuideLink =
  "https://drive.google.com/file/d/1j8fsRbzO4W7jiIzkKmEp4pSD28esP-7U/view?usp=sharing"

/**
 * Set by data engineering
 * use instead of riskThresholds
 */
// Remove when event tool is removed
export const RiskNames = {
  low: "Low",
  medium: "Moderate",
  high: "High",
  severe: "Severe",
} as const

// Remove when event tool is removed
export const TrendNames = {
  noArrow: "Being Analyzed",
  rightArrow: "And Persistent",
  downRightArrow: "But Decreasing",
  upRightArrow: "And Increasing",
  upArrow: "And Accelerating",
} as const

export enum Permission {
  READ_USER = "read:user",
  WRITE_USER = "write:user",
  READ_ORGANIZATION = "read:organization",
  WRITE_ORGANIZATION = "write:organization",
  READ_ORGANIZATION_CURRENT = "read:organization.current",
  WRITE_ORGANIZATION_CURRENT = "write:organization.current",
  READ_PREFERENCES_USER_CURRENT = "read:preferences.user.current",
  WRITE_PREFERENCES_USER_CURRENT = "write:preferences.user.current",
  READ_PREFERENCES_USER = "read:preferences.user",
  WRITE_PREFERENCES_USER = "write:preferences.user",
  READ_PREFERENCES_USER_ALL = "read:preferences.user.all",
  WRITE_PREFERENCES_USER_ALL = "write:preferences.user.all",
  READ_PREFERENCES_ORGANIZATION = "read:preferences.organization",
  WRITE_PREFERENCES_ORGANIZATION = "write:preferences.organization",
  READ_PREFERENCES_ORGANIZATION_ALL = "read:preferences.organization.all",
  WRITE_PREFERENCES_ORGANIZATION_ALL = "write:preferences.organization.all",
  READ_NOTIFICATIONS = "read:notifications",
  WRITE_NOTIFICATIONS = "write:notifications",
}

export const userPermissions = [
  Permission.READ_PREFERENCES_USER_CURRENT,
  Permission.WRITE_PREFERENCES_USER_CURRENT,
  Permission.READ_NOTIFICATIONS,
  Permission.WRITE_NOTIFICATIONS,
]

export const adminPermissions = [
  Permission.READ_USER,
  Permission.WRITE_USER,
  Permission.READ_PREFERENCES_USER_CURRENT,
  Permission.WRITE_PREFERENCES_USER_CURRENT,
  Permission.READ_PREFERENCES_USER,
  Permission.READ_PREFERENCES_ORGANIZATION,
  Permission.WRITE_PREFERENCES_ORGANIZATION,
  Permission.READ_ORGANIZATION_CURRENT,
  Permission.READ_NOTIFICATIONS,
]

export const phcUserPermissions = [
  Permission.READ_USER,
  Permission.READ_ORGANIZATION,
  Permission.READ_PREFERENCES_USER_CURRENT,
  Permission.WRITE_PREFERENCES_USER_CURRENT,
  Permission.READ_PREFERENCES_ORGANIZATION,
  Permission.READ_NOTIFICATIONS,
  Permission.WRITE_NOTIFICATIONS,
]

export const phcAdminPermissions = [
  Permission.READ_USER,
  Permission.WRITE_USER,
  Permission.READ_ORGANIZATION,
  Permission.WRITE_ORGANIZATION,
  Permission.READ_PREFERENCES_USER_CURRENT,
  Permission.WRITE_PREFERENCES_USER_CURRENT,
  Permission.READ_PREFERENCES_USER,
  Permission.WRITE_PREFERENCES_USER,
  Permission.READ_PREFERENCES_USER_ALL,
  Permission.READ_PREFERENCES_ORGANIZATION,
  Permission.WRITE_PREFERENCES_ORGANIZATION,
  Permission.READ_PREFERENCES_ORGANIZATION_ALL,
  Permission.WRITE_PREFERENCES_ORGANIZATION_ALL,
  Permission.READ_NOTIFICATIONS,
  Permission.WRITE_NOTIFICATIONS,
]

export const TABLE_ROWS = 15

export enum AdminContentTags {
  SOURCES = "sources",
}

export enum SeverityLevel {
  Fatal = "fatal",
  Error = "error",
  Warning = "warning",
  Log = "log",
  Info = "info",
  Debug = "debug",
}

export const LEGAL_STATEMENT =
  "PHC Global provides this material for informational purposes only. It does not constitute, and is not a substitute for, medical services or advice and should not be construed as such."

export const LOCATION_IDS_WITH_STALE_DATA = [
  "586",
  "528",
  "704",
  "233",
  "496",
  "320",
  "372",
  "826",
  "332",
  "404",
  "800",
  "724",
]

export const TRUE = "true"
export const FALSE = "false"
