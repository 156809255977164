import { styled, Typography } from "@mui/material"
import { Risk_Severity } from "@phc-health/connect-query"
import { severityMapping } from "../../../utils/helpers/threatTrendHelper"
import { extraColors } from "../../../utils/theme"
import type { IconSize } from "../../icons/IconSize"

const SeverityBlock = styled("div")({
  display: "flex",
  justifyContent: "center",
  gap: 8,
  alignItems: "center",
  borderRadius: 20,
  opacity: 0.2,
  textAlign: "center",
  width: 140,
  height: 38,
  boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.12) inset",
})

interface SeverityVisualIndicatorProps {
  severity: Risk_Severity
  showTrend: boolean
  activeSeverity?: Risk_Severity
  size?: IconSize
  className?: string
}

interface SeverityStyleProps {
  background: string
  border: string
  text: string
}

export const SeverityVisualIndicator: React.FC<
  SeverityVisualIndicatorProps
> = ({ severity, activeSeverity, className }) => {
  const { background, text } = getIndicatorStyles(severity)
  return (
    <SeverityBlock
      className={className}
      sx={{
        background,
        opacity: activeSeverity && activeSeverity !== severity ? 0.2 : 1,
      }}
    >
      <Typography
        variant="body2Bold"
        sx={{ color: text, textTransform: "uppercase" }}
      >
        {severityMapping(severity)}
      </Typography>
    </SeverityBlock>
  )
}

export function getIndicatorStyles(
  severity?: Risk_Severity
): SeverityStyleProps {
  switch (severity) {
    case Risk_Severity.LOW:
      return {
        background: extraColors.status.greenLight,
        border: extraColors.status.green,
        text: extraColors.status.greenDark,
      }
    case Risk_Severity.MODERATE:
      return {
        background: extraColors.status.yellowLight,
        border: extraColors.status.yellowMiddle,
        text: extraColors.status.yellowDark,
      }
    case Risk_Severity.HIGH:
      return {
        background: extraColors.status.orangeLight,
        border: extraColors.status.orangeMiddle,
        text: extraColors.status.orangeDark,
      }
    case Risk_Severity.SEVERE:
      return {
        background: extraColors.status.redLight,
        border: extraColors.status.redMiddle,
        text: extraColors.status.redDark,
      }
    default:
      return {
        background: "transparent",
        border: "transparent",
        text: "transparent",
      }
  }
}
