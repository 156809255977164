import { Typography } from "@mui/material"
import { useState } from "react"
import { extraColors } from "../../../../utils/theme"
import { SettingsContainer } from "../../shared/SettingsContainer"
import { useNotificationTypeContext } from "../contexts/NotificationTypeContext"
import { Alerts } from "./Alerts/Alerts"
import { EmailExampleDialog } from "../EmailExampleDialog"
import { SettingCheckbox } from "../SettingCheckbox"
import { GlobalSettingsRadioGroup } from "./GlobalSettingsRadioGroup"
import { useNotificationContext } from "../contexts/NotificationContext"

export const ExpertInsights = () => {
  const { notificationType } = useNotificationTypeContext()
  const { analysisEnabled, setAnalysisEnabled, alertsEnabled } =
    useNotificationContext(notificationType)
  const [dialogIsOpen, setDialogIsOpen] = useState(false)

  return (
    <SettingsContainer title="Expert Insights">
      <Typography variant="body1">
        Receive emails for the following content when there is information on
        any potential threats affecting your locations.{" "}
        <Typography
          variant="body1Bold"
          onClick={() => setDialogIsOpen(true)}
          sx={{ cursor: "pointer", color: extraColors.purpleMiddle }}
        >
          View examples
        </Typography>
      </Typography>
      <EmailExampleDialog
        dialogIsOpen={dialogIsOpen}
        setDialogIsOpen={setDialogIsOpen}
        type="expert insights"
      />
      <Alerts />
      <SettingCheckbox
        settingEnabled={analysisEnabled || false}
        setSettingEnabled={setAnalysisEnabled}
        title="Analysis"
      />
      {(analysisEnabled || alertsEnabled) && (
        <GlobalSettingsRadioGroup title={"Expert Insights"} />
      )}
    </SettingsContainer>
  )
}
