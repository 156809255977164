import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material"

import type React from "react"
import { SelectContainer } from "./Shared"
import { MIXED_TEXT } from "./EditAssetsDialog"

interface ThreatRadiusSelectProps {
  threatRadius?: number
  setThreatRadius: (radius: number) => void
  isDisabled: boolean
  showMixed?: boolean
}

export const ThreatRadiusSelect: React.FC<ThreatRadiusSelectProps> = ({
  threatRadius,
  setThreatRadius,
  isDisabled,
  showMixed,
}) => {
  if (isDisabled) return null

  return (
    <SelectContainer>
      <FormControl fullWidth>
        <InputLabel id="threat-radius">Threat Radius</InputLabel>
        <Select
          value={
            showMixed && threatRadius === undefined
              ? MIXED_TEXT
              : threatRadius?.toString() || ""
          }
          label="Threat Radius"
          onChange={event => {
            setThreatRadius(parseInt(event.target.value) || 0)
          }}
          style={{ padding: 0 }}
        >
          {[MIXED_TEXT, 0, 10, 25, 50, 100].map(radius => {
            if (radius === MIXED_TEXT) {
              return showMixed ? (
                <MenuItem disabled key={MIXED_TEXT} value={MIXED_TEXT}>
                  <Typography sx={{ marginLeft: "4px" }}>
                    {MIXED_TEXT}
                  </Typography>
                </MenuItem>
              ) : null
            }
            return (
              <MenuItem key={radius} value={radius}>
                {radius === 0 ? (
                  <em>None</em>
                ) : (
                  `${radius.toLocaleString()} miles`
                )}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </SelectContainer>
  )
}
