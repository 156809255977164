import LinkIcon from "@mui/icons-material/Link"
import WarningAmberIcon from "@mui/icons-material/WarningAmber"
import { Box, Button, Typography, styled, useTheme } from "@mui/material"
import type { AlertLevel, Category } from "@phc/common"
import { useLayoutEffect, useMemo, useRef, useState } from "react"
import { Link } from "react-router-dom"
import { ROUTES, stringifySearchParams } from "../../../utils/constants"
import { extraColors } from "../../../utils/theme"
import { alertColors } from "../../Shared/AlertPill"
import { CardContainer, LeftBorder } from "../LocationCard/Shared"
import { CriticalThreatTag } from "../../CriticalThreats/CriticalThreatTag"
import { useCapServiceContext } from "../../CriticalThreats/capServiceContext"
import { getCriticalThreatByContentId } from "../../CriticalThreats/criticalThreatHelper"
import { trackEvent } from "../../../utils/mixpanel"
import { dayjsUTC } from "../../../utils/helpers"
import { ConfidenceIndicator } from "../../Shared/ConfidenceIndicator"
import {
  getConfidenceFromJustification,
  type JustificationConfidence,
} from "../../Shared/AlertConfidence"
import { LocationTag } from "../../LocationDetails/LocationRisk/LocationTag"

const ContentContainer = styled("div")({
  display: "flex",
  gap: 12,
  flexDirection: "column",
  width: "100%",
  padding: "12px",
})

const Row = styled("div")({
  display: "grid",
  alignItems: "center",
  justifyContent: "space-between",
  gap: 15,
  gridTemplateColumns: "auto auto",
})

const LinkIconStyled = styled(LinkIcon)({
  fill: extraColors.disabled,
  height: "70%",
  marginRight: -10,
  cursor: "pointer",
  verticalAlign: "middle",
  "&:hover": {
    fill: extraColors.purple,
    transform: "scale(1.1)",
  },
})

const BorderText = styled(Typography)({
  position: "absolute",
  transform: "translate(-3%, 20%)",
  transformOrigin: "left top",
  writingMode: "vertical-rl",
  whiteSpace: "nowrap",
})

interface AlertCardProps {
  location: string
  locationSubTitle: string
  title: string
  content: string | undefined
  recommendation: string | undefined
  alertLevel: AlertLevel | undefined
  date: string
  locationId: string | undefined
  link?: string
  className?: string
  id: string
  threat: Category["title"]
}

const LINES_TO_SHOW = 3

export const AlertCard = ({
  alertLevel,
  location,
  locationSubTitle,
  locationId,
  title,
  content,
  recommendation,
  date,
  className,
  link,
  id,
  threat,
}: AlertCardProps) => {
  const { useCriticalThreats } = useCapServiceContext()
  const { data } = useCriticalThreats()
  const [showMore, setShowMore] = useState(false)
  const contentRef = useRef<HTMLDivElement>(null)
  const theme = useTheme()

  // measure content height to determine if we need to show the "see more" button
  const [numLines, setNumLines] = useState(0)

  const formattedDate = dayjsUTC(date).local().format("MM/D/YY")

  const relatedCriticalThreat = useMemo(() => {
    return getCriticalThreatByContentId(id, data)
  }, [data, id])

  const justificationParsed: JustificationConfidence = useMemo(
    () => getConfidenceFromJustification(content),
    [content]
  )

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (!contentRef.current) return
      const contentHeight = contentRef.current.scrollHeight
      const lineHeight = parseInt(
        window.getComputedStyle(contentRef.current).lineHeight
      )
      const calculatedNumLines = Math.floor(contentHeight / lineHeight)
      setNumLines(calculatedNumLines)
    })

    if (contentRef.current) {
      resizeObserver.observe(contentRef.current)
    }

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  const isOverflowing = numLines > LINES_TO_SHOW

  const alertColor = alertLevel && {
    background: alertColors[alertLevel].background,
    text: alertColors[alertLevel].text,
  }

  const handleShowMore = () => {
    setShowMore(!showMore)
    trackEvent("CLICK_ALERT_SEE_MORE", {
      title,
      link: link ?? "",
    })
  }

  return (
    <CardContainer className={className}>
      <LeftBorder color={alertColor?.background || extraColors.light} />
      <BorderText>
        <WarningAmberIcon
          sx={{
            color: alertColor?.text || extraColors.navy,
            fontSize: "18px",
            marginBottom: "8px",
          }}
        />
        <Typography
          variant="small1"
          color={alertColor?.text || extraColors.navy}
          textTransform="uppercase"
          fontWeight={600}
        >
          {alertLevel?.toLowerCase()}
        </Typography>
      </BorderText>
      <ContentContainer>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap="7px"
        >
          <Box sx={{ display: "flex", gap: "7px", alignItems: "center" }}>
            <Typography
              component={Link}
              to={{
                pathname: ROUTES.LOCATION_DETAILS,
                search: stringifySearchParams({
                  title: location,
                  subtitle: locationSubTitle,
                  id: locationId,
                }),
              }}
              variant="body1Bold"
              color={extraColors.purple}
              sx={{ textDecoration: "none", textTransform: "uppercase" }}
            >
              {threat}
            </Typography>
            <CriticalThreatTag relatedCriticalThreat={relatedCriticalThreat} />
          </Box>

          <Typography variant="small1" color={extraColors.hint}>
            {formattedDate}
          </Typography>
        </Box>
        <Row>
          <LocationTag
            title={location}
            subtitle={locationSubTitle}
            id={locationId || ""}
          />
        </Row>
        <Row>
          <Typography variant="body2Bold">{title}</Typography>
        </Row>
        <Box
          style={{
            alignSelf: "end",
            position: "relative",
          }}
        >
          <Typography
            variant="small1"
            ref={contentRef}
            sx={{
              position: "relative",
              overflow: "hidden",
              display: showMore ? "block" : "-webkit-box",
              WebkitLineClamp: LINES_TO_SHOW,
              WebkitBoxOrient: "vertical",
              lineHeight: "18px",
              maxHeight: showMore ? "none" : "calc(18px * 3 + 10px)",
            }}
          >
            <Typography variant="inherit" fontWeight={300}>
              <Typography variant="small1Bold">RECOMMENDATION</Typography> -{" "}
              {recommendation}
            </Typography>
            <Typography variant="inherit" fontWeight={300} paddingTop="8px">
              <Typography variant="small1Bold">SITUATION</Typography> -{" "}
              {content}
            </Typography>
          </Typography>
          {isOverflowing && (
            <Button
              variant="text"
              onClick={handleShowMore}
              style={{
                position: showMore ? "relative" : "absolute",
                bottom: showMore ? 2 : 0,
                right: 0,
                background: "linear-gradient(90deg, transparent 0%, white 15%)",
                display: "inline",
                ...theme.typography.small1,
                color: extraColors.purple,
                height: 18,
                margin: 0,
                padding: 0,
                paddingLeft: showMore ? 0 : 20,
              }}
            >
              {showMore ? "see less" : "... see more"}
            </Button>
          )}
        </Box>
        {link && (
          <Link
            to={link}
            target="_blank"
            onClick={() => {
              trackEvent("CLICK_ALERT_SOURCE", {
                title,
                link,
              })
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "4px",
                justifyContent: "space-between",
                alignItems: "self-end",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Typography
                  variant="tiny"
                  color={extraColors.hint}
                  alignSelf="center"
                >
                  SOURCE
                </Typography>
                <LinkIconStyled fontSize="inherit" />
              </Box>
              <ConfidenceIndicator
                confidenceInteger={justificationParsed.parsedConfidence}
              />
            </Box>
          </Link>
        )}
      </ContentContainer>
    </CardContainer>
  )
}
