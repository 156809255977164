import { styled, Typography } from "@mui/material"
import type { Bucket } from "@phc-health/connect-query"
import { extraColors } from "../../../../utils/theme"
import { SectionContainer } from "../Shared"
import { DiseaseFactor } from "./Disease/DiseaseFactor"
import analysis from "../../../../assets/pngs/related-analysis.png"

const HeaderText = styled(Typography)({
  color: extraColors.medium,
  fontWeight: 600,
})

const ContributingFactorsContainer = styled("div")(({ theme }) => ({
  marginTop: 36,
  [theme.breakpoints.down("sm")]: {
    marginTop: 0,
  },
}))

const SectionContainerStyled = styled(SectionContainer)({
  paddingTop: 0,
})

const AnalysisImg = styled("img")({
  maxHeight: 170,
})

const InfoContainer = styled("div")({
  display: "grid",
  placeContent: "center",
  textAlign: "center",
  gap: 32,
  maxHeight: 276,
  paddingTop: 24,
})

interface ContributingFactorsProps {
  buckets: Bucket[]
  title: string
  className?: string
  defaultCollapsed: boolean
}

export const ContributingFactors: React.FC<ContributingFactorsProps> = ({
  buckets,
  title,
  className,
  defaultCollapsed,
}) => {
  if (!buckets.length) return <NoThreats />

  const dedupedBucketData = buckets.filter(
    (bucket, index) =>
      index === buckets.findIndex(b => b.disease === bucket.disease)
  )
  return (
    <ContributingFactorsContainer className={className}>
      <HeaderText variant="h3Bold">{title}</HeaderText>
      <SectionContainerStyled>
        {dedupedBucketData.map(b => (
          <DiseaseFactor
            key={b.disease}
            bucket={b}
            defaultCollapsed={defaultCollapsed}
          />
        ))}
      </SectionContainerStyled>
    </ContributingFactorsContainer>
  )
}

const NoThreats: React.FC = () => {
  return (
    <SectionContainerStyled>
      <InfoContainer>
        <AnalysisImg src={analysis} />
        <Typography variant="body1Bold" style={{ color: extraColors.disabled }}>
          No Data is Available
        </Typography>
      </InfoContainer>
    </SectionContainerStyled>
  )
}
