import { Autocomplete, TextField } from "@mui/material"
import { IndustryType } from "@phc-health/connect-query"

import type React from "react"
import {
  industryTypeEnumToString,
  industryStringList,
  getIndustryStringToEnumMap,
} from "../../../utils/helpers/assetHelper"
import { MIXED_TEXT } from "./EditAssetsDialog"
import { extraColors } from "../../../utils/theme"

interface IndustryTypeAutocompleteProps {
  selectedIndustry: IndustryType
  setSelectedIndustry?: (type: IndustryType) => void
  isDisabled?: boolean
  showMixed?: boolean
  /**
   * Either show industry type Baseline or Unspecified, but not both
   */
  showBaseline?: boolean
}

export const IndustryAutocomplete: React.FC<IndustryTypeAutocompleteProps> = ({
  selectedIndustry,
  setSelectedIndustry,
  isDisabled,
  showMixed,
  showBaseline,
}) => {
  if (isDisabled) return null

  const industryTypeList = getIndustryStringToEnumMap()
  if (showBaseline) {
    industryTypeList.delete("Unknown")
  } else {
    industryTypeList.delete("Baseline")
  }
  const filteredIndustryStringList = industryStringList.filter(
    industryString =>
      showBaseline
        ? industryString !== "Unknown"
        : industryString !== "Baseline"
  )
  const fallbackIndustry = showBaseline
    ? IndustryType.BASELINE
    : IndustryType.UNSPECIFIED

  return (
    <Autocomplete
      id="tags-outlined"
      disableClearable
      openOnFocus
      autoHighlight
      selectOnFocus
      sx={{ ".MuiSvgIcon-root": { color: extraColors.purpleMiddle } }}
      options={filteredIndustryStringList.concat([MIXED_TEXT])}
      value={
        showMixed && !selectedIndustry
          ? MIXED_TEXT
          : industryTypeEnumToString(selectedIndustry)
      }
      renderInput={params => (
        <TextField {...params} variant="outlined" label="Industry" />
      )}
      onChange={(_, newValue) => {
        const industryString = industryTypeList.get(
          newValue || industryTypeEnumToString(fallbackIndustry)
        )

        setSelectedIndustry?.(industryString || fallbackIndustry)
      }}
      filterOptions={(options, state) => {
        const filtered = options.filter(
          option =>
            option.toLowerCase().includes(state.inputValue.toLowerCase()) &&
            option !== MIXED_TEXT
        )
        if (filtered.length === 0 && state.inputValue) {
          return [industryTypeEnumToString(fallbackIndustry)]
        }
        return filtered
      }}
    />
  )
}
