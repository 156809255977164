import {
  AssetNotificationAlertLevel,
  AssetNotificationSetting,
  AssetNotificationSettingStatus,
  AssetNotificationType,
  AssetRegion,
  Disease_Type,
  Asset,
  AssetType,
  AssetNotificationSettings,
  BaseEvent,
} from "@phc-health/connect-query"
import * as Sentry from "@sentry/react"

import type React from "react"
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react"
import { enumKeys, getEnumKey } from "../../../../utils/helpers"
import { getNonGlobalAssets } from "../../../../utils/helpers/assetHelper"
import { useCreateAsset } from "../../../WatchedLocations/AssetManagement/hooks/useCreateAsset"
import { SeverityLevel } from "../../../../utils/constants"
import { isEqual } from "lodash-es"
import { trackEvent } from "../../../../utils/mixpanel"
import { useUpdateNotificationSettings } from "../hooks/useUpdateNotificationSettings"
import { useListAssets } from "../../../WatchedLocations/hooks/useAssetService"

export const FORECAST_RANGE_DEFAULT = 3

export const EXPERT_INSIGHT_TYPES: AssetNotificationType[] = [
  AssetNotificationType.ALERT,
  AssetNotificationType.ANALYSIS,
]

export const ABSENTEEISM_INSIGHT_TYPES: AssetNotificationType[] = [
  AssetNotificationType.FORECASTED_ABSENTEEISM_INCREASE,
  AssetNotificationType.HISTORICAL_AVG_ABSENTEEISM_INCREASE,
]

export enum SettingGranularity {
  GLOBAL = "global",
  ASSET = "asset",
}

const NotificationContext = createContext<{
  assets?: Asset[]
  assetsLoading: boolean
  nonGlobalAssets?: Asset[]
  submitNotificationSettings: () => Promise<void>
  discardNotificationSettings: () => void
  setRiskIncreaseDialogSelectedAssets: React.Dispatch<
    React.SetStateAction<Asset[] | undefined>
  >
  riskIncreaseDialogSelectedAssets?: Asset[]
  setExpertInsightsDialogSelectedAssets: React.Dispatch<
    React.SetStateAction<Asset[] | undefined>
  >
  expertInsightsDialogSelectedAssets?: Asset[]
  setAbsenteeismDialogSelectedAssets: React.Dispatch<
    React.SetStateAction<Asset[] | undefined>
  >
  absenteeismDialogSelectedAssets?: Asset[]
  setSelectedRiskIncreaseAssets: React.Dispatch<
    React.SetStateAction<Asset[] | undefined>
  >
  selectedRiskIncreaseAssets?: Asset[]
  setSelectedExpertInsightsAssets: React.Dispatch<
    React.SetStateAction<Asset[] | undefined>
  >
  selectedExpertInsightsAssets?: Asset[]
  setSelectedAbsenteeismAssets: React.Dispatch<
    React.SetStateAction<Asset[] | undefined>
  >
  selectedAbsenteeismAssets?: Asset[]
  analysisEnabled: boolean
  setAnalysisEnabled: React.Dispatch<React.SetStateAction<boolean>>
  alertsEnabled: boolean
  setAlertsEnabled: React.Dispatch<React.SetStateAction<boolean>>
  riskIncreaseEnabled: boolean
  setRiskIncreaseEnabled: React.Dispatch<React.SetStateAction<boolean>>
  forecastedAbsenteeismEnabled: boolean
  setForecastedAbsenteeismEnabled: React.Dispatch<React.SetStateAction<boolean>>
  historicalAbsenteeismEnabled: boolean
  setHistoricalAbsenteeismEnabled: React.Dispatch<React.SetStateAction<boolean>>
  forecastRange?: number
  setForecastRange: React.Dispatch<React.SetStateAction<number | undefined>>

  alertLevel: AssetNotificationAlertLevel
  setAlertLevel: React.Dispatch<
    React.SetStateAction<AssetNotificationAlertLevel>
  >
  selectedThreats: Disease_Type[]
  setSelectedThreats: React.Dispatch<React.SetStateAction<Disease_Type[]>>
  expertInsightsSettingGranularity?: SettingGranularity
  setExpertInsightsSettingGranularity: React.Dispatch<
    React.SetStateAction<SettingGranularity>
  >
  hasChangedSettings: boolean
  isSaving: boolean
} | null>(null)

export const NotificationContextProvider: React.FC<{
  assets?: Asset[]
  children?: React.ReactNode
}> = ({ assets, children }) => {
  const [isSaving, setIsSaving] = useState(false)
  const { mutateAsync: updateSettings } =
    useUpdateNotificationSettings(setIsSaving)

  const { mutateAsync: createAsset } = useCreateAsset({ hideToast: true })
  const { isRefetching } = useListAssets({
    includeGlobal: true,
  })

  const nonGlobalAssets = useMemo(() => {
    return getNonGlobalAssets(assets)
  }, [assets])

  const hasPlanetEarth = useMemo(() => {
    return !!assets?.find(
      asset => asset.name === getEnumKey(AssetRegion, AssetRegion.PLANET_EARTH)
    )
  }, [assets])

  const globalAlertsEnabledSetting = useMemo(() => {
    return getGlobalEnabledSettingOfType(AssetNotificationType.ALERT, assets)
  }, [assets])

  const dbAlertsEnabled = useMemo(() => {
    return assetsHaveEnabledSettingOfType(
      AssetNotificationType.ALERT,
      nonGlobalAssets
    )
  }, [nonGlobalAssets])

  const globalAnalysisEnabledSetting = useMemo(() => {
    return getGlobalEnabledSettingOfType(AssetNotificationType.ANALYSIS, assets)
  }, [assets])

  const globalAlertSettingLevel = useMemo(() => {
    return globalAlertsEnabledSetting
      ? alertSettingLevel([globalAlertsEnabledSetting])
      : undefined
  }, [globalAlertsEnabledSetting])

  const dbAnalysisEnabled = useMemo(() => {
    return assetsHaveEnabledSettingOfType(
      AssetNotificationType.ANALYSIS,
      nonGlobalAssets
    )
  }, [nonGlobalAssets])

  const dbRiskIncreaseEnabled = useMemo(() => {
    return assetsHaveEnabledSettingOfType(
      AssetNotificationType.RISK_INCREASE,
      nonGlobalAssets
    )
  }, [nonGlobalAssets])

  const dbAlertSettingLevel = useMemo(() => {
    return alertSettingLevel(nonGlobalAssets)
  }, [nonGlobalAssets])

  const dbRiskIncreaseSelectedThreats = useMemo(() => {
    return riskIncreaseSelectedThreats(nonGlobalAssets)
  }, [nonGlobalAssets])

  const dbForecastedAbsenteeismEnabled = useMemo(() => {
    return assetsHaveEnabledSettingOfType(
      AssetNotificationType.FORECASTED_ABSENTEEISM_INCREASE,
      nonGlobalAssets
    )
  }, [nonGlobalAssets])

  const dbHistoricalAbsenteeismEnabled = useMemo(() => {
    return assetsHaveEnabledSettingOfType(
      AssetNotificationType.HISTORICAL_AVG_ABSENTEEISM_INCREASE,
      nonGlobalAssets
    )
  }, [nonGlobalAssets])

  const dbForecastRange = useMemo(() => {
    return getDBEnabledForecastRange(nonGlobalAssets)
  }, [nonGlobalAssets])

  const [forecastedAbsenteeismEnabled, setForecastedAbsenteeismEnabled] =
    useState(dbForecastedAbsenteeismEnabled)

  const [historicalAbsenteeismEnabled, setHistoricalAbsenteeismEnabled] =
    useState(dbHistoricalAbsenteeismEnabled)

  const [forecastRange, setForecastRange] = useState(dbForecastRange)

  const [riskIncreaseEnabled, setRiskIncreaseEnabled] = useState(
    dbRiskIncreaseEnabled
  )

  const [analysisEnabled, setAnalysisEnabled] = useState(
    dbAnalysisEnabled || !!globalAnalysisEnabledSetting
  )

  const [alertsEnabled, setAlertsEnabled] = useState(
    dbAlertsEnabled || !!globalAlertsEnabledSetting
  )

  const [alertLevel, setAlertLevel] = useState<AssetNotificationAlertLevel>(
    globalAlertSettingLevel ||
      dbAlertSettingLevel ||
      AssetNotificationAlertLevel.ADVISORY
  )

  const [selectedThreats, setSelectedThreats] = useState<Disease_Type[]>(
    Array.from(dbRiskIncreaseSelectedThreats)
  )

  const assetsWithExpertInsightsEnabled = useMemo(
    () => getAssetsWithEnabledExpertInsights(nonGlobalAssets),
    [nonGlobalAssets]
  )

  const assetsWithRiskIncreaseEnabled = useMemo(
    () =>
      getAssetsWithEnabledRiskIncreaseSettings(
        nonGlobalAssets,
        selectedThreats
      ),
    [nonGlobalAssets, selectedThreats]
  )

  const assetsWithAbsenteeismEnabled = useMemo(
    () => getAssetsWithAbsenteeismSettings(nonGlobalAssets),
    [nonGlobalAssets]
  )

  // Once our dialog selections are confirmed, we set selected assets.
  // Selected assets are initialized to settings retrieved from db.
  const [selectedRiskIncreaseAssets, setSelectedRiskIncreaseAssets] = useState(
    assetsWithRiskIncreaseEnabled
  )

  const [selectedExpertInsightsAssets, setSelectedExpertInsightsAssets] =
    useState(assetsWithExpertInsightsEnabled)

  const [selectedAbsenteeismAssets, setSelectedAbsenteeismAssets] = useState(
    assetsWithAbsenteeismEnabled
  )

  // We store our dialog selections in a separate list until they are confirmed or cancelled.
  // Then, we set them as the selected assets or clear them, respectively.
  const [
    riskIncreaseDialogSelectedAssets,
    setRiskIncreaseDialogSelectedAssets,
  ] = useState(selectedRiskIncreaseAssets)

  const [
    expertInsightsDialogSelectedAssets,
    setExpertInsightsDialogSelectedAssets,
  ] = useState(selectedExpertInsightsAssets)

  const [absenteeismDialogSelectedAssets, setAbsenteeismDialogSelectedAssets] =
    useState(selectedAbsenteeismAssets)

  // Maps for efficiently checking for individual enabled settings
  const enabledRiskIncreaseAssetsById = useMemo(
    () => getAssetsById(riskIncreaseDialogSelectedAssets),
    [riskIncreaseDialogSelectedAssets]
  )

  const enabledExpertInsightAssetsById = useMemo(
    () => getAssetsById(expertInsightsDialogSelectedAssets),
    [expertInsightsDialogSelectedAssets]
  )

  const enabledAbsenteeismAssetsById = useMemo(
    () => getAssetsById(absenteeismDialogSelectedAssets),
    [absenteeismDialogSelectedAssets]
  )

  // Setting granularity is only configurable for Expert Insights. Risk Increase settings are
  // per asset only.
  const [
    expertInsightsSettingGranularity,
    setExpertInsightsSettingGranularity,
  ] = useState<SettingGranularity>(
    (globalAlertsEnabledSetting ||
      globalAnalysisEnabledSetting ||
      !assets?.length) &&
      !selectedExpertInsightsAssets?.length
      ? SettingGranularity.GLOBAL
      : SettingGranularity.ASSET
  )

  const discardNotificationSettings = useCallback(() => {
    setRiskIncreaseEnabled(dbRiskIncreaseEnabled)
    setAnalysisEnabled(dbAnalysisEnabled || !!globalAnalysisEnabledSetting)

    setForecastedAbsenteeismEnabled(dbForecastedAbsenteeismEnabled)
    setHistoricalAbsenteeismEnabled(dbHistoricalAbsenteeismEnabled)

    setForecastRange(dbForecastRange)

    setAlertsEnabled(dbAlertsEnabled || !!globalAlertsEnabledSetting)
    setAlertLevel(
      dbAlertSettingLevel ||
        globalAlertSettingLevel ||
        AssetNotificationAlertLevel.ADVISORY
    )

    setSelectedThreats(Array.from(dbRiskIncreaseSelectedThreats))

    setSelectedRiskIncreaseAssets(assetsWithRiskIncreaseEnabled)
    setRiskIncreaseDialogSelectedAssets(assetsWithRiskIncreaseEnabled)

    setSelectedExpertInsightsAssets(assetsWithExpertInsightsEnabled)
    setExpertInsightsDialogSelectedAssets(assetsWithExpertInsightsEnabled)

    setAbsenteeismDialogSelectedAssets(assetsWithAbsenteeismEnabled)
    setSelectedAbsenteeismAssets(assetsWithAbsenteeismEnabled)

    setExpertInsightsSettingGranularity(
      (globalAlertsEnabledSetting || globalAnalysisEnabledSetting) &&
        !assetsWithExpertInsightsEnabled?.length
        ? SettingGranularity.GLOBAL
        : SettingGranularity.ASSET
    )

    trackEvent("DISCARD_NOTIFICATIONS")
  }, [
    dbRiskIncreaseEnabled,
    dbAnalysisEnabled,
    globalAnalysisEnabledSetting,
    dbForecastedAbsenteeismEnabled,
    dbHistoricalAbsenteeismEnabled,
    dbForecastRange,
    dbAlertsEnabled,
    globalAlertsEnabledSetting,
    dbAlertSettingLevel,
    globalAlertSettingLevel,
    dbRiskIncreaseSelectedThreats,
    assetsWithRiskIncreaseEnabled,
    assetsWithExpertInsightsEnabled,
    assetsWithAbsenteeismEnabled,
  ])

  const updatedAssetNotificationSettings: AssetNotificationSettings[] =
    useMemo(() => {
      return (
        assets?.map(asset => {
          return new AssetNotificationSettings({
            assetId: asset.assetId,
            notificationSettings: buildUpdatedAssetWithSettings({
              assetName: asset.name,
              selectedThreats,
              isSelectedForRiskIncrease: !!enabledRiskIncreaseAssetsById?.has(
                asset.assetId
              ),
              isSelectedForExpertInsights:
                !!enabledExpertInsightAssetsById?.has(asset.assetId),
              isSelectedForAbsenteeism: !!enabledAbsenteeismAssetsById?.has(
                asset.assetId
              ),
              assetId: asset.assetId,
              expertInsightsSettingGranularity,
              alertsEnabled,
              alertLevel,
              analysisEnabled,
              riskIncreaseEnabled,
              forecastedAbsenteeismEnabled,
              historicalAbsenteeismEnabled,
              forecastRange,
            }),
          })
        }) || []
      )
    }, [
      assets,
      selectedThreats,
      enabledRiskIncreaseAssetsById,
      enabledExpertInsightAssetsById,
      enabledAbsenteeismAssetsById,
      expertInsightsSettingGranularity,
      alertsEnabled,
      alertLevel,
      analysisEnabled,
      riskIncreaseEnabled,
      forecastedAbsenteeismEnabled,
      historicalAbsenteeismEnabled,
      forecastRange,
    ])

  const hasChangedSettings = useMemo(() => {
    if (!hasPlanetEarth) return true
    if (isSaving || isRefetching || !updatedAssetNotificationSettings.length) {
      return false
    }

    const dbAssetsById = getAssetsById(assets)
    return !updatedAssetNotificationSettings.every(updatedAsset => {
      const dbAsset = dbAssetsById?.get(updatedAsset.assetId)

      // Compare each notification setting where threatType and notificationType match since settings may be out of
      // order in the array, and there is no single key to identify matching assets (we need the composit key).
      return (
        dbAsset &&
        updatedAsset.notificationSettings.every(updatedAssetSetting => {
          const matchingDbAssetSetting = dbAsset.notificationSettings.find(
            dbSetting =>
              dbSetting.notificationType ===
                updatedAssetSetting.notificationType &&
              dbSetting.threatType.case ===
                updatedAssetSetting.threatType.case &&
              dbSetting.threatType.value ===
                updatedAssetSetting.threatType.value
          )

          // When we introduce new settings which customers haven't had access to yet,
          // matchingDbAssetSetting will be undefined.
          // If there is no matching asset setting from the db, check to see if the
          // updated asset setting is enabled. If not, then we effectively don't
          // have changes yet.
          if (
            !matchingDbAssetSetting &&
            updatedAssetSetting.status ===
              AssetNotificationSettingStatus.DISABLED
          ) {
            return true
          }

          return matchingDbAssetSetting
            ? isEqual(matchingDbAssetSetting, updatedAssetSetting)
            : false
        })
      )
    })
  }, [
    assets,
    hasPlanetEarth,
    isRefetching,
    isSaving,
    updatedAssetNotificationSettings,
  ])

  const trackNotificationsUpdate = useCallback(() => {
    trackEvent("SAVE_NOTIFICATIONS", {
      analysisEnabled,
      alertsEnabled,
      alertLevel: AssetNotificationAlertLevel[alertLevel],
      riskIncreaseEnabled,
      selectedThreats: selectedThreats.map(threat => Disease_Type[threat]),
      expertInsightsSettingGranularity,
      historicalAbsenteeismEnabled,
      forecastedAbsenteeismEnabled,
    })
  }, [
    alertLevel,
    alertsEnabled,
    analysisEnabled,
    expertInsightsSettingGranularity,
    forecastedAbsenteeismEnabled,
    historicalAbsenteeismEnabled,
    riskIncreaseEnabled,
    selectedThreats,
  ])

  const submitNotificationSettings = useCallback(async () => {
    setIsSaving(true)

    // If there is not an existing asset for PLANET_EARTH, create one.
    if (!hasPlanetEarth) {
      const newAsset = createGlobalAsset()
      newAsset.notificationSettings = buildUpdatedAssetWithSettings({
        assetName: newAsset.name,
        selectedThreats,
        isSelectedForRiskIncrease: false, // risk increase cannot be global
        isSelectedForExpertInsights: true,
        isSelectedForAbsenteeism: false, // absenteeism cannot be global
        assetId: "",
        expertInsightsSettingGranularity,
        alertsEnabled,
        alertLevel,
        analysisEnabled,
        riskIncreaseEnabled: false,
        forecastedAbsenteeismEnabled: false,
        historicalAbsenteeismEnabled: false,
        forecastRange: undefined,
      })

      await createAsset({ asset: newAsset })
    }

    updatedAssetNotificationSettings.length
      ? await updateSettings({
          assetNotificationSettings: updatedAssetNotificationSettings,
        })
      : setIsSaving(false)

    trackNotificationsUpdate()
  }, [
    alertLevel,
    alertsEnabled,
    analysisEnabled,
    createAsset,
    expertInsightsSettingGranularity,
    hasPlanetEarth,
    selectedThreats,
    trackNotificationsUpdate,
    updateSettings,
    updatedAssetNotificationSettings,
  ])

  const context = useMemo(() => {
    return {
      assets,
      assetsLoading: isRefetching,
      nonGlobalAssets,
      submitNotificationSettings,
      discardNotificationSettings,
      riskIncreaseDialogSelectedAssets,
      setRiskIncreaseDialogSelectedAssets,
      expertInsightsDialogSelectedAssets,
      setExpertInsightsDialogSelectedAssets,
      absenteeismDialogSelectedAssets,
      setAbsenteeismDialogSelectedAssets,
      analysisEnabled,
      setAnalysisEnabled,
      riskIncreaseEnabled,
      setRiskIncreaseEnabled,
      forecastedAbsenteeismEnabled,
      setForecastedAbsenteeismEnabled,
      historicalAbsenteeismEnabled,
      setHistoricalAbsenteeismEnabled,
      forecastRange,
      setForecastRange,
      alertsEnabled,
      setAlertsEnabled,
      alertLevel,
      setAlertLevel,
      selectedThreats,
      setSelectedThreats,
      selectedRiskIncreaseAssets,
      setSelectedRiskIncreaseAssets,
      selectedExpertInsightsAssets,
      setSelectedExpertInsightsAssets,
      selectedAbsenteeismAssets,
      setSelectedAbsenteeismAssets,
      expertInsightsSettingGranularity,
      setExpertInsightsSettingGranularity,
      hasChangedSettings,
      isSaving,
    }
  }, [
    assets,
    isRefetching,
    nonGlobalAssets,
    submitNotificationSettings,
    discardNotificationSettings,
    riskIncreaseDialogSelectedAssets,
    expertInsightsDialogSelectedAssets,
    absenteeismDialogSelectedAssets,
    analysisEnabled,
    riskIncreaseEnabled,

    forecastedAbsenteeismEnabled,
    historicalAbsenteeismEnabled,
    forecastRange,
    alertsEnabled,
    alertLevel,
    selectedThreats,
    selectedRiskIncreaseAssets,
    selectedExpertInsightsAssets,
    selectedAbsenteeismAssets,
    expertInsightsSettingGranularity,
    hasChangedSettings,
    isSaving,
  ])

  return (
    <NotificationContext.Provider value={context}>
      {children}
    </NotificationContext.Provider>
  )
}

export function useNotificationContext(
  notificationType?: AssetNotificationType
) {
  const context = useContext(NotificationContext)

  if (context == null) {
    Sentry.captureMessage(
      "useNotificationContext must be used within a NotificationContext",
      SeverityLevel.Fatal
    )
    throw new Error(
      "useNotificationContext must be used within a NotificationContext"
    )
  }

  return useMemo(() => {
    // Downstream consumers of this context may need to know which setters/getters to use
    // for their type-agnostic components, so we can split these out based on which
    // AssetNotificationType the context hook is called with.
    // We want all of the objects to live upstream inside the context so we can submit them at the
    // same time in an update request, or else we'd be able to split these out into separate contexts entirely.
    switch (notificationType) {
      case AssetNotificationType.RISK_INCREASE:
        return {
          assets: context.assets,
          assetsLoading: context.assetsLoading,
          nonGlobalAssets: context.nonGlobalAssets,
          hasChangedSettings: context.hasChangedSettings,
          isSaving: context.isSaving,
          selectedAssets: context.selectedRiskIncreaseAssets,
          setSelectedAssets: context.setSelectedRiskIncreaseAssets,
          dialogSelectedAssets: context.riskIncreaseDialogSelectedAssets,
          setDialogSelectedAssets: context.setRiskIncreaseDialogSelectedAssets,
          selectedThreats: context.selectedThreats,
          setSelectedThreats: context.setSelectedThreats,
          riskIncreaseEnabled: context.riskIncreaseEnabled,
          setRiskIncreaseEnabled: context.setRiskIncreaseEnabled,
        }
      case AssetNotificationType.FORECASTED_ABSENTEEISM_INCREASE:
      case AssetNotificationType.HISTORICAL_AVG_ABSENTEEISM_INCREASE:
        return {
          assets: context.assets,
          assetsLoading: context.assetsLoading,
          nonGlobalAssets: context.nonGlobalAssets,
          hasChangedSettings: context.hasChangedSettings,
          isSaving: context.isSaving,
          selectedAssets: context.selectedAbsenteeismAssets,
          setSelectedAssets: context.setSelectedAbsenteeismAssets,
          dialogSelectedAssets: context.absenteeismDialogSelectedAssets,
          setDialogSelectedAssets: context.setAbsenteeismDialogSelectedAssets,
          forecastedAbsenteeismEnabled: context.forecastedAbsenteeismEnabled,
          setForecastedAbsenteeismEnabled:
            context.setForecastedAbsenteeismEnabled,
          historicalAbsenteeismEnabled: context.historicalAbsenteeismEnabled,
          setHistoricalAbsenteeismEnabled:
            context.setHistoricalAbsenteeismEnabled,
          forecastRange: context.forecastRange,
          setForecastRange: context.setForecastRange,
        }
      case AssetNotificationType.ALERT:
      case AssetNotificationType.ANALYSIS:
        return {
          assets: context.assets,
          assetsLoading: context.assetsLoading,
          nonGlobalAssets: context.nonGlobalAssets,
          hasChangedSettings: context.hasChangedSettings,
          isSaving: context.isSaving,
          selectedAssets: context.selectedExpertInsightsAssets,
          setSelectedAssets: context.setSelectedExpertInsightsAssets,
          dialogSelectedAssets: context.expertInsightsDialogSelectedAssets,
          setDialogSelectedAssets:
            context.setExpertInsightsDialogSelectedAssets,
          settingGranularity: context.expertInsightsSettingGranularity,
          setSettingGranularity: context.setExpertInsightsSettingGranularity,
          analysisEnabled: context.analysisEnabled,
          setAnalysisEnabled: context.setAnalysisEnabled,
          alertsEnabled: context.alertsEnabled,
          setAlertsEnabled: context.setAlertsEnabled,
          alertLevel: context.alertLevel,
          setAlertLevel: context.setAlertLevel,
        }
      default:
        return {
          assets: context.assets,
          assetsLoading: context.assetsLoading,
          nonGlobalAssets: context.nonGlobalAssets,
          hasChangedSettings: context.hasChangedSettings,
          isSaving: context.isSaving,
          submitNotificationSettings: context.submitNotificationSettings,
          discardNotificationSettings: context.discardNotificationSettings,
        }
    }
  }, [context, notificationType])
}

export const isSettingGranularity = (
  value: any
): value is SettingGranularity => {
  return (
    value === SettingGranularity.GLOBAL || value === SettingGranularity.ASSET
  )
}

function getAssetsWithEnabledRiskIncreaseSettings(
  assets?: Asset[],
  threatTypes?: Disease_Type[]
) {
  return assets?.filter(asset => {
    const riskIncreaseSettings = asset.notificationSettings.filter(
      setting =>
        setting.notificationType === AssetNotificationType.RISK_INCREASE &&
        setting.threatType.case === "diseaseType" &&
        threatTypes?.includes(setting.threatType.value)
    )

    return (
      riskIncreaseSettings.length &&
      riskIncreaseSettings.every(
        setting => setting.status === AssetNotificationSettingStatus.ENABLED
      )
    )
  })
}

function getAssetsWithAbsenteeismSettings(assets?: Asset[]) {
  return assets?.filter(asset => {
    return !!asset.notificationSettings.find(
      setting =>
        ABSENTEEISM_INSIGHT_TYPES.includes(setting.notificationType) &&
        setting.status === AssetNotificationSettingStatus.ENABLED
    )
  })
}

function getAssetsWithEnabledExpertInsights(assets?: Asset[]) {
  return assets?.filter(asset => {
    return !!asset.notificationSettings.find(
      setting =>
        EXPERT_INSIGHT_TYPES.includes(setting.notificationType) &&
        setting.status === AssetNotificationSettingStatus.ENABLED
    )
  })
}

function buildNotificationSetting(
  assetId: string,
  settingType: AssetNotificationType,
  threatType: Disease_Type,
  status: AssetNotificationSettingStatus,
  forecastRange?: number,
  alertLevel?: AssetNotificationAlertLevel
) {
  const setting = new AssetNotificationSetting({
    assetId: assetId,
    notificationType: settingType,
    threatType: {
      value: threatType,
      case: "diseaseType",
    },
    status: status,
  })

  if (settingType === AssetNotificationType.ALERT && alertLevel) {
    setting.alertLevel = alertLevel
  }

  if (ABSENTEEISM_INSIGHT_TYPES.includes(settingType) && forecastRange) {
    setting.forecastRangeWeekCount = forecastRange
  }

  return setting
}

export function assetsHaveEnabledSettingOfType(
  notificationType: AssetNotificationType,
  assets?: Asset[]
) {
  return !!assets?.find(asset =>
    hasEnabledSettingOfType(notificationType, asset)
  )
}

export function getGlobalEnabledSettingOfType(
  notificationType: AssetNotificationType,
  assets?: Asset[]
) {
  return assets?.find(
    asset =>
      hasEnabledSettingOfType(notificationType, asset) &&
      asset.name === getEnumKey(AssetRegion, AssetRegion.PLANET_EARTH)
  )
}

function hasEnabledSettingOfType(
  notificationType: AssetNotificationType,
  asset?: Asset
) {
  return !!asset?.notificationSettings.find(
    setting =>
      setting.notificationType === notificationType &&
      setting.status === AssetNotificationSettingStatus.ENABLED
  )
}

export function alertSettingLevel(assets?: Asset[]) {
  return assets?.flatMap(asset => {
    const alertLevel = asset.notificationSettings.find(
      setting =>
        setting.notificationType === AssetNotificationType.ALERT &&
        setting.status === AssetNotificationSettingStatus.ENABLED
    )

    return alertLevel?.alertLevel ?? []
  })[0]
}

export function getDBEnabledForecastRange(assets?: Asset[]) {
  return assets?.flatMap(asset => {
    const absenteeismSetting = asset.notificationSettings.find(
      setting =>
        ABSENTEEISM_INSIGHT_TYPES.includes(setting.notificationType) &&
        setting.status === AssetNotificationSettingStatus.ENABLED
    )

    return absenteeismSetting?.forecastRangeWeekCount ?? []
  })[0]
}

export function riskIncreaseSelectedThreats(assets?: Asset[]) {
  const threatTypeSet = new Set<Disease_Type>()

  // The first asset we find with enabled risk increase settings will have all
  // of the selected threat types on it that we want to use as a default, so we
  // can do a `find` and return early once we find one with enabled settings.
  assets?.find(asset => {
    const riskIncreaseSettings = asset.notificationSettings.filter(setting => {
      return (
        setting.notificationType === AssetNotificationType.RISK_INCREASE &&
        setting.status === AssetNotificationSettingStatus.ENABLED
      )
    })

    riskIncreaseSettings.forEach(setting => {
      if (setting.threatType.case == "diseaseType")
        threatTypeSet.add(setting.threatType.value)
    })

    return riskIncreaseSettings.length
  })

  return threatTypeSet
}

function getAssetsById(selectedAssets?: Asset[]) {
  return selectedAssets?.reduce((byId, asset) => {
    byId.set(asset.assetId, asset)
    return byId
  }, new Map<string, Asset>())
}

export const buildUpdatedAssetWithSettings = ({
  assetName,
  selectedThreats,
  isSelectedForRiskIncrease,
  isSelectedForExpertInsights,
  isSelectedForAbsenteeism,
  assetId,
  expertInsightsSettingGranularity,
  alertsEnabled,
  alertLevel,
  analysisEnabled,
  riskIncreaseEnabled,
  forecastedAbsenteeismEnabled,
  historicalAbsenteeismEnabled,
  forecastRange,
}: {
  assetName: string
  selectedThreats: Disease_Type[]
  isSelectedForRiskIncrease: boolean
  isSelectedForExpertInsights: boolean
  isSelectedForAbsenteeism: boolean
  assetId: string
  expertInsightsSettingGranularity: SettingGranularity
  alertsEnabled: boolean
  alertLevel: AssetNotificationAlertLevel
  analysisEnabled: boolean
  riskIncreaseEnabled: boolean
  forecastedAbsenteeismEnabled: boolean
  historicalAbsenteeismEnabled: boolean
  forecastRange?: number
}): AssetNotificationSetting[] => {
  const updatedSettings: AssetNotificationSetting[] = []

  // The primary key for asset notification settings in the db is assetId + notificationType + threatType,
  // so we need to build a notification setting per asset per notification type per threat type.
  enumKeys(Disease_Type).forEach(threat => {
    const diseaseType = Disease_Type[threat]
    if (diseaseType === Disease_Type.UNSPECIFIED) return

    const isPlanetEarthAsset =
      assetName === getEnumKey(AssetRegion, AssetRegion.PLANET_EARTH)

    // Build risk increase settings.
    // Do not enable for PLANET_EARTH asset.
    updatedSettings.push(
      buildNotificationSetting(
        assetId,
        AssetNotificationType.RISK_INCREASE,
        diseaseType,
        !isPlanetEarthAsset &&
          isSelectedForRiskIncrease &&
          selectedThreats.includes(diseaseType) &&
          riskIncreaseEnabled
          ? AssetNotificationSettingStatus.ENABLED
          : AssetNotificationSettingStatus.DISABLED,
        undefined,
        undefined
      )
    )

    // Build settings for alerts and analysis (expert insights).
    // If global granularity is selected, the settings need to be disabled for non-PLANET_EARTH assets,
    // but they need to be enabled for the PLANET_EARTH asset, and vice versa.
    const canBeEnabled = isPlanetEarthAsset
      ? expertInsightsSettingGranularity === SettingGranularity.GLOBAL
      : isSelectedForExpertInsights &&
        expertInsightsSettingGranularity !== SettingGranularity.GLOBAL

    // Build settings for alerts
    updatedSettings.push(
      buildNotificationSetting(
        assetId,
        AssetNotificationType.ALERT,
        diseaseType,
        alertsEnabled && canBeEnabled
          ? AssetNotificationSettingStatus.ENABLED
          : AssetNotificationSettingStatus.DISABLED,
        undefined,
        alertLevel
      )
    )

    // Build settings for analysis
    updatedSettings.push(
      buildNotificationSetting(
        assetId,
        AssetNotificationType.ANALYSIS,
        diseaseType,
        analysisEnabled && canBeEnabled
          ? AssetNotificationSettingStatus.ENABLED
          : AssetNotificationSettingStatus.DISABLED,
        undefined,
        undefined
      )
    )

    const assetSelectedForAbsenteeism =
      !isPlanetEarthAsset && isSelectedForAbsenteeism

    updatedSettings // Build settings for historical absenteeism
      .push(
        buildNotificationSetting(
          assetId,
          AssetNotificationType.HISTORICAL_AVG_ABSENTEEISM_INCREASE,
          diseaseType,
          historicalAbsenteeismEnabled && assetSelectedForAbsenteeism
            ? AssetNotificationSettingStatus.ENABLED
            : AssetNotificationSettingStatus.DISABLED,
          forecastRange,
          undefined
        )
      )

    // Build settings for forecasted absenteeism
    updatedSettings.push(
      buildNotificationSetting(
        assetId,
        AssetNotificationType.FORECASTED_ABSENTEEISM_INCREASE,
        diseaseType,
        forecastedAbsenteeismEnabled && assetSelectedForAbsenteeism
          ? AssetNotificationSettingStatus.ENABLED
          : AssetNotificationSettingStatus.DISABLED,
        forecastRange,
        undefined
      )
    )
  })

  return updatedSettings
}

const createGlobalAsset = () => {
  const locationId = getEnumKey(AssetRegion, AssetRegion.PLANET_EARTH)

  const baseEvent = new BaseEvent({
    geotags: [
      {
        locationId,
        wktCentroid: "POINT (0.0 0.0)",
      },
    ],
  })

  return new Asset({
    name: getEnumKey(AssetRegion, AssetRegion.PLANET_EARTH),
    baseEvent,
    threatRadius: 12500,
    assetTypes: [AssetType.ASSET_TYPE_UNSPECIFIED],
  })
}
