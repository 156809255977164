import CloseIcon from "@mui/icons-material/Close"
import type { AlertProps } from "@mui/material"
import { Alert, Box, Collapse, IconButton, styled } from "@mui/material"
import { extraColors } from "../../utils/theme"
import WarningAmberIcon from "@mui/icons-material/WarningAmber"

const StyledAlert = styled(Alert)({
  "& .MuiAlert-icon": {
    margin: "3px 6px 4px 4px",
  },
  "& .MuiIconButton-sizeSmall": {
    margin: 0,
  },
})

interface BannerProps {
  open: boolean
  type: AlertProps["severity"]
  children: React.ReactNode
  dismissFunction?: () => void
  className?: string
}

export const Banner: React.FC<BannerProps> = ({
  open = false,
  type,
  children,
  dismissFunction,
  className,
}) => {
  return (
    <Box className={className}>
      <Collapse in={open}>
        <StyledAlert
          severity={type}
          color={type}
          action={
            dismissFunction && (
              <IconButton
                aria-label="close"
                size="small"
                onClick={dismissFunction}
                sx={{ margin: "auto 0 auto 0" }}
              >
                <CloseIcon
                  fontSize="inherit"
                  sx={{ color: extraColors.purpleDark }}
                />
              </IconButton>
            )
          }
          iconMapping={{
            info: (
              <WarningAmberIcon
                style={{
                  color: extraColors.purpleDark,
                  fontSize: 16,
                }}
              />
            ),
          }}
        >
          {children}
        </StyledAlert>
      </Collapse>
    </Box>
  )
}
