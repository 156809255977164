import { Typography, styled } from "@mui/material"
import { SectionContainer } from "../../LocationRisk/Shared"
import { extraColors } from "../../../../utils/theme"
import Button from "../../../Shared/Button"
import { Edit } from "@mui/icons-material"
import type { Asset } from "@phc-health/connect-query"
import type React from "react"
import { useState } from "react"
import { EditSiteDetails } from "./EditSiteDetails"
import { DisplaySiteDetails } from "./DisplaySiteDetails"

const SectionHeaderContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
})

export const SiteDetailSiteSection: React.FC<{
  asset: Asset
}> = ({ asset }) => {
  const [isEditing, setIsEditing] = useState(false)

  return (
    <SectionContainer>
      <SectionHeaderContainer>
        <Typography variant="h3Bold" sx={{ color: extraColors.medium }}>
          Site
        </Typography>
        <Button
          variant="text"
          sx={{ color: extraColors.navy }}
          startIcon={
            <Edit
              sx={{
                height: 18,
                width: 18,
                marginBottom: "2px",
                opacity: isEditing ? 0 : 1,
              }}
            />
          }
          onClick={() => setIsEditing(prev => !prev)}
          disabled={isEditing}
        >
          {isEditing ? "" : "Edit"}
        </Button>
      </SectionHeaderContainer>
      {isEditing ? (
        <EditSiteDetails asset={asset} setIsEditing={setIsEditing} />
      ) : (
        <DisplaySiteDetails asset={asset} />
      )}
    </SectionContainer>
  )
}
