import img0 from "../../assets/pngs/CriticalThreatDefaultImages/critical_threat_0.png"
import img1 from "../../assets/pngs/CriticalThreatDefaultImages/critical_threat_1.png"
import img2 from "../../assets/pngs/CriticalThreatDefaultImages/critical_threat_2.png"
import img3 from "../../assets/pngs/CriticalThreatDefaultImages/critical_threat_3.png"
import img4 from "../../assets/pngs/CriticalThreatDefaultImages/critical_threat_4.png"
import img5 from "../../assets/pngs/CriticalThreatDefaultImages/critical_threat_5.png"
import img6 from "../../assets/pngs/CriticalThreatDefaultImages/critical_threat_6.png"
import img7 from "../../assets/pngs/CriticalThreatDefaultImages/critical_threat_7.png"
import img8 from "../../assets/pngs/CriticalThreatDefaultImages/critical_threat_8.png"
import img9 from "../../assets/pngs/CriticalThreatDefaultImages/critical_threat_9.png"
import img10 from "../../assets/pngs/CriticalThreatDefaultImages/critical_threat_10.png"

const IMAGES = [
  img0,
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
]
export function getCriticalThreatImageFromSeed(title: string) {
  // Convert the first character of the title to lowercase
  const firstChar = title.charAt(0).toLowerCase()

  // Define the alphabet
  const alphabet = "abcdefghijklmnopqrstuvwxyz"

  // Define the number of sections
  const sectionLength = Math.floor(alphabet.length / IMAGES.length)

  // Find the index of the section that contains the first character
  const sectionIndex = Math.floor(alphabet.indexOf(firstChar) / sectionLength)

  // Return the corresponding image from IMAGES array
  return IMAGES[sectionIndex] || IMAGES[IMAGES.length - 1]
}
