import { AccessManagementServiceQuery } from "@phc-health/connect-query"
import { useMutation } from "@connectrpc/connect-query"
import { useSnackbar } from "notistack"
import { useNavigate } from "react-router-dom"
import { ACCESS_API, ROUTES, stringifySearchParams } from "../utils/constants"
import { useTransport } from "./useTransport"

export function useCreateOrganization() {
  const navigate = useNavigate()
  const transport = useTransport(ACCESS_API)
  const { enqueueSnackbar } = useSnackbar()
  return useMutation(AccessManagementServiceQuery.createOrganization, {
    transport,
    onError: err => {
      console.error(err)
    },
    onSuccess: data => {
      navigate({
        pathname: ROUTES.USERS,
        search: stringifySearchParams({
          organizationId: data.name,
          organizationName: data.displayName,
        }),
      })
      enqueueSnackbar("Organization created", {
        variant: "success",
      })
    },
  })
}
