import { Link, Typography } from "@mui/material"
import { extraColors } from "../../utils/theme"
import { WorkforcePlanningGraph } from "./WorkforcePlanningGraph"
import PopOver from "../PopOver"
import { Link as RouterLink, generatePath } from "react-router-dom"
import { ROUTES } from "../../utils/constants"
import { IndustryAutocomplete } from "../WatchedLocations/AssetManagement/IndustryAutocomplete"
import { IndustryType } from "@phc-health/connect-query"
import type React from "react"
import { useEffect, useRef, useState } from "react"
import { WorkforcePlanningTable } from "./WorkforcePlanningTable"
import { useLayersForAbsenteeismById } from "../../hooks/useLayersApi"
import { WorkforcePlanningHoverProvider } from "./WorkforcePlanningHoverContext"
import { WorkforcePlanningContainer } from "./workforcePlanningShared"
import type { LottieRef } from "lottie-react"
import Lottie from "lottie-react"
import heartLoader from "../../lottie/heart-loader.json"
import { trackEvent } from "../../utils/mixpanel"

export const WorkforcePlanningContent: React.FC<{
  locationId: string | undefined
  defaultIndustry: IndustryType | undefined
}> = ({ locationId, defaultIndustry = IndustryType.BASELINE }) => {
  const [selectedIndustry, setSelectedIndustry] = useState(defaultIndustry)
  const { data, isLoading } = useLayersForAbsenteeismById({
    locationId,
    industry: selectedIndustry || IndustryType.BASELINE,
  })

  useEffect(() => {
    trackEvent("WORKFORCE_PLANNING_INDUSTRY_CHANGE", {
      industry: IndustryType[selectedIndustry],
    })
  }, [selectedIndustry])

  useEffect(() => {
    setSelectedIndustry(curr =>
      curr === defaultIndustry ? curr : defaultIndustry
    )
  }, [defaultIndustry])

  const [animationFinished, setAnimationFinished] = useState(!isLoading)
  const lottieRef: LottieRef = useRef(null)

  useEffect(() => {
    if (isLoading) {
      setAnimationFinished(false)
      lottieRef.current?.play()
    }
  }, [isLoading])

  if (locationId && !locationId.startsWith("840")) {
    return (
      <WorkforcePlanningContainer>
        <Typography variant="h3Bold" component="h3">
          Absenteeism Forecast
        </Typography>
        <Typography variant="body1">
          Absenteeism Forecasts are only available for the United States.
        </Typography>
      </WorkforcePlanningContainer>
    )
  }

  if (isLoading || !animationFinished) {
    return (
      <WorkforcePlanningContainer>
        <Typography variant="h3Bold" component="h3">
          Absenteeism Forecast
        </Typography>
        <Lottie
          animationData={heartLoader}
          lottieRef={lottieRef}
          onLoopComplete={() => {
            if (!isLoading) {
              lottieRef.current?.pause()
              setAnimationFinished(true)
            }
          }}
          style={{ maxWidth: 1000, margin: "0 auto" }}
        />
      </WorkforcePlanningContainer>
    )
  }

  return (
    <WorkforcePlanningContainer>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(300px, auto))",
          gap: 20,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <PopOver size="lg" horizontal={"left"}>
            <Typography variant="small1">
              The tool predicts the weekly absenteeism rate, influenced by
              industry, location, and VRI (Viral Respiratory Illness) severity.
              It indicates the expected % of absent employees, comparing to it
              the industry baseline rate.{" "}
              <Link
                variant="small1Bold"
                color={extraColors.purpleMiddle}
                component={RouterLink}
                to={generatePath(ROUTES.FAQ_POST, {
                  slug: "how-to-interpret-absenteeism-data-in-the-workforce-planning-tool",
                })}
                underline="hover"
                target="_blank"
              >
                Read More
              </Link>
            </Typography>
          </PopOver>
          <Typography variant="h3Bold" component="h3" display="inline">
            Absenteeism Forecast
          </Typography>
        </div>
        <IndustryAutocomplete
          selectedIndustry={selectedIndustry || IndustryType.BASELINE}
          setSelectedIndustry={setSelectedIndustry}
          showBaseline
        />
      </div>
      <WorkforcePlanningHoverProvider>
        <WorkforcePlanningGraph
          absenteeismData={data?.absenteeismForecasts}
          min={data?.min}
          max={data?.max}
        />
        <WorkforcePlanningTable absenteeismData={data?.absenteeismForecasts} />
      </WorkforcePlanningHoverProvider>
    </WorkforcePlanningContainer>
  )
}
