import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import { styled, Typography } from "@mui/material"
import type { Bucket } from "@phc-health/connect-query"
import { Disease_Type } from "@phc-health/connect-query"
import { useState } from "react"
import { diseaseTypeMapping } from "../../../../../utils/helpers/threatTrendHelper"
import { extraColors } from "../../../../../utils/theme"
import { InfoIconStyled, LightTooltip } from "../../Shared"
import { DiseaseIndicator } from "./DiseaseIndicator"
import { trackEvent } from "../../../../../utils/mixpanel"

const FactorContainer = styled("div")({
  marginTop: 24,
})

const DiseaseHeader = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  cursor: "pointer",
})

const IndicatorContainer = styled("div")<{ isCollapsed: boolean }>(
  ({ isCollapsed }) => ({
    display: isCollapsed ? "none" : "block",
    marginTop: 8,
    "@media print": {
      display: "block",
    },
  })
)

const KeyboardArrowUpIconStyled = styled(KeyboardArrowUpIcon)({
  fill: extraColors.purpleMiddle,
  marginLeft: "auto",
  marginRight: -8,
})

const KeyboardArrowDownIconStyled = styled(KeyboardArrowDownIcon)({
  fill: extraColors.purpleMiddle,
  marginLeft: "auto",
  marginRight: -8,
})

interface DiseaseFactorProps {
  bucket: Bucket
  defaultCollapsed: boolean
}

export const DiseaseFactor: React.FC<DiseaseFactorProps> = ({
  bucket,
  defaultCollapsed,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed)
  const tooltipText = getDiseaseTooltipText(bucket.disease)
  return (
    <FactorContainer>
      <DiseaseHeader
        onClick={() => {
          setIsCollapsed(!isCollapsed)
          trackEvent("OPEN_DISEASE_FACTOR", {
            disease: Disease_Type[bucket.disease],
            locationId: bucket.baseEvent?.locationId,
          })
        }}
      >
        <Typography
          variant="body1Bold"
          sx={{ color: extraColors.purpleMiddle }}
        >
          {diseaseTypeMapping(bucket.disease)}
        </Typography>
        {tooltipText && (
          <LightTooltip title={tooltipText} arrow placement="top-end">
            <InfoIconStyled />
          </LightTooltip>
        )}
        {isCollapsed ? (
          <KeyboardArrowDownIconStyled />
        ) : (
          <KeyboardArrowUpIconStyled />
        )}
      </DiseaseHeader>
      <IndicatorContainer isCollapsed={isCollapsed}>
        <DiseaseIndicator bucket={bucket} />
      </IndicatorContainer>
    </FactorContainer>
  )
}

const getDiseaseTooltipText = (disease: Disease_Type) => {
  switch (disease) {
    case Disease_Type.COVID:
      return "The infectious respiratory disease caused by the SARS-CoV-2 virus. Distinct from other coronaviruses, a positive case is confirmed by laboratory testing."
    case Disease_Type.FLU:
      return "The infectious respiratory disease caused by the influenza virus. Typically caused by influenza A or B, symptoms include fever, cough, sore throat, and achiness."
    case Disease_Type.RSV:
      return "The infectious respiratory disease caused by the respiratory syncytial virus. Symptoms, which include couging and fever, tend to be more severe in children and the elderly."
    default:
      return undefined
  }
}
