import { Typography, styled } from "@mui/material"
import { isAlertLevel } from "@phc/common"

import { extraColors } from "../../../utils/theme"
import { AlertCard } from "../../Map/Cards/AlertCard"
import { useCapServiceContext } from "../../CriticalThreats/capServiceContext"

const AlertCardsContainer = styled("div")(() => ({
  paddingTop: 12,
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(280px, 1fr))",
  gap: 24,
}))

export const RelatedAlerts: React.FC<{
  locationId?: string
}> = ({ locationId }) => {
  const { useRelatedAlerts } = useCapServiceContext()
  const alerts = useRelatedAlerts(locationId)

  if (!alerts.data?.length) return null

  return (
    <div
      style={{
        marginBottom: "48px",
        marginTop: "4px",
      }}
    >
      <Typography variant="h3Bold" color={extraColors.medium}>
        Related Alerts
      </Typography>
      <AlertCardsContainer>
        {alerts.data.map(alert => (
          <AlertCard
            key={alert._id}
            id={alert._id}
            locationId={alert.geotag?.id}
            title={alert.title ?? ""}
            location={alert.geotag?.name ?? ""}
            locationSubTitle={alert.geotag?.subtitle ?? ""}
            date={alert.publishedAt ?? alert.updatedAt ?? ""}
            alertLevel={
              isAlertLevel(alert.alertLevel) ? alert.alertLevel : undefined
            }
            content={alert.justification}
            recommendation={alert.recommendation}
            link={alert.link}
            threat={alert.categories?.[0]?.title || ""}
          />
        ))}
      </AlertCardsContainer>
    </div>
  )
}
