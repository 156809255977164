import type { Asset, Bucket } from "@phc-health/connect-query"
import { AssetType, Disease_Type } from "@phc-health/connect-query"
import * as Sentry from "@sentry/react"
import { AlertLevel } from "@phc/common"
import type React from "react"
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  type SetStateAction,
} from "react"
import { useListAssets } from "../components/WatchedLocations/hooks/useAssetService"
import { SeverityLevel } from "../utils/constants"
import { useCapServiceContext } from "../components/CriticalThreats/capServiceContext"
import type { CAPRouterOutput } from "../utils/cap"
import { useLayersForMap } from "../hooks/useLayersApi"
import { getLocationId } from "../utils/helpers"

type AssetFilter = Partial<Record<AssetType, boolean>>

interface MapContextValue {
  openAlertDrawer: boolean
  setOpenAlertDrawer: React.Dispatch<SetStateAction<boolean>>
  selectedLocationId: string | undefined
  setSelectedLocationId: React.Dispatch<SetStateAction<string | undefined>>
  relatedAlerts: CAPRouterOutput["relatedAlerts"] | undefined
  relatedAnalyses: CAPRouterOutput["relatedAnalyses"] | undefined
  availableAssetTypes: AssetType[]
  assets?: Asset[]
  setAssetFilters: React.Dispatch<SetStateAction<AssetFilter | undefined>>
  assetFilters: AssetFilter | undefined
  bucketData: Record<string, Bucket[]>
  setDiseaseType: React.Dispatch<SetStateAction<Disease_Type>>
  diseaseType: Disease_Type
  alertFilters: AlertLevel[]
  setAlertFilters: React.Dispatch<SetStateAction<AlertLevel[]>>
  clusterAlertsIds: string[]
  setClusterAlertsIds: React.Dispatch<SetStateAction<string[]>>
  mapLoadedRef: React.MutableRefObject<boolean>
}

const MapContext = createContext<MapContextValue | null>(null)

export const MapContextProvider = ({
  children,
}: {
  children?: React.ReactNode
}) => {
  const mapLoadedRef = useRef(false)

  const [openAlertDrawer, setOpenAlertDrawer] = useState<boolean>(false)
  const [selectedLocationId, setSelectedLocationId] = useState<
    string | undefined
  >(undefined)
  const [alertFilters, setAlertFilters] = useState([
    AlertLevel.ADVISORY,
    AlertLevel.WATCH,
    AlertLevel.WARNING,
  ])
  const [clusterAlertsIds, setClusterAlertsIds] = useState<string[]>([])

  const { useRelatedAlerts, useRelatedAnalyses } = useCapServiceContext()
  const { data: relatedAlerts } = useRelatedAlerts(selectedLocationId)
  const { data: relatedAnalyses } = useRelatedAnalyses(selectedLocationId)
  const { data: assetData } = useListAssets({
    includeGlobal: false,
    excludeNotifications: true,
    excludeGroups: true,
  })

  // get asset types and dedupe values
  const availableAssetTypes = useMemo(() => {
    const dedupedAssetTypes = [
      ...new Set(
        assetData.assets.flatMap(asset => {
          // an empty assetTypes arr reflects no asset type selected which should map to AssetType.UNSPECIFIED_ASSET_TYPE
          if (!asset.assetTypes.length) {
            return AssetType.ASSET_TYPE_UNSPECIFIED
          } else {
            return asset.assetTypes
          }
        })
      ),
    ]

    return dedupedAssetTypes
  }, [assetData.assets])

  const [assetFilters, setAssetFilters] = useState<AssetFilter>()

  useEffect(() => {
    // generate initial asset filters using availableAssetTypes and set to TRUE by default
    const initialAssetFilters = availableAssetTypes.reduce<AssetFilter>(
      (filters, type) => {
        filters[type] = true
        return filters
      },
      {}
    )

    setAssetFilters(initialAssetFilters)
  }, [availableAssetTypes])

  // default to RESP
  const [diseaseType, setDiseaseType] = useState(Disease_Type.RESP)
  const newBuckets = useLayersForMap(diseaseType)
  const [allBuckets, setAllBuckets] = useState<Record<string, Bucket[]>>({})

  useEffect(() => {
    setAllBuckets(prev => {
      const bucketByLocationId =
        newBuckets.data?.buckets.reduce(
          (byId, bucket) => {
            const key = getLocationId(bucket)
            if (!key) return byId
            const mappedBucket = byId[key]
            if (mappedBucket) {
              // only push if unique
              if (!mappedBucket.some(b => b.disease === bucket.disease)) {
                mappedBucket.push(bucket)
              }
            } else {
              byId[key] = [bucket]
            }

            return byId
          },
          // recreate reference so map rerenders
          { ...prev }
        ) ?? prev
      return bucketByLocationId
    })
  }, [newBuckets.data?.buckets])

  // manage what alerts get shown in the alert drawer
  useEffect(() => {
    if (clusterAlertsIds.length) {
      setOpenAlertDrawer(true)
      setSelectedLocationId(undefined)
    }
    // use the whole array as a dep so that if you click on the same alert twice, it still opens the drawer
  }, [clusterAlertsIds])
  useEffect(() => {
    if (selectedLocationId) {
      setClusterAlertsIds([])
    }
  }, [selectedLocationId])

  const context = useMemo(() => {
    return {
      openAlertDrawer,
      setOpenAlertDrawer,
      selectedLocationId,
      setSelectedLocationId,
      relatedAlerts,
      relatedAnalyses,
      assets: assetData.assets,
      availableAssetTypes,
      assetFilters,
      setAssetFilters,
      bucketData: allBuckets,
      diseaseType,
      setDiseaseType,
      alertFilters,
      setAlertFilters,
      clusterAlertsIds,
      setClusterAlertsIds,
      mapLoadedRef,
    } satisfies MapContextValue
  }, [
    alertFilters,
    assetData,
    assetFilters,
    availableAssetTypes,
    clusterAlertsIds,
    diseaseType,
    openAlertDrawer,
    relatedAlerts,
    relatedAnalyses,
    allBuckets,
    selectedLocationId,
  ])

  return <MapContext.Provider value={context}>{children}</MapContext.Provider>
}

export const useMapContext = () => {
  const context = useContext(MapContext)
  if (context == null) {
    Sentry.captureMessage(
      "useMapContext must be used within a MapContext",
      SeverityLevel.Fatal
    )
    throw new Error("useMapContext must be used within a MapContext")
  }
  return useMemo(() => context, [context])
}
