import type React from "react"
import { createContext, useContext, useMemo, useState } from "react"
import { cap } from "../../utils/cap"
import type { Dataset } from "@phc/cap-service"
import { ENV } from "../../utils/env"

export const isPrd = ENV === "prd"

const CapServiceContext = createContext<{
  sanityDataset: Dataset
  setSanityDataset: React.Dispatch<React.SetStateAction<Dataset>>
  useGuidance: () => ReturnType<typeof getGuidance>
  useAnalyses: () => ReturnType<typeof getAnalyses>
  useCategories: () => ReturnType<typeof getCategories>
  useCriticalThreats: () => ReturnType<typeof getCriticalThreats>
  useRelatedAnalyses: (id?: string) => ReturnType<typeof getRelatedAnalyses>
  useSinglePost: (slug: string) => ReturnType<typeof getSinglePost>
  useAlerts: (showAll?: boolean) => ReturnType<typeof getAlerts>
  useRelatedAlerts: (id?: string) => ReturnType<typeof getRelatedAlerts>
  useAdminPost: (tag: string) => ReturnType<typeof getAdminPost>
  useFaqs: () => ReturnType<typeof getFaqs>
} | null>(null)

export const CapServiceContextProvider: React.FC<{
  children?: React.ReactNode
}> = ({ children }) => {
  const [sanityDataset, setSanityDataset] = useState<Dataset>(
    import.meta.env.VITE_LOCAL ? "development" : "production"
  )

  const context = useMemo(() => {
    const useGuidance = () => {
      return getGuidance(sanityDataset)
    }

    const useAnalyses = () => {
      return getAnalyses(sanityDataset)
    }

    const useCategories = () => {
      return getCategories(sanityDataset)
    }

    const useCriticalThreats = () => {
      return getCriticalThreats(sanityDataset)
    }

    const useRelatedAnalyses = (id?: string) => {
      return getRelatedAnalyses(id ?? undefined, sanityDataset)
    }

    const useSinglePost = (slug: string) => {
      return getSinglePost(slug, sanityDataset)
    }

    const useAlerts = (showAll = false) => {
      return getAlerts(showAll, sanityDataset)
    }

    const useRelatedAlerts = (id?: string) => {
      return getRelatedAlerts(id ?? undefined, sanityDataset)
    }

    const useAdminPost = (tag: string) => {
      return getAdminPost(tag, sanityDataset)
    }

    const useFaqs = () => {
      return getFaqs(sanityDataset)
    }

    return {
      sanityDataset,
      setSanityDataset,
      useGuidance,
      useAnalyses,
      useCategories,
      useCriticalThreats,
      useRelatedAnalyses,
      useSinglePost,
      useAlerts,
      useRelatedAlerts,
      useAdminPost,
      useFaqs,
    }
  }, [sanityDataset])
  return (
    <CapServiceContext.Provider value={context}>
      {children}
    </CapServiceContext.Provider>
  )
}

export function useCapServiceContext() {
  const context = useContext(CapServiceContext)
  if (context == null) {
    throw new Error(
      "useCapServiceContext must be used within a CapServiceContext"
    )
  }
  return useMemo(() => context, [context])
}

const getGuidance = (dataset: Dataset) => {
  return cap.guidance.useQuery({ dataset })
}

const getAnalyses = (dataset: Dataset) => {
  return cap.analyses.useQuery({ dataset })
}

const getCategories = (dataset: Dataset) => {
  return cap.categories.useQuery({ dataset })
}

const getCriticalThreats = (dataset: Dataset) => {
  return cap.criticalThreats.useQuery({ dataset })
}

const getRelatedAnalyses = (
  id: string | null | undefined,
  dataset: Dataset
) => {
  return cap.relatedAnalyses.useQuery(
    { arg: id ?? undefined, dataset },
    {
      enabled: !!id,
      staleTime: 1000 * 60 * 5,
    }
  )
}

const getSinglePost = (slug: string, dataset: Dataset) => {
  return cap.singlePost.useQuery({ arg: slug, dataset })
}

const getAlerts = (showAll: boolean, dataset: Dataset) => {
  return cap.alerts.useQuery(
    { dataset, arg: showAll ? "all" : undefined },
    {
      staleTime: 1000 * 60 * 5,
    }
  )
}

const getRelatedAlerts = (id: string | null | undefined, dataset: Dataset) => {
  return cap.relatedAlerts.useQuery(
    { arg: id ?? undefined, dataset },
    {
      enabled: !!id,
      staleTime: 1000 * 60 * 5,
    }
  )
}

const getAdminPost = (tag: string, dataset: Dataset) => {
  return cap.adminPost.useQuery({ arg: tag, dataset })
}

const getFaqs = (dataset: Dataset) => {
  return cap.faq.useQuery({ dataset })
}
