import { Timestamp } from "@bufbuild/protobuf"
import type { PharosUserTrial } from "@phc-health/connect-query"
import {
  useUpdateUserStatus,
  useUpdateUserTrial,
} from "../hooks/useManageAssetServiceUsers"
import { Typography, styled } from "@mui/material"
import { extraColors } from "../../../utils/theme"
import { dayjsUTC } from "../../../utils/helpers"
import Button from "../../Shared/Button"
import { usePermissions } from "../../../hooks/usePermissions"
import { getThirtyDaysFromNowDate } from "../../../utils/helpers/dateHelper"

const BUTTON_WIDTH = "230px"

const UserDetails = styled("div")({
  display: "flex",
  flexDirection: "column",
})

const StatusRow = styled("div")({
  display: "flex",
  flexDirection: "row",
  gap: 6,
  marginBottom: 6,
})

const StatusTag = styled("div")<{ background: string; border: string }>(
  ({ background, border, theme }) => ({
    ...theme.typography.small1Bold,
    backgroundColor: background,
    padding: "1px 8px",
    borderRadius: 12,
    border: `1px solid ${border}`,
    color: border,
    display: "flex",
    alignItems: "center",
    fontSize: 9,
  })
)

const DeactivateButton = styled(Button)({
  border: `2px solid ${extraColors.medium}`,
  background: extraColors.white,
  color: extraColors.medium,
  width: BUTTON_WIDTH,
  "&:hover": {
    border: `2px solid ${extraColors.medium}`,
    background: extraColors.light,
    color: extraColors.medium,
  },
})

const ActionRow = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifySelf: "end",
  justifyContent: "space-evenly",
  gap: 20,
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
    marginLeft: 20,
  },
}))

const UserContainer = styled("div")(({ theme }) => ({
  display: "grid",
  gridAutoFlow: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: 12,
  padding: "12px 20px",
  borderRadius: 6,
  border: `2px solid rgba(0, 140, 159, .15)`,
  transition: "background 250ms ease",
  [theme.breakpoints.up("sm")]: {
    gridAutoFlow: "column",
    gap: 0,
    justifyContent: "space-between",
  },
}))

const UserEmail = styled(Typography)(({ theme }) => ({
  overflowWrap: "anywhere",
  maxWidth: BUTTON_WIDTH,
  color: extraColors.status.tealDark,
  [theme.breakpoints.up("sm")]: {
    maxWidth: "unset",
  },
}))

export const UserWithTrial: React.FC<{
  userTrial: PharosUserTrial
}> = ({ userTrial }) => {
  const { user } = userTrial

  return (
    <UserContainer>
      <UserDetails>
        <UserStatus userTrial={userTrial} />
        <UserEmail variant="body1Bold">{user?.primaryEmailAddress}</UserEmail>
      </UserDetails>
      <UserActions userTrial={userTrial} />
    </UserContainer>
  )
}

const UserStatus: React.FC<{
  userTrial: PharosUserTrial
}> = ({ userTrial }) => {
  const { isTrialUser, user } = userTrial

  return (
    <StatusRow>
      <StatusTag
        background={
          user?.deletedAt ? extraColors.light : extraColors.status.greenLight
        }
        border={
          user?.deletedAt ? extraColors.medium : extraColors.status.greenDark
        }
      >
        {user?.deletedAt ? "Deactivated" : "Active"}
      </StatusTag>
      {isTrialUser && (
        <StatusTag
          background={extraColors.status.tealLight}
          border={extraColors.status.tealDark}
        >
          {getTrialPillText(userTrial)}
        </StatusTag>
      )}
    </StatusRow>
  )
}

const UserActions: React.FC<{
  userTrial: PharosUserTrial
}> = ({ userTrial }) => {
  const { mutate: toggleDeletedStatus } = useUpdateUserStatus()
  const { mutate: updateUserTrial } = useUpdateUserTrial()
  const permissions = usePermissions()
  const isPHCAdmin = !!permissions.phcAdminUser.organizations.users.write
  const { isTrialUser, user, trialStartedAt } = userTrial

  const updateUserTrialStatus = () => {
    if (!user?.userId) return
    const now = new Date()
    const thirtyDaysInFuture = getThirtyDaysFromNowDate(now)

    updateUserTrial({
      userTrial: {
        user: {
          userId: user.userId,
        },
        isTrialUser: !isTrialUser,
        // If the user is already a trial user, we don't want to update their trial start date.
        // That should be preserved unless they start a new trial. Just set the end date if we end the trial.
        // Set the trial start and end date if we're converting them to trial status.
        trialStartedAt: !isTrialUser ? Timestamp.fromDate(now) : trialStartedAt,
        trialEndedAt: !isTrialUser
          ? Timestamp.fromDate(thirtyDaysInFuture)
          : Timestamp.fromDate(now),
      },
    })
  }

  return (
    <ActionRow>
      <Button
        variant="contained"
        sx={{ width: BUTTON_WIDTH }}
        disabled={!isPHCAdmin}
        onClick={updateUserTrialStatus}
      >
        {isTrialUser ? "End 30-Day Trial" : "Convert to 30-Day Trial"}
      </Button>
      <DeactivateButton
        disabled={!isPHCAdmin}
        variant="outlined"
        onClick={() => {
          toggleDeletedStatus({
            userId: user?.userId,
            // If the user has already been deleted, set isActive to true
            // If they have no deletedAt date, set isActive to false
            isActive: !!userTrial.user?.deletedAt,
          })
        }}
      >
        {user?.deletedAt ? "Reactivate User" : "Deactivate User"}
      </DeactivateButton>
    </ActionRow>
  )
}

function getTrialPillText(userTrial: PharosUserTrial) {
  const { trialEndedAt, trialStartedAt } = userTrial
  const trialStartedAtDate = trialStartedAt
    ? dayjsUTC(trialStartedAt.toDate()).local().format("MM/DD/YYYY")
    : ""

  const trialStartedText = trialStartedAt ? `: ${trialStartedAtDate}` : ""

  const trialEndedAtDate = trialEndedAt
    ? dayjsUTC(trialEndedAt.toDate()).local().format("MM/DD/YYYY")
    : ""

  const trialEndedAtText = trialEndedAt ? ` - ${trialEndedAtDate}` : ""

  return `Trial${trialStartedText}${trialEndedAtText}`
}
