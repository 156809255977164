import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  DialogContent,
  LinearProgress,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material"
import type { Asset } from "@phc-health/connect-query"
import { AssetType, AssetServiceQuery } from "@phc-health/connect-query"

import type React from "react"
import { useCallback, useState } from "react"
import { useBulkCreateAssets } from "./hooks/useCreateAsset"
import { useSnackbar } from "notistack"

import {
  useListAssetGroups,
  useListAssets,
  useRemoveAssetGroup,
} from "../hooks/useAssetService"

import { AssetDialogControls, DialogHeader, DialogStyled } from "./Shared"
import { styled } from "@mui/material/styles"
import Button from "@mui/material/Button"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import { useRemoveAsset } from "./hooks/useRemoveAsset"
import PQueue from "p-queue"
import { useQueryClient } from "@tanstack/react-query"
import { ExpandMore } from "@mui/icons-material"
import { enumKeys } from "../../../utils/helpers"
import {
  parseBulkCSV,
  type AssetCSVRow,
  assetSchema,
} from "./utils/parseBulkCSV"
import {
  BULK_ASSET_GROUP,
  LOCAL_STORAGE_PREPARED_ASSETS_KEY,
  getLocalStorageAssets,
  makeAssetFromBulkCSV,
} from "./utils/prepareNewAssets"
import { createConnectQueryKey } from "@connectrpc/connect-query"

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
})

const StyledContent = styled(DialogContent)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 10,
  padding: "16px 0",
})

const ColumnTitleDesc: React.FC<{
  title: string | undefined
  desc?: string
}> = ({ title, desc }) => (
  <Typography variant="body2">
    <Typography variant="body2Bold" display="inline">
      {title}:
    </Typography>{" "}
    {desc}
  </Typography>
)

interface UploadRowError {
  row: number
  message: string
}

enum UploadMode {
  APPEND = "append",
  SYNC = "sync",
}
const isUploadMode = (mode: any): mode is UploadMode => {
  if (typeof mode !== "string") {
    return false
  }
  switch (mode) {
    case UploadMode.APPEND.toString():
    case UploadMode.SYNC.toString():
      return true
    default:
      throw new Error(`Unknown upload mode: ${mode}`)
  }
}

interface AssetBulkDialog {
  handleModalClose: () => void
}

export const AssetBulkDialog: React.FC<AssetBulkDialog> = ({
  handleModalClose,
}) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  const { mutateAsync: createBulk, isPending: bulkIsPending } =
    useBulkCreateAssets()
  const { mutateAsync: remove, isPending: removeIsPending } = useRemoveAsset({
    hideToast: true,
    skipQueryInvalidation: true,
  })
  const { mutateAsync: removeGroup, isPending: removeGroupIsPending } =
    useRemoveAssetGroup({ hideToast: true, skipQueryInvalidation: true })

  const existingAssets = useListAssets({
    includeGlobal: false,
    excludeGroups: true,
    excludeNotifications: true,
  })
  const existingGroups = useListAssetGroups()
  const [isLoading, setIsLoading] = useState(false)
  const [uploadMode, setUploadMode] = useState<UploadMode>(UploadMode.APPEND)
  const [newAssets, setNewAssets] = useState<Asset[]>()
  const [assetsToBeRemoved, setAssetsToBeRemoved] = useState<Asset[]>()
  const isComponentLoading =
    existingAssets.isFetching ||
    existingGroups.isFetching ||
    bulkIsPending ||
    removeIsPending ||
    isLoading
  const [totalJobs, setTotalJobs] = useState(0)
  const [inProgressJobs, setInProgressJobs] = useState(0)
  const [completedJobs, setCompletedJobs] = useState(0)

  // TODO: test error handling
  const [error, setError] = useState<UploadRowError[] | string>([])

  const prepareNewAssets = useCallback(async (assets: AssetCSVRow[]) => {
    console.time("prepareNewAssets")
    setTotalJobs(assets.length)
    setInProgressJobs(0)
    setCompletedJobs(0)
    const localStorageAssets = getLocalStorageAssets()
    const promises = assets.map(async asset => {
      setInProgressJobs(prev => prev + 1)
      const newAsset = await makeAssetFromBulkCSV(asset, localStorageAssets)
      setCompletedJobs(prev => prev + 1)
      return newAsset
    })
    const results = await Promise.allSettled(promises)
    setTotalJobs(0)
    setInProgressJobs(0)
    setCompletedJobs(0)
    console.timeEnd("prepareNewAssets")
    // TODO: display errors or at least say to check the console
    const erroredAssets = results.flatMap(result =>
      result.status === "rejected" ? result : []
    )
    if (erroredAssets.length > 0) {
      console.error("erroredAssets", erroredAssets)
    }
    const successfulAssets = results.flatMap(result =>
      result.status === "fulfilled" ? result.value : []
    )
    return successfulAssets
  }, [])

  const handleCSV = useCallback(
    async (file: File) => {
      setError([])
      try {
        const json = await parseBulkCSV(file)

        if (!json.length) {
          return
        }

        if (isComponentLoading) {
          setError("Existing assets still loading, please try again")
          return
        }
        // check name against existing assets
        const uniqueAssets = json.filter(
          (asset: AssetCSVRow) =>
            !existingAssets.data.assets.find(
              existingAsset => existingAsset.name === asset.Name
            )
        )
        const assets = await prepareNewAssets(uniqueAssets)
        setNewAssets(assets)
        const notFoundAssets = existingAssets.data.assets.filter(
          existingAsset =>
            // existing assets that are not in the new list
            !json.find(asset => asset.Name === existingAsset.name) &&
            // only remove assets that are in the bulk group
            existingAsset.assetGroups
              .map(g => g.name)
              .includes(BULK_ASSET_GROUP)
        )
        setAssetsToBeRemoved(notFoundAssets)
      } catch (e) {
        if (e instanceof Error) {
          setError(e.message)
        }
      }
    },
    [existingAssets.data.assets, isComponentLoading, prepareNewAssets]
  )

  const bulkCreateAssets = useCallback(async () => {
    setError([])
    if (!newAssets) {
      return
    }
    setTotalJobs(newAssets.length)
    setInProgressJobs(0)
    setCompletedJobs(0)

    try {
      const batchSize = 300
      const totalAssets = newAssets.length
      let currentIndex = 0

      // Loop until all assets are processed
      while (currentIndex < totalAssets) {
        const endIndex = Math.min(currentIndex + batchSize, totalAssets)
        const batchAssets = newAssets.slice(currentIndex, endIndex)
        setInProgressJobs(endIndex)
        try {
          await createBulk({ assets: batchAssets })
          setCompletedJobs(endIndex)
        } catch (e) {
          console.error("Error creating bulk assets:", e)
          if (e instanceof Error) {
            setError(prev => (e instanceof Error ? e.message : prev))
          }
        }
        currentIndex += batchSize
      }

      // remove assets that were not in the csv
      const queue = new PQueue({ concurrency: 50 })
      if (uploadMode === UploadMode.SYNC && assetsToBeRemoved) {
        const removePromises = assetsToBeRemoved.map(async asset => {
          return queue.add(() => {
            return remove({ assetId: asset.assetId })
          })
        })
        await Promise.allSettled(removePromises)
      }
      window.localStorage.removeItem(LOCAL_STORAGE_PREPARED_ASSETS_KEY)
      handleModalClose()
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message)
      }
      console.error(e)
    } finally {
      setCompletedJobs(0)
      setInProgressJobs(0)
      setTotalJobs(0)

      enqueueSnackbar(
        error.length
          ? "Unable to save your locations"
          : "Your new locations have been saved.",
        {
          variant: error.length ? "error" : "success",
        }
      )

      await queryClient.invalidateQueries({
        queryKey: createConnectQueryKey(AssetServiceQuery.listAssets),
      })

      await queryClient.invalidateQueries({
        queryKey: createConnectQueryKey(AssetServiceQuery.getAsset),
      })

      await queryClient.invalidateQueries({
        queryKey: createConnectQueryKey(AssetServiceQuery.listAssetGroups),
      })
    }
  }, [
    assetsToBeRemoved,
    createBulk,
    enqueueSnackbar,
    error,
    handleModalClose,
    newAssets,
    queryClient,
    remove,
    uploadMode,
  ])

  const normalizeProgress = (progress: number) => (progress / totalJobs) * 100

  return (
    <DialogStyled
      open
      onClose={handleModalClose}
      aria-labelledby={"asset-modal-title"}
    >
      <DialogHeader
        title="Bulk Add Locations"
        desc="Add multiple locations at once by uploading a CSV file."
      />
      <StyledContent dividers>
        <Accordion disableGutters>
          <AccordionSummary expandIcon={<ExpandMore />}>
            CSV Schema
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2">Columns:</Typography>
            <span
              style={{
                fontFamily: "monospace",
                background: "#ccc",
                padding: "1px 8px",
              }}
            >
              {Object.keys(assetSchema.shape).join(",")}
            </span>
            <ColumnTitleDesc
              title={assetSchema.shape.Name._def.description}
              desc="Unique name for the location"
            />
            <ColumnTitleDesc title={assetSchema.shape.Type._def.description} />
            <ul
              style={{
                maxHeight: 150,
                overflow: "auto",
                marginTop: 0,
              }}
            >
              {enumKeys(AssetType)
                .sort()
                .map(type => (
                  <Typography
                    component="li"
                    key={type}
                    variant="body2"
                    fontFamily="monospace"
                    sx={{
                      background: "#ddd",
                      width: "fit-content",
                      margin: "2px 0",
                    }}
                  >
                    {type}
                  </Typography>
                ))}
            </ul>
            <ColumnTitleDesc
              title={assetSchema.shape.Latitude._def.description}
              desc="Number between -90 and 90"
            />
            <ColumnTitleDesc
              title={assetSchema.shape.Longitude._def.description}
              desc="Number between -180 and 180"
            />
            <ColumnTitleDesc
              title={assetSchema.shape["Threat Radius"]._def.description}
              desc="Number (in miles)"
            />
            <ColumnTitleDesc
              title="Groups"
              desc="Comma separated list of existing asset group names"
            />
          </AccordionDetails>
        </Accordion>
        <Button
          component="label"
          variant="contained"
          startIcon={<CloudUploadIcon />}
          disabled={isComponentLoading}
        >
          {isComponentLoading ? "processing" : "Upload file"}
          <VisuallyHiddenInput
            type="file"
            accept=".csv"
            onChange={e => {
              if (!e.target.files) {
                return
              }
              const file = e.target.files[0]
              if (!file) {
                return
              }
              setIsLoading(true)
              handleCSV(file)
                .catch(console.error)
                .finally(() => {
                  setIsLoading(false)
                })
            }}
          />
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            window.localStorage.removeItem(LOCAL_STORAGE_PREPARED_ASSETS_KEY)
            setError([])
            setNewAssets(undefined)
            setAssetsToBeRemoved(undefined)
          }}
          disabled={isComponentLoading || !newAssets || !assetsToBeRemoved}
        >
          Clear file
        </Button>
        <Typography variant="body1" color="error">
          {typeof error === "string"
            ? error
            : error.map(e => (
                <Typography variant="inherit" key={e.row}>
                  {e.row}: {e.message}
                </Typography>
              ))}
        </Typography>
        <div>
          {import.meta.env.ENV === "sbx" && (
            <Button
              variant="outlined"
              onClick={() => {
                const queue = new PQueue({
                  concurrency: 100,
                })
                for (const group of existingGroups.data?.assetGroups || []) {
                  queue
                    .add(() =>
                      removeGroup({
                        assetGroupId: group.assetGroupId,
                      })
                    )
                    .catch(console.error)
                }
              }}
              disabled={
                removeGroupIsPending ||
                existingGroups.isLoading ||
                existingGroups.data?.assetGroups.length === 0
              }
            >
              clean groups
            </Button>
          )}
          <ToggleButtonGroup
            value={uploadMode}
            exclusive
            onChange={(e, value) => {
              if (isUploadMode(value)) {
                setUploadMode(value)
              }
            }}
          >
            <ToggleButton value={UploadMode.APPEND} color="success">
              Append
            </ToggleButton>
            <ToggleButton value={UploadMode.SYNC} color="error">
              Sync
            </ToggleButton>
          </ToggleButtonGroup>
          <div>
            <Typography variant="body2" color="success">
              Append: Add new bulk assets
            </Typography>
            <Typography variant="body2" color="error">
              Sync: Add new bulk assets and remove bulk assets not found in CSV
            </Typography>
          </div>
        </div>
        <div>
          {newAssets != null && (
            <div>
              <div>Create {newAssets.length} new assets</div>
              {uploadMode === UploadMode.SYNC && (
                <div>Remove {assetsToBeRemoved?.length ?? 0} assets</div>
              )}
            </div>
          )}
        </div>
      </StyledContent>
      <LinearProgress
        sx={{
          mt: 2,
        }}
        color="info"
        variant={bulkIsPending || removeIsPending ? "buffer" : "determinate"}
        value={completedJobs > 0 ? normalizeProgress(completedJobs) : 0}
        valueBuffer={inProgressJobs > 0 ? normalizeProgress(inProgressJobs) : 0}
      />
      <AssetDialogControls
        handleModalClose={handleModalClose}
        isDisabled={!newAssets?.length || completedJobs > 0}
        action={bulkCreateAssets}
        text={
          (completedJobs || inProgressJobs) > 0 ? (
            <CircularProgress size={20} color="info" />
          ) : (
            "Add Locations"
          )
        }
      />
    </DialogStyled>
  )
}

/**
 * Error geo coding:
 * [
    "Error: No feature found for: -122.464936,37.673739",
    "Error: No feature found for: -122.217683,37.475146",
    "Error: No feature found for: -122.416424,37.636877",
    "Error: No feature found for: -122.283784,37.558918",
    "Error: No feature found for: -122.470173,37.670553",
    "Error: No feature found for: -122.135466,37.460057",
    "Error: No feature found for: -122.48288,37.70032",
    "Error: No feature found for: -121.774363,36.915542",
    "Error: No feature found for: -121.968761,36.974784",
    "Error: No feature found for: -122.031931,37.048364",
    "Error: No feature found for: -87.126274,30.601174",
    "Error: No feature found for: -75.672877,36.037805",
    "Error: No feature found for: -70.347774,43.634242",
    "Error: No feature found for: -122.42,37.63539"
]
 */

/**
 *
 */
