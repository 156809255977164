import { AssetServiceQuery } from "@phc-health/connect-query"
import { useQueryClient } from "@tanstack/react-query"
import { useSnackbar } from "notistack"
import { useTransport } from "../../../../hooks/useTransport"
import { ASSET_SERVICE_API } from "../../../../utils/constants"
import { createConnectQueryKey, useMutation } from "@connectrpc/connect-query"

export const useUpdateNotificationSettings = (
  setIsSaving: (val: boolean) => void
) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()
  const successMessage = "Your notification settings have been updated."
  const transport = useTransport(ASSET_SERVICE_API)
  return useMutation(AssetServiceQuery.updateNotificationSettings, {
    transport,
    onError: err => {
      enqueueSnackbar("Unable to update your notification settings.", {
        variant: "error",
      })
      console.error(err)
    },
    onSuccess: () => {
      enqueueSnackbar(successMessage, {
        variant: "success",
      })
    },
    onSettled: async () => {
      setIsSaving(false)

      await queryClient.invalidateQueries({
        queryKey: createConnectQueryKey(AssetServiceQuery.listAssets),
      })

      await queryClient.invalidateQueries({
        queryKey: createConnectQueryKey(AssetServiceQuery.getAsset),
      })
    },
  })
}
