import { Button, Typography, styled } from "@mui/material"
import type { AssetGroup } from "@phc-health/connect-query"

import type React from "react"
import { useCallback } from "react"
import { extraColors } from "../../../utils/theme"
import { useRemoveAssetGroup } from "../hooks/useAssetService"
import { DialogStyled } from "./Shared"
import type { GroupWithLabel } from "./AssetGroupAutocomplete"

const GroupName = styled("span")({
  fontWeight: 600,
})

const ButtonContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  paddingTop: 10,
})

export const GroupDeleteDialog: React.FC<{
  groupToDelete: GroupWithLabel
  setGroupToDelete: React.Dispatch<
    React.SetStateAction<GroupWithLabel | undefined>
  >
  setGroups: React.Dispatch<React.SetStateAction<AssetGroup[] | undefined>>
}> = ({ groupToDelete, setGroups, setGroupToDelete }) => {
  const { mutateAsync: removeGroup, isSuccess } = useRemoveAssetGroup({
    hideToast: false,
  })

  const onDeleteGroup = useCallback(
    async (assetGroupId: string) => {
      await removeGroup({
        assetGroupId,
      })

      if (isSuccess) {
        setGroups(prev => prev?.filter(g => g.assetGroupId !== assetGroupId))
      }
    },
    [isSuccess, removeGroup, setGroups]
  )

  return (
    <DialogStyled
      open
      onClose={() => setGroupToDelete(undefined)}
      sx={{
        ".MuiPaper-root": {
          display: "grid",
          gap: "20px",
        },
      }}
    >
      <Typography sx={{ alignContent: "baseline" }}>
        <GroupName>Warning:</GroupName> Deleting{" "}
        <GroupName sx={{ color: extraColors.status.teal }}>
          {groupToDelete.label}
        </GroupName>{" "}
        will remove it from this and all other assets.
      </Typography>
      <ButtonContainer>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => setGroupToDelete(undefined)}
        >
          Cancel
        </Button>
        <Button
          variant="destructive"
          onClick={() => {
            void onDeleteGroup(groupToDelete.group.assetGroupId)
            setGroupToDelete(undefined)
          }}
        >
          Delete Group
        </Button>
      </ButtonContainer>
    </DialogStyled>
  )
}
