import MapIcon from "@mui/icons-material/Map"
import { Typography } from "@mui/material"
import { useCallback } from "react"
import { ROUTES, stringifySearchParams } from "../../../../utils/constants.js"
import { ActionMenuItem, ActionMenuLink, IconStyles } from "./ActionsShared.js"
import { useParams } from "react-router-dom"

export const ViewMapMenuItem: React.FC<{
  handleClose: () => void
  locationId: string | undefined
}> = ({ handleClose, locationId }) => {
  const { assetId } = useParams<{ assetId: string }>()

  const goToMap = useCallback(() => {
    if (!locationId) return
    handleClose()
  }, [handleClose, locationId])

  return (
    <ActionMenuItem onClick={goToMap}>
      <ActionMenuLink
        to={{
          pathname: ROUTES.MAP,
          search: stringifySearchParams({
            assetId,
            id: locationId,
            fitBounds: true,
          }),
        }}
      >
        <MapIcon sx={{ ...IconStyles }} />
        <Typography>View On Map</Typography>
      </ActionMenuLink>
    </ActionMenuItem>
  )
}
