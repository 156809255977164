import {
  styled,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  type TypographyVariant,
} from "@mui/material"

import type {
  PortableTextMarkComponentProps,
  PortableTextReactComponents,
} from "@portabletext/react"
import type { CSSProperties, ReactPortal } from "react"
import type React from "react"
import { Link as RouterLink, generatePath } from "react-router-dom"
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch"
import type { BlockContentImage } from "@phc/common"
import { cmsUrlBuilder } from "@phc/common"
import { ROUTES } from "../../utils/constants"
import { ExternalLinkRenderer } from "./ExternalLinks"

/**
 * STYLES
 */

const Image = styled("img")({
  maxWidth: "100%",
})

const HeaderTypographyStyled = styled(Typography)({
  marginTop: 20,
})

const BulletListStyled = styled("ul")({
  marginTop: 5,
})

const Footnote = styled("sup")({
  lineHeight: "normal",
})

const RouterLinkStyled = styled(RouterLink)({})
/**
 * COMPONENTS
 */

export const components = (
  dataset: string,
  overrides?: {
    linkStyle: CSSProperties
  }
): Partial<PortableTextReactComponents> => {
  const comps: Partial<PortableTextReactComponents> = {
    block: {
      caption: ({ children }) => (
        <Typography variant="small1">{children}</Typography>
      ),
      h1: ({ children }) => displayHeader(children, "h1"),
      h2: ({ children }) => displayHeader(children, "h2"),
      h3: ({ children }) => displayHeader(children, "h3"),
      h4: ({ children }) => displayHeader(children, "h4"),
    },
    list: {
      bullet: ({ children }) => <BulletListStyled>{children}</BulletListStyled>,
    },
    marks: {
      link: ExternalLinkRenderer,
      sup: ({ children }) => <Footnote>{children}</Footnote>,
      internalLink: (
        props: PortableTextMarkComponentProps<{
          slug: {
            current: string
          }
          postType: string
          _type: "internalLink"
        }>
      ) => {
        return (
          <RouterLinkStyled
            sx={overrides?.linkStyle}
            to={getPostLink(props.value?.slug.current, props.value?.postType)}
            onClick={e => e.stopPropagation()}
          >
            {props.children}
          </RouterLinkStyled>
        )
      },
    },
    types: {
      image: (props: { value: BlockContentImage }) => {
        const { value } = props
        const { alt, width, height, align } = value
        const url = cmsUrlBuilder(dataset).image(value).url()

        return (
          <TransformWrapper wheel={{ wheelDisabled: true }}>
            <TransformComponent
              wrapperStyle={{
                maxWidth: width,
                width: width ? "100%" : undefined,
                maxHeight: height,
                justifySelf: align,
              }}
            >
              <Image src={url} alt={alt} />
            </TransformComponent>
          </TransformWrapper>
        )
      },
      //casting the data for now.
      table: (props: { value: { rows: any[] } }) => {
        const {
          value: { rows },
        } = props
        return <TableComponent rows={rows} />
      },
    },
  }
  return comps
}

const TableComponent: React.FC<{ rows: any[] }> = ({ rows }) => {
  return (
    <TableContainer sx={{ paddingBottom: 1, paddingTop: 1 }}>
      <Table size="small">
        {rows.map((row: { cells: any[]; _key: string; index: number }, i) => {
          const cells = row.cells.map(
            (
              cell:
                | boolean
                | React.JSX.Element
                | ReactPortal
                | null
                | undefined,
              index: number
            ) => {
              return (
                <TableCell align="left" component="th" scope="row" key={index}>
                  {cell}
                </TableCell>
              )
            }
          )

          if (i === 0) {
            return (
              <TableHead key={row._key}>
                <TableRow sx={{ verticalAlign: "bottom" }}>{cells}</TableRow>
              </TableHead>
            )
          }

          return (
            <TableRow
              key={row._key}
              sx={{
                verticalAlign: "top",
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              {cells}
            </TableRow>
          )
        })}
      </Table>
    </TableContainer>
  )
}

const getPostLink = (slug?: string, postType?: string) => {
  if (!slug || !postType) {
    return ""
  }
  switch (postType) {
    // TODO: remove headlines case once cms dataset is migrated
    case "headlines":
    case "analysis":
      return generatePath(ROUTES.ANALYSIS_POST, {
        slug,
      })
    case "guidance":
      return generatePath(ROUTES.GUIDANCE_POST, {
        slug,
      })
    case "criticalThreat":
      return generatePath(ROUTES.CRITICAL_THREAT, {
        slug,
      })
    default:
      console.error(`Unknown post type: ${postType}`)
      return ""
  }
}

const displayHeader = (
  children: React.ReactNode,
  variant?: TypographyVariant
) => {
  return (
    <HeaderTypographyStyled variant={variant}>
      {children}
    </HeaderTypographyStyled>
  )
}
