import { useParams } from "react-router-dom"
import { useLocationId } from "../../../hooks/useLocationId"
import { useGetAsset } from "../../WatchedLocations/hooks/useAssetService"
import { WorkforcePlanningContent } from "../../WorkforcePlanning/WorkforcePlanningContent"
import { styled } from "@mui/material"
import { AssetHeader } from "../../WorkforcePlanning/WorkforcePlanningTool"
import { IndustryType } from "@phc-health/connect-query"

const AssetHeaderContainer = styled("div")({
  display: "none",
  paddingLeft: 25,
  "@media print": {
    display: "block",
  },
})

export const Absenteeism = () => {
  const locationId = useLocationId()
  const { assetId } = useParams<{ assetId: string }>()

  const { data: asset, isLoading } = useGetAsset(assetId)
  const placeName = asset?.asset?.name
  const address = asset?.asset?.baseEvent?.mapboxLocation?.placeName
  const groups = asset?.asset?.assetGroups.map(g => g.name)

  if (isLoading) return null
  const industry = asset?.asset?.industry || IndustryType.BASELINE
  return (
    <>
      <AssetHeaderContainer>
        <AssetHeader
          name={placeName || ""}
          address={address || ""}
          groups={groups}
        />
      </AssetHeaderContainer>
      <WorkforcePlanningContent
        locationId={locationId}
        defaultIndustry={industry}
      />
    </>
  )
}
