import {
  Disease_Type,
  Asset,
  AssetNotificationType,
  AssetNotificationAlertLevel,
} from "@phc-health/connect-query"
import { useMemo, useCallback } from "react"
import { getNonGlobalAssets } from "../../../../../utils/helpers/assetHelper"
import {
  riskIncreaseSelectedThreats,
  getGlobalEnabledSettingOfType,
  assetsHaveEnabledSettingOfType,
  alertSettingLevel,
  getDBEnabledForecastRange,
  buildUpdatedAssetWithSettings,
  SettingGranularity,
  FORECAST_RANGE_DEFAULT,
} from "../../../../Settings/notifications/contexts/NotificationContext"
import { useUpdateAsset } from "../../../../WatchedLocations/AssetManagement/hooks/useUpdateAsset"
import { useListAssets } from "../../../../WatchedLocations/hooks/useAssetService"

interface LocationNotificationSettingsProps {
  saveAssetNotifications: ({
    toggleRiskIncrease,
    toggleExpertInsights,
    toggleAbsenteeism,
  }: {
    toggleRiskIncrease?: boolean
    toggleExpertInsights?: boolean
    toggleAbsenteeism?: boolean
  }) => void
  updateIsPending: boolean
  globalAlertsEnabled: boolean
  globalAnalysisEnabled: boolean
  summaryAlertsEnabled: boolean
  summaryAnalysisEnabled: boolean
  summarySelectedThreats: Disease_Type[]
  summaryForecastedAbsenteeismEnabled: boolean
  summaryHistoricalAbsenteeismEnabled: boolean
  summaryForecastRange?: number
  currentAssetExpertInsightsEnabled: boolean
  currentAssetRiskIncreaseEnabled: boolean
  currentAssetAbsenteeismEnabled: boolean
  isLoading: boolean
}

export const useLocationNotificationSettings = (
  asset: Asset
): LocationNotificationSettingsProps => {
  const { data: assets, isLoading } = useListAssets({
    includeGlobal: true,
  })

  const { mutate: update, isPending: updateIsPending } = useUpdateAsset()

  const nonGlobalAssets = useMemo(() => {
    return getNonGlobalAssets(assets.assets)
  }, [assets])

  // Risk Increase states
  // If there are selected threats, then risk increase notifications are implicitly enabled
  const summarySelectedThreats = useMemo(() => {
    return Array.from(riskIncreaseSelectedThreats(nonGlobalAssets))
  }, [nonGlobalAssets])

  const currentAssetRiskIncreaseEnabled = useMemo(() => {
    return assetsHaveEnabledSettingOfType(AssetNotificationType.RISK_INCREASE, [
      asset,
    ])
  }, [asset])

  // Expert insights states
  const globalAlertsEnabled = useMemo(() => {
    return !!getGlobalEnabledSettingOfType(
      AssetNotificationType.ALERT,
      assets.assets
    )
  }, [assets.assets])

  const globalAnalysisEnabled = useMemo(() => {
    return !!getGlobalEnabledSettingOfType(
      AssetNotificationType.ANALYSIS,
      assets.assets
    )
  }, [assets.assets])

  const summaryAnalysisEnabled = useMemo(() => {
    return assetsHaveEnabledSettingOfType(
      AssetNotificationType.ANALYSIS,
      nonGlobalAssets
    )
  }, [nonGlobalAssets])

  const summaryAlertsEnabled = useMemo(() => {
    return assetsHaveEnabledSettingOfType(
      AssetNotificationType.ALERT,
      nonGlobalAssets
    )
  }, [nonGlobalAssets])

  const summaryAlertSettingLevel = useMemo(() => {
    return alertSettingLevel(nonGlobalAssets)
  }, [nonGlobalAssets])

  const currentAssetExpertInsightsEnabled = useMemo(() => {
    return (
      assetsHaveEnabledSettingOfType(AssetNotificationType.ALERT, [asset]) ||
      assetsHaveEnabledSettingOfType(AssetNotificationType.ANALYSIS, [asset])
    )
  }, [asset])

  // Absenteeism states
  const summaryHistoricalAbsenteeismEnabled = useMemo(() => {
    return assetsHaveEnabledSettingOfType(
      AssetNotificationType.HISTORICAL_AVG_ABSENTEEISM_INCREASE,
      nonGlobalAssets
    )
  }, [nonGlobalAssets])

  const summaryForecastedAbsenteeismEnabled = useMemo(() => {
    return assetsHaveEnabledSettingOfType(
      AssetNotificationType.FORECASTED_ABSENTEEISM_INCREASE,
      nonGlobalAssets
    )
  }, [nonGlobalAssets])

  const summaryForecastRange = useMemo(() => {
    return getDBEnabledForecastRange(nonGlobalAssets)
  }, [nonGlobalAssets])

  const currentAssetAbsenteeismEnabled = useMemo(() => {
    return (
      assetsHaveEnabledSettingOfType(
        AssetNotificationType.HISTORICAL_AVG_ABSENTEEISM_INCREASE,
        [asset]
      ) ||
      assetsHaveEnabledSettingOfType(
        AssetNotificationType.FORECASTED_ABSENTEEISM_INCREASE,
        [asset]
      )
    )
  }, [asset])

  const saveAssetNotifications = useCallback(
    ({
      toggleRiskIncrease = false,
      toggleExpertInsights = false,
      toggleAbsenteeism = false,
    }) => {
      const globalExpertInsightsEnabled =
        globalAlertsEnabled || globalAnalysisEnabled

      const summaryExpertInsightsEnabled =
        summaryAlertsEnabled || summaryAnalysisEnabled

      const checkboxRiskIncreaseEnabled = toggleRiskIncrease
        ? !currentAssetRiskIncreaseEnabled
        : currentAssetRiskIncreaseEnabled

      const checkboxExpertInsightsEnabled = toggleExpertInsights
        ? !currentAssetExpertInsightsEnabled
        : currentAssetExpertInsightsEnabled

      const checkboxAbsenteeismEnabled = toggleAbsenteeism
        ? !currentAssetAbsenteeismEnabled
        : currentAssetAbsenteeismEnabled

      const updatedAsset = new Asset({
        ...asset,
        notificationSettings: buildUpdatedAssetWithSettings({
          assetName: asset.name || "",
          selectedThreats: summarySelectedThreats.length
            ? summarySelectedThreats
            : [Disease_Type.RESP],
          isSelectedForRiskIncrease: checkboxRiskIncreaseEnabled,
          isSelectedForExpertInsights: checkboxExpertInsightsEnabled,
          assetId: asset.assetId,
          expertInsightsSettingGranularity: globalExpertInsightsEnabled
            ? SettingGranularity.GLOBAL
            : SettingGranularity.ASSET,
          alertsEnabled: summaryExpertInsightsEnabled
            ? checkboxExpertInsightsEnabled && summaryAlertsEnabled
            : checkboxExpertInsightsEnabled,
          alertLevel:
            summaryAlertSettingLevel || AssetNotificationAlertLevel.ADVISORY,
          analysisEnabled: summaryExpertInsightsEnabled
            ? checkboxExpertInsightsEnabled && summaryAnalysisEnabled
            : checkboxExpertInsightsEnabled,
          riskIncreaseEnabled: checkboxRiskIncreaseEnabled,
          isSelectedForAbsenteeism: checkboxAbsenteeismEnabled,
          // If there is no summary historical absenteeism and current absenteeism is checked, this defaults to true
          // Otherwise, respect summary value
          historicalAbsenteeismEnabled: summaryHistoricalAbsenteeismEnabled
            ? checkboxAbsenteeismEnabled && summaryHistoricalAbsenteeismEnabled
            : checkboxAbsenteeismEnabled,
          // If there is no summary historical absenteeism and current absenteeism is checked, this defaults to false
          // Otherwise, respect summary value
          forecastedAbsenteeismEnabled: summaryForecastedAbsenteeismEnabled
            ? checkboxAbsenteeismEnabled && summaryForecastedAbsenteeismEnabled
            : false,
          forecastRange: summaryForecastRange ?? FORECAST_RANGE_DEFAULT,
        }),
      })

      update({ asset: updatedAsset })
    },
    [
      globalAlertsEnabled,
      globalAnalysisEnabled,
      summaryAlertsEnabled,
      summaryAnalysisEnabled,
      currentAssetRiskIncreaseEnabled,
      currentAssetExpertInsightsEnabled,
      currentAssetAbsenteeismEnabled,
      asset,
      summarySelectedThreats,
      summaryAlertSettingLevel,
      summaryHistoricalAbsenteeismEnabled,
      summaryForecastedAbsenteeismEnabled,
      summaryForecastRange,
      update,
    ]
  )

  return {
    saveAssetNotifications,
    updateIsPending,
    globalAlertsEnabled,
    globalAnalysisEnabled,
    summaryAlertsEnabled,
    summaryAnalysisEnabled,
    summarySelectedThreats,
    summaryForecastedAbsenteeismEnabled,
    summaryHistoricalAbsenteeismEnabled,
    summaryForecastRange,
    currentAssetRiskIncreaseEnabled,
    currentAssetExpertInsightsEnabled,
    currentAssetAbsenteeismEnabled,
    isLoading,
  }
}
