import { AccessManagementServiceQuery } from "@phc-health/connect-query"
import { useQuery } from "@connectrpc/connect-query"
import { ACCESS_API } from "../utils/constants"
import { useTransport } from "./useTransport"

export function useRoles(pageToken?: string) {
  const transport = useTransport(ACCESS_API)
  return useQuery(
    AccessManagementServiceQuery.listRoles,
    {
      pageToken,
    },
    {
      transport,
    }
  )
}
