import { styled } from "@mui/material"
import * as React from "react"
import { Popup, useMap } from "react-map-gl"
import { LocationCard } from "../LocationCard/LocationCard"
import { useSearchParams } from "../../../hooks/useSearchParams"
import { useGetAsset } from "../../WatchedLocations/hooks/useAssetService"
import { configureFitBoundsOptions } from "../../../utils/helpers/locationCenterHelper"
import type { MapSelectedInfo } from "./ReactMapbox"
import {
  findFeatureBoundingBox,
  getCentroidCoordinates,
  isPhcFeature,
} from "@phc/common"
import {
  getCountryCodeFromCoords,
  getLocationId,
  getLocationNameFromTileset,
} from "../../../utils/helpers"
import { useMapContext } from "../../../contexts/MapContext"
import { useLayersByLocationId } from "../../../hooks/useLayersApi"
import { MAPBOX_ACCESS_TOKEN } from "../../../utils/env"

const CustomPopup = styled(Popup)<{ isMarker: boolean | undefined }>(
  ({ isMarker }) => ({
    padding: 0,
    ".mapboxgl-popup-content": {
      width: "fit-content",
      padding: 0,
      borderRadius: 6,
      border: "none",
      boxShadow: "none",
      bottom: isMarker ? "30px" : undefined,
    },
    ".mapboxgl-popup-tip": {
      position: isMarker ? "relative" : "static",
      bottom: isMarker ? "30px" : undefined,
    },
  })
)

/**
 * Requires locationId (?id=840), optional centroid if no PHC data (?centroid="POINT (<lng> <lat>)") or assetId (?assetId=kjh123-l123...)
 */
export const ReactMapRiskPopup: React.FC = () => {
  const { current: map } = useMap()
  const [mapSelectedInfo, setMapSelectedInfo] =
    React.useState<MapSelectedInfo>()
  const {
    id: locationId,
    centroid,
    assetId,
    fitBounds,
    setSearchParams,
  } = useSearchParams()

  const animateInitial = React.useRef(!!(locationId || assetId))

  const { setClusterAlertsIds, clusterAlertsIds } = useMapContext()

  const { data: assetData, isLoading: assetsLoading } = useGetAsset(
    assetId ?? undefined
  )
  const { data: layersData, isLoading: layersLoading } =
    useLayersByLocationId(locationId)

  /** Clear alerts on popup */
  React.useEffect(() => {
    if (locationId || assetId) setClusterAlertsIds([])
  }, [assetId, locationId, setClusterAlertsIds])
  /** Clear popup on alert */
  React.useEffect(() => {
    if (clusterAlertsIds.length) {
      setSearchParams(null)
    }
  }, [clusterAlertsIds.length, setSearchParams])

  React.useEffect(() => {
    const getInfo = async () => {
      if (!map || assetsLoading) return
      if (assetId && assetData?.asset) {
        const coords = getCentroidCoordinates(
          assetData.asset.baseEvent?.geotags[0]?.wktCentroid
        )
        if (!coords) return
        setMapSelectedInfo({
          assetId: assetData.asset.assetId,
          isMarker: true,
          locationId: getLocationId(assetData.asset),
          lat: coords.lat,
          lng: coords.lng,
        })

        if (animateInitial.current) {
          map.flyTo({
            center: coords,
            zoom: 10,
            animate: false,
          })
          animateInitial.current = false
        }
        return
      }

      if (locationId && !layersLoading) {
        const coords = getCentroidCoordinates(
          centroid ??
            layersData?.buckets[0]?.baseEvent?.geotags?.[0]?.wktCentroid
        )
        if (!coords) return
        const countryCode = await getCountryCodeFromCoords({
          accessToken: MAPBOX_ACCESS_TOKEN,
          lat: coords.lat,
          lng: coords.lng,
        })
        if (!countryCode) {
          console.error(`Could not get country code from coordinates`, coords)
          return
        }
        const { title, subtitle, feature } = await getLocationNameFromTileset({
          lat: coords.lat,
          lng: coords.lng,
          locationId,
          countryCode,
        })
        setMapSelectedInfo({
          lng: coords.lng,
          lat: coords.lat,
          locationId,
          title,
          subtitle,
        })

        if (fitBounds && isPhcFeature(feature)) {
          const bBox = findFeatureBoundingBox(feature)
          if (!bBox) return
          map.fitBounds(bBox, configureFitBoundsOptions(feature))
        }
      }
    }
    getInfo().catch(console.error)
  }, [
    assetData?.asset,
    assetId,
    assetsLoading,
    centroid,
    fitBounds,
    layersData?.buckets,
    layersLoading,
    locationId,
    map,
  ])

  if (!locationId) return null

  if (!mapSelectedInfo) return null
  return (
    <CustomPopup
      isMarker={!!assetId}
      anchor="bottom"
      longitude={mapSelectedInfo.lng}
      latitude={mapSelectedInfo.lat}
      closeOnClick={false}
      closeButton={false}
    >
      <LocationCard
        key={assetId}
        id={locationId}
        assetId={assetId}
        isMapCard={true}
        isMarker={!!assetId}
        title={mapSelectedInfo.title}
        subtitle={mapSelectedInfo.subtitle}
      />
    </CustomPopup>
  )
}

ReactMapRiskPopup.displayName = "RiskPopup"
