import { IconButton, styled } from "@mui/material"
import {
  MaterialDesignContent,
  SnackbarProvider,
  closeSnackbar,
} from "notistack"
import type React from "react"
import { extraColors } from "../utils/theme"
import { Close } from "@mui/icons-material"

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  "&.notistack-MuiContent-success": {
    backgroundColor: extraColors.medium,
  },
  "&.notistack-MuiContent-error": {
    backgroundColor: extraColors.severe,
  },
}))

export const ToastProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <SnackbarProvider
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
      }}
      action={key => (
        <IconButton
          onClick={() => {
            closeSnackbar(key)
          }}
        >
          <Close sx={{ color: "white" }} />
        </IconButton>
      )}
    >
      {children}
    </SnackbarProvider>
  )
}
