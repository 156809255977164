import { Box, Skeleton, styled } from "@mui/material"

const AnalysisInfo = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}))

export const AnalysisLoadingItem = () => {
  return (
    <Box sx={{ marginBottom: "16px" }}>
      <AnalysisInfo>
        <Skeleton width={"33%"} height={40} />
        <Skeleton width={"23%"} height={40} />
      </AnalysisInfo>
      <Skeleton width={"100%"} height={24} sx={{ marginTop: "4px" }} />
      <Skeleton width={"85%"} height={24} />
    </Box>
  )
}
